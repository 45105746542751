import React from 'react';
import { DeliveryDate } from '_common/components/search/DeliveryDateOptions';

export const DELIVERY_DATE_CONTEXT_DEFAULT = 'DEFAULT';
export const DELIVERY_DATE_CONTEXT_ISOLATED = 'ISOLATED';

type PageContext =
  | typeof DELIVERY_DATE_CONTEXT_DEFAULT
  | typeof DELIVERY_DATE_CONTEXT_ISOLATED;

interface ContextState {
  pageContext: PageContext;
  isolatedContextDeliveryDate?: DeliveryDate;
  setIsolatedContextDeliveryDate?: () => void;
}

const defaultContextState: ContextState = {
  pageContext: DELIVERY_DATE_CONTEXT_DEFAULT
};

export const DeliveryDateContext = React.createContext(defaultContextState);

export const chooseDeliveryDateFromContext = (
  contextState: ContextState,
  deliveryDate: DeliveryDate
) => {
  return contextState.pageContext === DELIVERY_DATE_CONTEXT_DEFAULT
    ? deliveryDate
    : contextState.isolatedContextDeliveryDate;
};
