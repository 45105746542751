const hasLocalStorage = () => {
  try {
    return 'localStorage' in window && window.localStorage !== null;
  } catch (e) {
    return false;
  }
};

const findBasketItemAmongSellableProducts = (item, sellableProducts) => {
  return sellableProducts.find(
    ({ _id, deliveryDates = [], soldOutDates = [] }) => {
      return (
        item.productId === _id &&
        deliveryDates.includes(item.deliveryDate) &&
        !soldOutDates.find(date => {
          return date === item.deliveryDate;
        })
      );
    }
  );
};

export const getItemFromLocalStorage = key => {
  return hasLocalStorage() && JSON.parse(window.localStorage.getItem(key));
};

export const updateLocalStorage = (key, data) => {
  return (
    hasLocalStorage() && window.localStorage.setItem(key, JSON.stringify(data))
  );
};

export const getBasketFromLocalStorage = sellableProducts => {
  const storedBasket = getItemFromLocalStorage('basket') || [];

  const validBasket = storedBasket.filter(item => {
    return findBasketItemAmongSellableProducts(item, sellableProducts);
  });

  updateLocalStorage('basket', validBasket);

  return validBasket;
};

export const getCurrentRoleIdFromLocalStorage = () => {
  return getItemFromLocalStorage('currentRoleId');
};

export const removeItemFromLocalStorage = key => {
  return hasLocalStorage() && window.localStorage.removeItem(key);
};
