import { roundNumber, Product, ProductPricing } from '@dagensmat/core';
import i18n from 'i18n';
import {
  PRODUCER_DELIVERS,
  PRODUCER_TRANSPORTS,
  PICKED_UP_BY_PARTNER
} from 'utils/delivery';

export const numberToWord = (count: number): string => {
  if (count > 10) return count.toString();
  return i18n.t('common:numberAsWord_interval', {
    postProcess: 'interval',
    count
  });
};

export const formatNok = (value: number): string => {
  return `${Math.floor(value)} kr`;
};

export const formatNok2Decimals = (value: number): string => {
  return `${roundNumber(value).toFixed(2)} kr`;
};

export const formatProductNameAndType = ({ name, type }: Product): string => {
  return [name, type].filter(Boolean).join(' ');
};

export const byProductNameAndId = ({
  name,
  type,
  _id
}: {
  name: string;
  type: string;
  _id: string;
}): string => {
  return [name, type, _id].filter(Boolean).join(' ');
};

export const formatTextWithUnits = (unit: string, units: number): string => {
  return `${units} ${i18n.t(`units:${unit}`, { count: units })}`;
};

export const booleanToYesNoUndecided = (value?: boolean | ''): string => {
  if (value === true) return i18n.t('common:yes');
  if (value === false) return i18n.t('common:no');
  return '';
};

export const getPricingText = ({
  nokPerPricedUnit,
  pricedUnit
}: ProductPricing): string => {
  return `${nokPerPricedUnit} kr/${i18n.t(`units:${pricedUnit}`)}`;
};

interface OrderGroup {
  type:
    | typeof PRODUCER_DELIVERS
    | typeof PRODUCER_TRANSPORTS
    | typeof PICKED_UP_BY_PARTNER;
  orders?: any[];
  toName: string;
  partnerName: string;
  pickupPoint: string;
}
export const getDeliverySubTitle = (orderGroup: OrderGroup): string => {
  const { type, orders = [], toName, partnerName, pickupPoint } = orderGroup;

  const orderCountText = i18n.t('common:numOrders', { count: orders.length });

  switch (type) {
    case PRODUCER_DELIVERS:
      return i18n.t('producer:thatYouDeliverToTheCustomer', { orderCountText });
    case PRODUCER_TRANSPORTS:
      return i18n.t('producer:toBeDeliveredToCustomerName', {
        orderCountText,
        toName
      });
    case PICKED_UP_BY_PARTNER:
      return i18n.t('producer:toBePickedUpAtPointByPartner', {
        orderCountText,
        pickupPoint,
        partnerName
      });
    default:
      return '';
  }
};

export const getDeliveryDayCalloutText = (orderGroup: OrderGroup): string => {
  const { type, toName, partnerName, pickupPoint } = orderGroup;

  const methodText =
    type === PICKED_UP_BY_PARTNER
      ? i18n.t('common:collected')
      : i18n.t('common:delivered');
  const lastPart = i18n.t('common:reminderDeliveryStatus', { methodText });

  switch (type) {
    case PRODUCER_DELIVERS:
      return i18n.t('common:deliveryDayCalloutText.PRODUCER_DELIVERS', {
        lastPart
      });
    case PRODUCER_TRANSPORTS:
      return i18n.t('common:deliveryDayCalloutText.PRODUCER_TRANSPORTS', {
        toName,
        lastPart
      });
    case PICKED_UP_BY_PARTNER:
      return i18n.t('common:deliveryDayCalloutText.PICKED_UP_BY_PARTNER', {
        partnerName,
        pickupPoint,
        lastPart
      });
    default:
      return '';
  }
};

export const getDeliveryInProgressCalloutText = (
  orderGroup: OrderGroup
): string => {
  const { type, partnerName } = orderGroup;

  switch (type) {
    case PRODUCER_TRANSPORTS:
    case PICKED_UP_BY_PARTNER:
      return i18n.t('common:deliveryInProgressCallout', {
        partnerName: partnerName
          ? ` ${i18n.t('common:with')} ${partnerName}`
          : ''
      });
    default:
      return i18n.t('common:deliveryInProgressCalloutDefault');
  }
};
