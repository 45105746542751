import React from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import DeliveryDateOptions, {
  DeliveryDate
} from '_common/components/search/DeliveryDateOptions';
import Typography from '../utils/Typography';

type ProductDeliveryDateSelectionProps = {
  product: any;
  availableDeliveryDates: DeliveryDate[];
  setDeliveryDate: (deliveryDate: DeliveryDate) => void;
};

const getUnavailableDeliveryDates = (
  product: any,
  availableDeliveryDates: string[]
) => {
  const { deliveryDates = [], soldOutDates = [] } = product;
  return Array.from(
    new Set<string>(
      [].concat(availableDeliveryDates, deliveryDates).filter(deliveryDate => {
        return (
          !deliveryDates.includes(deliveryDate) ||
          soldOutDates.includes(deliveryDate)
        );
      })
    )
  );
};

const ProductDeliveryDateSelection = ({
  product,
  availableDeliveryDates,
  setDeliveryDate
}: ProductDeliveryDateSelectionProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography
        variant="inputLabel"
        mt={theme.spacing.m}
        mb={theme.spacing.xxs}
      >
        {t('consumer:WhenAreYouOrderingFor')}
      </Typography>
      <DeliveryDateOptions
        availableDeliveryDates={availableDeliveryDates}
        unavailableDeliveryDates={getUnavailableDeliveryDates(
          product,
          availableDeliveryDates
        )}
        setDeliveryDate={setDeliveryDate}
      />
    </>
  );
};

export default ProductDeliveryDateSelection;
