import React from 'react';
import { connect } from 'react-redux';
import { forSaleStatuses } from '@dagensmat/core';
import { getImageUrl } from 'utils/imageUrls';
import { P } from 'utils/fonts';
import BulletPointsCallout from '_common/components/callout/BulletPointsCallout';
import CloseButton from '_common/components/button/CloseButton';
import ProductInfo from '_common/components/product/ProductInfo';
import LikeProduct from '_common/components/product/LikeProduct';
import {
  Content,
  ModalImageContainer as ImageContainer
} from '_common/components/product/Product.style';
import {
  DeliveryDateContext,
  chooseDeliveryDateFromContext
} from '_common/context/DeliveryDateContext';
import SendRequest from '_common/components/product/request-product/SendRequest';
import SingleProductSeason from '_common/components/season/SingleProductSeason';
import ProductTag from '_common/components/product/ProductTag';
import ContactProducerText from '_common/components/product/ContactProducerText';
import theme from 'theme';
import ModalBuyerWrapper from './ModalBuyerWrapper';

const ProductView = ({
  product,
  roleType,
  closeModal,
  isGuest,
  selectedDeliveryDate
}) => {
  const context = React.useContext(DeliveryDateContext);

  const {
    _id: productId,
    description,
    image,
    keyFacts = [],
    forSaleStatus,
    seasonCalendar = [],
    name,
    producer
  } = product;

  const isForSale = forSaleStatus === forSaleStatuses.FOR_SALE;
  const isOnRequest = forSaleStatus === forSaleStatuses.ON_REQUEST;
  const imageUrl = getImageUrl(image, { width: 1000 });
  const deliveryDate = chooseDeliveryDateFromContext(
    context,
    selectedDeliveryDate
  );

  return (
    <div style={{ position: 'relative' }}>
      <ProductTag product={product} deliveryDate={deliveryDate} />
      <LikeProduct
        productId={productId}
        roleType={roleType}
        isGuest={isGuest}
      />
      <ImageContainer imageUrl={imageUrl} name={name} onClick={closeModal} />
      <div style={{ position: 'relative' }}>
        <CloseButton
          onClick={closeModal}
          style={{
            position: 'absolute',
            right: 15,
            top: 0,
            padding: 15
          }}
        />
        <Content>
          <ProductInfo product={product} roleType={roleType} isModal />
          <P mt={theme.spacing.xs} mb={theme.spacing.xs}>
            {description}
          </P>
          {isOnRequest && <SendRequest productId={productId} />}
          {isForSale && (
            <>
              <ModalBuyerWrapper product={product} />
              <ContactProducerText producerId={producer._id} />
            </>
          )}
          <SingleProductSeason seasonCalendar={seasonCalendar} />
          <BulletPointsCallout points={keyFacts} />
        </Content>
      </div>
    </div>
  );
};

const mapStateToProps = ({ productsForSale: { selectedDeliveryDate } }) => {
  return {
    selectedDeliveryDate
  };
};

export default connect(mapStateToProps)(ProductView);
