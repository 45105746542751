import {
  format,
  getISOWeek,
  getDayOfYear,
  startOfYear,
  getMonth,
  getYear,
  endOfYear,
  isBefore,
  differenceInCalendarDays,
  isToday as dateFnsIsToday,
  parseISO,
  min,
  max,
  add,
  isSameWeek
} from 'date-fns';
import nb from 'date-fns/locale/nb';
import en from 'date-fns/locale/en-US';
import da from 'date-fns/locale/da';
import i18n from 'i18n';

export const parseDate = date => {
  return typeof date === 'string' ? parseISO(date) : date;
};

export const isBeforeMonthIndex = index => {
  return index < getMonth(new Date());
};

export const getStartOfYear = date => {
  return startOfYear(parseDate(date));
};

export const getEndOfYear = date => {
  return endOfYear(parseDate(date));
};

export const minCurrentYear = date => {
  return max([parseDate(date), getStartOfYear(new Date())]);
};

export const isBeforeThisYear = date => {
  return getYear(parseDate(date)) < getYear(new Date());
};

export const isAfterThisYear = date => {
  return getYear(parseDate(date)) > getYear(new Date());
};

export const maxCurrentYear = date => {
  return min([parseDate(date), getEndOfYear(new Date())]);
};

export const isToday = date => {
  return dateFnsIsToday(parseDate(date));
};

export const isTodayOrBefore = date => {
  return isToday(date) || isBefore(parseDate(date), new Date());
};

export const daysUntil = date => {
  return differenceInCalendarDays(parseDate(date), new Date());
};

export const dayOfYear = date => {
  return getDayOfYear(parseDate(date));
};

export const getYearProgressPercentage = () => {
  return (getDayOfYear(Date.now()) / 365) * 100;
};

const lng = { nb, en, da };

export const formatDate = (date, pattern = 'iiii d. MMMM') => {
  return format(parseDate(date), pattern, { locale: lng[i18n.language] });
};

export const formatDateWithWeek = date => {
  return `${i18n.t('calendar:Week')} ${getISOWeek(
    parseDate(date)
  )} - ${formatDate(date)}`;
};

export const formatDateWithTime = date => {
  return formatDate(date, 'iiii d. MMMM HH:mm');
};

export const slugifyDate = date => {
  return formatDate(date, 'yyyy-MM-dd');
};

export const getDay = date => {
  return formatDate(date, 'iiii');
};

export const formatDateWithLetters = date => {
  return formatDate(date, 'MMMM do');
};

export const formatPeriod = ({ from, to }) => {
  return [
    from && formatDate(from, 'dd.MM.yy'),
    to && formatDate(to, 'dd.MM.yy')
  ]
    .filter(Boolean)
    .join(' - ');
};

export const isWeek = (date, weeksFromNow = 0) => {
  const currentDate = add(parseDate(new Date()), { weeks: weeksFromNow });
  return isSameWeek(parseDate(date), currentDate, { weekStartsOn: 1 });
};
