import React from 'react';
import { connect } from 'react-redux';
import { CountryCode, isCountry } from '@dagensmat/core';

const DagensPhoneLink = ({ organization }) => {
  let tel = '+4781503266';
  let formattedTel = '+47 815 03 266';

  const isDkOrganization =
    organization && isCountry(organization, CountryCode.DK);

  if (isDkOrganization) {
    tel = '+4589871343';
    formattedTel = '+45 89 87 13 43';
  }

  return <a href={`tel:${tel}`}>{formattedTel}</a>;
};

const mapStateToProps = ({ auth: { organization } } = {}) => {
  return {
    organization
  };
};

export default connect(mapStateToProps)(DagensPhoneLink);
