import React from 'react';
import { capitalize } from '@dagensmat/core';
import InnerScrollContainer from '_common/components/utils/InnerScrollContainer.style';
import ScrollContainer from '_common/components/utils/ScrollContainer.style';
import { TagButton } from '_common/components/button/Button.style';
import SelectedButton from '_common/components/button/SelectedButton';
import { mapOptions } from '_common/components/radio-buttons/RadioButtons';
import theme from 'theme';

const Tags = ({ tags = [], selectedTags = [], deselectTag, selectTag }) => {
  return (
    <ScrollContainer>
      <InnerScrollContainer>
        {mapOptions(selectedTags).map(t => {
          return (
            <SelectedButton
              key={t.key}
              mr={theme.spacing.xxs}
              mb={theme.spacing.xs}
              onClick={() => {
                return deselectTag(t.key);
              }}
            >
              {capitalize(t.value)}
            </SelectedButton>
          );
        })}
        {mapOptions(tags).map(t => {
          return (
            <TagButton
              key={t.key}
              onClick={() => {
                return selectTag(t.key);
              }}
            >
              {capitalize(t.value)}
            </TagButton>
          );
        })}
      </InnerScrollContainer>
    </ScrollContainer>
  );
};

export default Tags;
