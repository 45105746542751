import React from 'react';
import { useTranslation } from 'react-i18next';
import InputWithUnit from '_common/components/input/InputWithUnit';
import { Picker as PickerStyle, Button } from './Picker.style';

const Picker = ({
  minValue = 0,
  nofUnits,
  unit,
  setNofUnits,
  disableTyping
}) => {
  const { t } = useTranslation();
  return (
    <PickerStyle>
      <Button
        onClick={e => {
          e.stopPropagation();
          setNofUnits(Math.max(minValue, nofUnits - 1));
        }}
      >
        -
      </Button>
      <InputWithUnit
        value={nofUnits}
        unit={t(`units:${unit}`, { count: nofUnits })}
        onChange={setNofUnits}
        disabled={disableTyping}
      />
      <Button
        onClick={e => {
          e.stopPropagation();
          setNofUnits(nofUnits + 1);
        }}
      >
        +
      </Button>
    </PickerStyle>
  );
};

export default Picker;
