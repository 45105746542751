import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { ctaButtonCss } from '_common/components/button/FixedFullWidthButton.style';

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: fixed;
  z-index: 1;

  right: 0;
  bottom: 0;
  left: 0;

  max-width: 980px;
  margin: auto;

  border-top: 2px solid
    ${({ theme }) => {
      return theme.palette.black;
    }};
  background-color: ${({ theme }) => {
    return theme.palette.black;
  }};
`;

export const FooterLine = styled.div<{ border: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 10px 26px;
  border-bottom: ${({ theme, border }) => {
    return border ? `1.5px dotted ${theme.palette.black}` : 'none';
  }};
  color: ${({ theme, color = theme.palette.black }) => {
    return color;
  }};
`;

export const FooterLink = styled(Link)`
  ${ctaButtonCss}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Loader = styled.div<{ delay: string }>`
  animation-name: ${fadeIn};
  animation-delay: ${props => {
    return props.delay;
  }};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: both;
`;

export const CheckoutButtonStyle = styled.button`
  ${ctaButtonCss}
`;
