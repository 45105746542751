import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isBeforeMonthIndex } from 'utils/date/format';
import { Label } from 'utils/fonts';
import theme from 'theme';

const LetterContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
`;

const InlineLabel = styled(Label)`
  display: inline-block;
  text-align: center;
  width: ${100 / 12}%;

  line-height: 25px;
  margin-bottom: 0;
`;

const MultiInlineLabel = styled(InlineLabel)`
  height: 100%;
  margin-top: 10px;
  border-right: 1px dashed ${theme.palette.lightGrey};

  :last-child {
    border: none;
  }
`;

const MonthLetters = ({ multi = false }) => {
  const LetterStyle = multi ? MultiInlineLabel : InlineLabel;
  const { t } = useTranslation();
  const months = t('calendar:months', { returnObjects: true });
  return months.map((month, i) => {
    return (
      <LetterStyle
        title={month}
        key={month}
        color={
          isBeforeMonthIndex(i) ? theme.palette.secondary : theme.palette.black
        }
      >
        {month.slice(0, 1)}
      </LetterStyle>
    );
  });
};

const CalendarMonths = ({ multi = false }) => {
  return (
    <LetterContainer>
      <MonthLetters multi={multi} />
    </LetterContainer>
  );
};

export default CalendarMonths;
