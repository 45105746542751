import React from 'react';
import { find } from 'lodash';
import { connect } from 'react-redux';
import { forSaleStatuses } from '@dagensmat/core';
import PaddingContainer from '_common/components/utils/PaddingContainer.style';
import { DeliveryDateContext } from '_common/context/DeliveryDateContext';
import Divider from '_common/components/utils/Divider.style';
import ProducerViewContainer from '_consumer/components/producer/ProducerViewContainer';
import REQ from 'utils/REQ';
import usePageView from '_common/hooks/usePageView';
import { PRODUCT_PAGE_VIEW } from 'utils/mixpanel';
import { isGuestConsumer } from 'utils/role';
import ProductView from './ProductView';

const ProductModal = ({
  req,
  product,
  producerId,
  roleType,
  closeModal,
  deliveryDateContext,
  isGuest
}) => {
  if ((req === REQ.SUCCESS && !product) || req !== REQ.SUCCESS) return null;

  const [isolatedContextDeliveryDate, setIsolatedContextDeliveryDate] =
    React.useState();
  usePageView(PRODUCT_PAGE_VIEW, {
    productId: product._id,
    isOrderItem: product.forSaleStatus === forSaleStatuses.ON_REQUEST
  });

  const context = React.useMemo(() => {
    return {
      pageContext: deliveryDateContext,
      isolatedContextDeliveryDate,
      setIsolatedContextDeliveryDate
    };
  }, [isolatedContextDeliveryDate, deliveryDateContext]);

  return (
    <DeliveryDateContext.Provider value={context}>
      <ProductView
        product={product}
        showImage
        roleType={roleType}
        closeModal={closeModal}
        isGuest={isGuest}
      />
      {producerId && (
        <>
          <Divider />
          <PaddingContainer>
            <ProducerViewContainer
              producerId={producerId}
              roleType={roleType}
            />
          </PaddingContainer>
        </>
      )}
    </DeliveryDateContext.Provider>
  );
};

const mapStateToProps = (
  { productsForSale: { req, items }, auth: { _type: roleType, _id: userId } },
  { productId }
) => {
  const product = find(items, { _id: productId });

  return {
    req,
    product,
    producerId: product && product.producer._id,
    roleType,
    isGuest: isGuestConsumer(userId)
  };
};

export default connect(mapStateToProps)(ProductModal);
