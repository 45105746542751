import React from 'react';
import styled from 'styled-components';
import margins, { MarginProps } from 'utils/style';
import theme, { Color, Spacing } from 'theme';

type SvgProps = {
  fill?: Color;
  rotate?: 0 | 90 | 180 | 270;
};

type IconProps = MarginProps &
  SvgProps & {
    icon: keyof typeof icons;
    $size?: Spacing;
  };

const IconContainer = styled.i<MarginProps & { $size?: Spacing }>`
  display: inline-block;
  vertical-align: bottom;
  height: ${({ $size }) => {
    return theme.spacing[$size] || theme.spacing.s;
  }}px;
  width: ${({ $size }) => {
    return theme.spacing[$size] || theme.spacing.s;
  }}px;

  ${margins}
  > svg {
    height: 100%;
    width: auto;
  }
`;

const icons = {
  chevron: ({ fill = 'black', rotate = 0 }: SvgProps) => {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m8.7947 4.8856 5.1467 5.1467a.2.2 0 0 1 0 .2829l-.6262.6262a.2.2 0 0 1-.2829 0L8.0087 5.9178l-5.0183 5.0183a.2.2 0 0 1-.2828 0l-.6263-.6263a.2.2 0 0 1 0-.2828l5.7872-5.7871a.2.2 0 0 1 .2828 0l.6263.6262a.2027.2027 0 0 1 .017.0195Z"
          fill={theme.palette[fill]}
          transform={`rotate(${rotate}, 8, 8)`}
        />
      </svg>
    );
  },
  clear: ({ fill = 'white' }: SvgProps) => {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3495 3.71152C13.4276 3.63342 13.4276 3.50679 13.3495 3.42868L12.5717 2.65086C12.4936 2.57276 12.3669 2.57276 12.2888 2.65086L7.99991 6.93979L3.7111 2.65098C3.63299 2.57288 3.50636 2.57288 3.42825 2.65098L2.65044 3.4288C2.57233 3.5069 2.57233 3.63354 2.65044 3.71164L6.93925 8.00045L2.65098 12.2887C2.57287 12.3668 2.57287 12.4935 2.65098 12.5716L3.4288 13.3494C3.5069 13.4275 3.63353 13.4275 3.71164 13.3494L7.99991 9.06111L12.2883 13.3495C12.3664 13.4276 12.493 13.4276 12.5711 13.3495L13.349 12.5717C13.4271 12.4936 13.4271 12.3669 13.349 12.2888L9.06057 8.00045L13.3495 3.71152Z"
          fill={theme.palette[fill]}
        />
      </svg>
    );
  },
  alert: ({ fill = 'error' }: SvgProps) => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 19.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Zm-.713-5.398L11.131 8.5h1.738l-.152 5.602h-1.43Zm1.652 1.562a.95.95 0 0 1-.937.938.933.933 0 1 1 0-1.867c.5 0 .933.417.938.93Z"
          fill={theme.palette[fill]}
        />
      </svg>
    );
  }
};

const Icon = ({ icon, fill, rotate, ...rest }: IconProps) => {
  const IconComp = icons[icon];
  return (
    <IconContainer {...rest}>
      <IconComp fill={fill} rotate={rotate} />
    </IconContainer>
  );
};

export default Icon;
