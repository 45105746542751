import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import margins, { MarginProps } from 'utils/style';
import theme, { ColorValue } from 'theme';

export const TRANSITION_DURATION = 320;

export const ButtonContainer = styled.div<{
  verticalSpacer?: number;
  horizontalSpacer?: number;
}>`
  padding: 20px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  > * {
    margin-bottom: ${({ verticalSpacer = 10 }) => {
      return `${verticalSpacer}px`;
    }};
    :last-child {
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: 600px) {
    display: block;
    text-align: right;
    width: auto;

    > * {
      margin-left: ${({ horizontalSpacer = 10 }) => {
        return `${horizontalSpacer}px`;
      }};
      :first-child {
        margin-left: 0;
      }
    }
  }
`;

export const MultiButtonContainer = styled.div<MarginProps>`
  padding: 20px 0;
  ${margins}

  > *:nth-child(2) {
    float: right;
  }
`;

export type Variant = 'primary' | 'secondary' | 'borderless' | 'error' | 'ghost';

type VariantStyle = {
  bg: ColorValue;
  borderColor: ColorValue;
  color: ColorValue;
};

const variants: Record<Variant, VariantStyle> = {
  primary: {
    bg: theme.palette.new,
    borderColor: theme.palette.new,
    color: theme.palette.black
  },
  secondary: {
    bg: theme.palette.black,
    borderColor: theme.palette.black,
    color: theme.palette.white
  },
  borderless: {
    bg: theme.palette.transparent,
    borderColor: theme.palette.transparent,
    color: theme.palette.black
  },
  error: {
    bg: theme.palette.transparent,
    borderColor: theme.palette.error,
    color: theme.palette.error
  },
  ghost: {
    bg: theme.palette.transparent,
    borderColor: theme.palette.black,
    color: theme.palette.black
  }
};

export type ButtonProps = {
  variant?: Variant;
} & MarginProps;

const buttonStyle = css<ButtonProps>`
  position: relative;
  display: inline-block;

  padding: 8px 10px;
  ${margins}

  background-color: ${({ variant = 'ghost' }) => {
    return variants[variant].bg;
  }};
  color: ${({ variant = 'ghost' }) => {
    return variants[variant].color;
  }};

  border: 2px solid
    ${({ variant = 'ghost' }) => {
      return variants[variant].borderColor;
    }};
  border-radius: ${theme.borderRadius.base}px;

  font-size: ${theme.typography.fontSize.buttonText}px;
  font-weight: ${theme.typography.fontWeight.medium}px;
  line-height: ${theme.spacing.s}px;
  letter-spacing: 0.25px;
  white-space: nowrap;

  transition: color ${TRANSITION_DURATION}ms ease-in-out,
    background-color ${TRANSITION_DURATION}ms ease-in-out,
    border-color ${TRANSITION_DURATION}ms ease-in-out,
    opacity ${TRANSITION_DURATION}ms ease-in-out;

  :disabled {
    color: ${theme.palette.secondary};
    border-color: ${theme.palette.lightGrey};
    background-color: ${theme.palette.lightGrey};
    cursor: initial;
  }

  :disabled:hover {
    opacity: 1;
  }

  :hover {
    opacity: 0.5;
  }

  @media (hover: none) {
    :hover {
      opacity: 1;
    }
  }

  @media print {
    display: none;
  }
`;

const Button = styled.button`
  ${buttonStyle}
`;

export const ButtonLink = styled(Link)`
  ${buttonStyle}
`;

export const ButtonAnchor = styled.a`
  ${buttonStyle}
`;

export const UnstyledButton = styled.button`
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  background-color: transparent;
`;

export const TagButton = styled(Button)`
  padding: 8px 18px;
  margin: 0 ${theme.spacing.xxs}px ${theme.spacing.xs}px 0;
`;

export default Button;
