import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import usePageView from '_common/hooks/usePageView';
import Button from '_common/components/button/Button.style';
import Container from '_common/components/container/Container.style';
import theme from 'theme';
import PageHeader from '_common/components/page-header/PageHeader';
import { Loader } from '_consumer/components/checkout-footers/CheckoutFooter.style';
import {
  track,
  EDIT_DELIVERY_INFO_MODAL_SAVE,
  EDIT_DELIVERY_INFO_MODAL_EDIT_DELIVERY_INSTRUCTIONS,
  EDIT_DELIVERY_INFO_MODAL_EDIT_DELIVERY_ADDRESS,
  EDIT_DELIVERY_INFO_MODAL_VIEW,
  EDIT_DELIVERY_INFO_MODAL_ADD_DELIVERY_INSTRUCTIONS
} from 'utils/mixpanel';
import { AUTO_REQUEST_TIMEOUT_MS } from 'config';
import FormField from '_common/components/input/FormField';
import Typography from '_common/components/utils/Typography';

type DeliveryInformationFormModalProps = {
  onSave: ({
    deliveryAddress,
    deliveryInfo
  }: {
    deliveryAddress?: string;
    deliveryInfo?: string;
  }) => Promise<void>;
  closeModal: () => void;
  deliveryInfo: string;
  deliveryAddress: string;
  headerTexti18nKey: string;
};

const DeliveryInformationFormModal = ({
  onSave,
  closeModal,
  deliveryInfo: prevDeliveryInfo,
  deliveryAddress: prevDeliveryAddress,
  headerTexti18nKey
}: DeliveryInformationFormModalProps) => {
  usePageView(EDIT_DELIVERY_INFO_MODAL_VIEW);
  const { t } = useTranslation();
  const [deliveryInfo, setDeliveryInfo] = React.useState(
    prevDeliveryInfo || ''
  );
  const [deliveryAddress, setDeliveryAddress] =
    React.useState(prevDeliveryAddress);
  const [isLoading, setIsLoading] = React.useState(false);

  const debouncedTrackEditField = useDebouncedCallback(
    (field: 'deliveryAddress' | 'deliveryInfo') => {
      if (field === 'deliveryAddress') {
        track(EDIT_DELIVERY_INFO_MODAL_EDIT_DELIVERY_ADDRESS);
      }
      if (field === 'deliveryInfo') {
        track(EDIT_DELIVERY_INFO_MODAL_EDIT_DELIVERY_INSTRUCTIONS);
      }
    },
    AUTO_REQUEST_TIMEOUT_MS
  );

  return (
    <Container padding={theme.spacing.l}>
      <PageHeader
        headerText={t(headerTexti18nKey)}
        subTitle={
          <Typography variant="paragraph" my={theme.spacing.s}>
            {t('consumer:DeliveryInformationModalSubtitle')}
          </Typography>
        }
        variant="secondaryHeading"
      />
      <FormField
        type="text"
        labelText={t('consumer:DeliveryAddress')}
        placeholder={t('consumer:LocationGoodsUsuallyDelivered')}
        value={deliveryAddress}
        onChange={({ target: { value } }) => {
          setDeliveryAddress(value);
          debouncedTrackEditField('deliveryAddress');
        }}
        helpText={t('consumer:DeliveryAddressExplanationText')}
        name="deliveryAddress"
      />
      <FormField
        type="text"
        labelText={t('consumer:DeliveryInformationFieldLabel')}
        value={deliveryInfo}
        placeholder={t('consumer:DeliveryInformationPlaceholder')}
        helpText={t('consumer:DeliveryInformationExplanation')}
        onChange={({ target: { value } }) => {
          setDeliveryInfo(value);
          debouncedTrackEditField('deliveryInfo');
        }}
        maxLength={120}
        name="deliveryInfo"
      />
      {isLoading ? (
        <Loader delay="80ms">{t('common:Saving')}</Loader>
      ) : (
        <Button
          variant="primary"
          onClick={async () => {
            setIsLoading(true);
            if (
              prevDeliveryInfo === undefined &&
              deliveryInfo !== prevDeliveryInfo
            ) {
              track(EDIT_DELIVERY_INFO_MODAL_ADD_DELIVERY_INSTRUCTIONS);
            }
            await onSave({ deliveryInfo, deliveryAddress });
            debouncedTrackEditField.flush();
            track(EDIT_DELIVERY_INFO_MODAL_SAVE);
            setIsLoading(false);
            closeModal();
          }}
        >
          {t('common:SubmitDeliveryInformation')}
        </Button>
      )}
    </Container>
  );
};

export default DeliveryInformationFormModal;
