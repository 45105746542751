import React from 'react';
import styled from 'styled-components';
import chevron from 'assets/chevron.svg';
import margins from 'utils/style';
import { TRANSITION_DURATION } from '_common/components/button/Button.style';

const Wrapper = styled.div<{ width: SelectProps['width'] }>`
  --chevron-size: ${({ theme }) => {
    return `${theme.spacing.s}px`;
  }};
  display: inline-block;
  position: relative;
  backface-visibility: hidden;
  width: ${({ width }) => {
    return width;
  }};

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: var(--chevron-size);
    height: var(--chevron-size);
    top: 50%;
    right: var(--chevron-size);
    transform: translate3d(0, -50%, 0);
    background-image: url(${chevron});
    pointer-events: none;
  }
`;

const SelectStyle = styled.select`
  --padding: ${({ theme }) => {
    return `${theme.spacing.xs + theme.spacing.border}px`;
  }};

  cursor: pointer;
  display: inline-block;
  width: 100%;
  color: ${({ theme }) => {
    return theme.palette.black;
  }};
  font-size: ${({ theme }) => {
    return `${theme.typography.fontSize.inputText}px`;
  }};
  line-height: ${({ theme }) => {
    return `${theme.spacing.m}px`;
  }};
  border: 2px solid
    ${({ theme }) => {
      return theme.palette.grey;
    }};
  border-radius: ${({ theme }) => {
    return `${theme.borderRadius.base}px`;
  }};
  margin: 0;
  padding: var(--padding);
  padding-right: calc((var(--chevron-size) * 3) - var(--padding));
  transition: border-color ${TRANSITION_DURATION}ms ease-in-out;

  appearance: none;
  outline: none;
  ${margins}

  &:focus,
  &:hover {
    border-color: ${({ theme }) => {
      return theme.palette.black;
    }};
  }
`;

export interface SelectProps {
  options: { value: string; name: string }[];
  selectedValue: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  id: string;
  width?: string;
}

const Select = ({
  options = [],
  selectedValue,
  onChange,
  disabled,
  id,
  width = '150px'
}: SelectProps) => {
  return (
    <Wrapper width={width}>
      <SelectStyle
        id={id}
        value={selectedValue}
        onChange={({ target: { value } }) => {
          return onChange(value);
        }}
        disabled={disabled}
      >
        {options.map(({ value, name }) => {
          return (
            <option key={value} value={value}>
              {name}
            </option>
          );
        })}
      </SelectStyle>
    </Wrapper>
  );
};

export default Select;
