import React from 'react';
import { forSaleStatuses } from '@dagensmat/core';
import { isGuestConsumer } from 'utils/role';
import { useAppSelector } from '_common/hooks/reduxHooks';
import ProductOnRequestCard from './ProductOnRequestCard';
import ProductForSaleCard from './ProductForSaleCard';

const ProductCard = ({ product }) => {
  const { forSaleStatus } = product;
  const { isGuest } = useAppSelector(({ auth: { _id } }) => {
    return {
      isGuest: isGuestConsumer(_id)
    };
  });

  switch (forSaleStatus) {
    case forSaleStatuses.FOR_SALE:
      return <ProductForSaleCard product={product} isGuest={isGuest} />;
    case forSaleStatuses.ON_REQUEST:
      return <ProductOnRequestCard product={product} isGuest={isGuest} />;
    default:
      return null;
  }
};

export default ProductCard;
