import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import Typography from '_common/components/utils/Typography';
import FormLabel from '_common/components/input/FormLabel';
import { mapOptions, UnitOption } from './RadioButtons';

const RadioButtonLabel = styled(Typography).attrs(() => {
  return {
    variant: 'inputLabel'
  };
})`
  --borderWidth: ${theme.spacing.border}px;
  --inputSize: ${theme.spacing.s + theme.spacing.xxs}px;

  display: flex;
  width: auto;
  margin-bottom: ${theme.spacing.xxs}px;
  padding: ${theme.spacing.xxs}px;

  * {
    cursor: pointer;
  }

  > div {
    margin-left: ${theme.spacing.xxs}px;
  }

  input {
    margin: ${theme.spacing.border}px;
    width: var(--inputSize);
    height: var(--inputSize);
    border: var(--borderWidth) solid ${theme.palette.secondary};
    border-radius: calc(var(--inputSize) / 2);
    flex-shrink: 0;
  }

  input:disabled + div > span:first-child {
    color: ${theme.palette.secondary};
  }

  input:checked:before {
    content: '';
    display: block;
    background: ${theme.palette.black};
    width: ${theme.spacing.xs}px;
    height: ${theme.spacing.xs}px;
    margin: ${theme.spacing.xxs}px auto;
    border-radius: calc(var(--inputSize) / 2);
  }

  input:checked {
    border: var(--borderWidth) solid ${theme.palette.black};
  }
`;

export type NewRadioButtonsProps = {
  options: UnitOption[];
  currentValue: string;
  radioGroupName: string;
  onChange: (key: string) => void;
  labelText: string;
};

const NewRadioButtons = ({
  options,
  currentValue,
  radioGroupName,
  onChange,
  labelText
}: NewRadioButtonsProps) => {
  const isChecked = (key: string): boolean => {
    return currentValue === key;
  };
  return (
    <div>
      <FormLabel labelText={labelText} id={radioGroupName} />
      {mapOptions(options).map(
        ({ key, value, explanationText, disabled = false }) => {
          return (
            <RadioButtonLabel key={key}>
              <input
                id={value}
                type="radio"
                name={radioGroupName}
                checked={isChecked(key)}
                onChange={() => {
                  return onChange(key);
                }}
                value={value}
                disabled={disabled}
              />
              <div>
                <Typography
                  variant="paragraph"
                  weight={isChecked(key) ? 'semiBold' : 'regular'}
                  as={isChecked(key) ? 'strong' : 'span'}
                >
                  {value}
                </Typography>
                {explanationText && (
                  <Typography
                    variant="paragraphSmall"
                    color={theme.palette.secondary}
                    as="span"
                    style={{ display: 'block' }}
                  >
                    {explanationText}
                  </Typography>
                )}
              </div>
            </RadioButtonLabel>
          );
        }
      )}
    </div>
  );
};

export default NewRadioButtons;
