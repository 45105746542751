import React from 'react';
import { ReactComponent as X } from 'assets/x.svg';
import { UnstyledButton } from '_common/components/button/Button.style';

type CloseButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const CloseButton = ({ onClick, ...rest }: CloseButtonProps) => {
  return (
    <UnstyledButton onClick={onClick} {...rest}>
      <X />
    </UnstyledButton>
  );
};

export default CloseButton;
