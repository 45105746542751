import React from 'react';
import { connect } from 'react-redux';
import ProductBuyer from '_common/components/product-buyer/ProductBuyer';
import { updateSelectedDeliveryDate } from '_consumer/reducers/productsForSale';
import { updateBasket } from '_consumer/reducers/basket';
import {
  DeliveryDateContext,
  chooseDeliveryDateFromContext,
  DELIVERY_DATE_CONTEXT_DEFAULT
} from '_common/context/DeliveryDateContext';
import { track, SELECT_DELIVERY_DAY_PRODUCT } from 'utils/mixpanel';
import MarginContainer2 from '_common/components/utils/MarginContainer2.style';
import theme from 'theme';
import ProductDeliveryDateSelection from './ProductDeliveryDateSelection';

export const isProductBuyable = (
  availableDeliveryDates = [],
  deliveryDates = []
) => {
  return Boolean(
    availableDeliveryDates.find(availableDeliveryDate => {
      return deliveryDates.includes(availableDeliveryDate);
    })
  );
};

const ModalBuyerWrapper = ({
  product,
  selectedDeliveryDate,
  availableDeliveryDates,
  dispatch
}) => {
  const context = React.useContext(DeliveryDateContext);

  const { pageContext, setIsolatedContextDeliveryDate } = context;

  const { deliveryDates, _id: productId } = product;

  if (!isProductBuyable(availableDeliveryDates, deliveryDates)) return null;

  const deliveryDate = chooseDeliveryDateFromContext(
    context,
    selectedDeliveryDate
  );

  const setDeliveryDate = date => {
    dispatch(updateBasket({ deliveryDate: date, productId, units: 1 }));

    if (pageContext === DELIVERY_DATE_CONTEXT_DEFAULT) {
      dispatch(updateSelectedDeliveryDate(date));
    } else {
      setIsolatedContextDeliveryDate(date);
    }
    track(SELECT_DELIVERY_DAY_PRODUCT);
  };

  return (
    <MarginContainer2 my={theme.spacing.m}>
      {deliveryDate ? (
        <ProductBuyer product={product} selectedDeliveryDate={deliveryDate} />
      ) : (
        <ProductDeliveryDateSelection
          product={product}
          availableDeliveryDates={availableDeliveryDates}
          setDeliveryDate={setDeliveryDate}
        />
      )}
    </MarginContainer2>
  );
};

const mapStateToProps = ({
  productsForSale: { selectedDeliveryDate, availableDeliveryDates }
}) => {
  return {
    selectedDeliveryDate,
    availableDeliveryDates
  };
};

export default connect(mapStateToProps)(ModalBuyerWrapper);
