import React from 'react';
import { useTranslation } from 'react-i18next';
import MarginContainer2 from '_common/components/utils/MarginContainer2.style';
import theme from 'theme';
import { formatPeriod, parseDate } from 'utils/date/format';
import {
  getAlwaysInSeason,
  isAlwaysInSeason,
  validatePeriod,
  updateCalendar,
  addToSeasonCalendar
} from 'utils/season';
import SingleProducerSeason from '_common/components/season/SingleProductSeason';
import Button from '_common/components/button/Button.style';
import SelectedButton from '_common/components/button/SelectedButton';
import DateRangePickerWithButtons from '_common/components/date-range-picker/DateRangePickerWithButtons';
import FormLabel from '_common/components/input/FormLabel';
import FormValidationMessage from '_common/components/input/FormValidationMessage';
import useWindowWidth from '_common/hooks/useWindowWidth';
import { initialRange } from '_common/components/date-range-picker/DateRangePicker';
import { DAYPICKER_MIN_DESKTOP_WIDTH, numberOfMonths } from 'utils/dayPicker';

const buttonMargins = { mr: theme.spacing.xxs, mb: theme.spacing.xs };

const EmptySeasonCalendarButton = ({ seasonCalendar = [], onChange }) => {
  const { t } = useTranslation();

  return (
    seasonCalendar.length === 0 && (
      <Button
        {...buttonMargins}
        onClick={() => {
          onChange(getAlwaysInSeason());
        }}
      >
        {t('producer:productSeasonCalendarButton1')}
      </Button>
    )
  );
};

const AlwaysAvailableButton = ({ seasonCalendar = [], onChange }) => {
  const { t } = useTranslation();
  return (
    isAlwaysInSeason(seasonCalendar) && (
      <SelectedButton
        {...buttonMargins}
        onClick={() => {
          return onChange([]);
        }}
      >
        {t('producer:productSeasonCalendarButton1')}
      </SelectedButton>
    )
  );
};

const SeasonPeriodButtons = ({
  seasonCalendar = [],
  selectedIndex,
  setSelectedIndex
}) => {
  const { t } = useTranslation();
  return (
    !isAlwaysInSeason(seasonCalendar) && (
      <>
        {seasonCalendar.map((period, i) => {
          return (
            <Button
              key={period._key}
              {...buttonMargins}
              variant={selectedIndex === i ? 'secondary' : 'ghost'}
              onClick={() => {
                return selectedIndex === i
                  ? setSelectedIndex(undefined)
                  : setSelectedIndex(i);
              }}
            >
              {formatPeriod(period)}
            </Button>
          );
        })}

        <Button
          {...buttonMargins}
          variant={selectedIndex === -1 ? 'secondary' : 'ghost'}
          onClick={() => {
            return selectedIndex === -1
              ? setSelectedIndex(undefined)
              : setSelectedIndex(-1);
          }}
        >
          {t('producer:productSeasonCalendarAddPeriod')}
        </Button>
      </>
    )
  );
};

const AddOrEditPeriod = ({ selectedIndex, seasonCalendar = [], onSave }) => {
  const { t } = useTranslation();
  const [dates, setDates] = React.useState();

  const windowWidth = useWindowWidth();

  React.useEffect(() => {
    if (seasonCalendar[selectedIndex]) {
      setDates({
        from: parseDate(seasonCalendar[selectedIndex].from),
        to: parseDate(seasonCalendar[selectedIndex].to)
      });
    } else {
      setDates({ initialRange });
    }
  }, [selectedIndex]);

  return (
    selectedIndex >= -1 && (
      <MarginContainer2
        style={{
          width: windowWidth < DAYPICKER_MIN_DESKTOP_WIDTH ? '100%' : 570 // 570px is the rounded width of displaying 2 months
        }}
        mb={theme.spacing.s}
      >
        <DateRangePickerWithButtons
          key={selectedIndex}
          saveButtonText={
            selectedIndex === -1
              ? t('producer:productSeasonCalendarSaveButton1', {
                  period: formatPeriod(dates)
                })
              : t('producer:productSeasonCalendarSaveButton2', {
                  period: formatPeriod(dates)
                })
          }
          resetButtonText={
            selectedIndex === -1
              ? t('common:reset')
              : t('producer:productSeasonCalendarResetButton2')
          }
          initialDates={seasonCalendar[selectedIndex]}
          onSave={newDates => {
            setDates(initialRange);
            onSave(newDates);
          }}
          value={dates}
          onChange={setDates}
          monthsToShow={numberOfMonths({ width: windowWidth })}
        />
      </MarginContainer2>
    )
  );
};

const ProductSeasonInput = props => {
  const { onChange, seasonCalendar = [] } = props;

  const [selectedIndex, setSelectedIndex] = React.useState();

  const savePeriod = period => {
    if (selectedIndex > -1 && validatePeriod(period)) {
      onChange(updateCalendar(seasonCalendar, period, selectedIndex));
    }

    if (selectedIndex > -1 && !validatePeriod(period)) {
      onChange([
        ...seasonCalendar.slice(0, selectedIndex),
        ...seasonCalendar.slice(selectedIndex + 1)
      ]);
    }

    if (selectedIndex === -1 && validatePeriod(period)) {
      onChange(addToSeasonCalendar(seasonCalendar, period));
    }

    setSelectedIndex();
  };
  const { t } = useTranslation();
  return (
    <>
      <FormLabel labelText={t('producer:productSeasonCalendarLabel')} />
      <div>
        <EmptySeasonCalendarButton
          seasonCalendar={seasonCalendar}
          onChange={onChange}
        />
        <AlwaysAvailableButton
          seasonCalendar={seasonCalendar}
          onChange={onChange}
        />
        <SeasonPeriodButtons
          seasonCalendar={seasonCalendar}
          onChange={onchange}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
      </div>
      {seasonCalendar.length === 0 && (
        <MarginContainer2 mb={theme.spacing.xs}>
          <FormValidationMessage
            message={t('producer:SeasonCalendarEmptyValidationMessage')}
          />
        </MarginContainer2>
      )}
      <AddOrEditPeriod
        onSave={savePeriod}
        seasonCalendar={seasonCalendar}
        selectedIndex={selectedIndex}
      />
      <SingleProducerSeason seasonCalendar={seasonCalendar} />
    </>
  );
};

export default ProductSeasonInput;
