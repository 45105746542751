import queryString from 'query-string';
import { baseUrl } from 'config';
import { getItemFromLocalStorage } from 'utils/clientcache';

const handleResponse = async (response: Response) => {
  if ([201, 204, 401].includes(response.status)) return response;

  const responseJson = await response.json();

  if (response.status >= 400) {
    const msg = responseJson.msg || responseJson.message;
    throw new Error(msg);
  }

  return responseJson;
};

const getDefaultHeaders = () => {
  return {
    Accept: 'application/json',
    From: getItemFromLocalStorage('fromHeader') as string
  };
};

export const getRequest = (endpoint: string) => {
  return async (params?: Record<string, any>) => {
    const response = await fetch(
      `${baseUrl}${endpoint}?${queryString.stringify(params)}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: getDefaultHeaders()
      }
    );

    return handleResponse(response);
  };
};

export const postRequest = (endpoint: string, method = 'POST') => {
  return async (payload?: Record<string, any>) => {
    const response = await fetch(`${baseUrl}${endpoint}`, {
      method,
      credentials: 'include',
      headers: {
        ...getDefaultHeaders(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    return handleResponse(response);
  };
};

export const deleteRequest = (endpoint: string) => {
  return async (payload?: Record<string, any>) => {
    return postRequest(endpoint, 'DELETE')(payload);
  };
};

export const postFormDataRequest = (endpoint: string) => {
  return async (payload?: FormData) => {
    const response = await fetch(`${baseUrl}${endpoint}`, {
      method: 'POST',
      credentials: 'include',
      headers: getDefaultHeaders(),
      body: payload
    });

    return handleResponse(response);
  };
};
