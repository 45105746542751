export const gitCommitRef = process.env.REACT_APP_COMMIT_REF || 'development';
export const environment = process.env.REACT_APP_ENV || 'development';
export const dataset = {
  production: 'production',
  prodStaging: 'production',
  test: 'staging',
  development: 'development'
}[environment];

export const baseUrl =
  process.env.REACT_APP_API_URL || 'http://localhost:3000/api';

export const mixpanelOptOutLoginEmails =
  environment === 'production'
    ? [
        'stianlp@gmail.com',
        'maximo@dagens.co',
        'maximoist@gmail.com',
        'kristoffertorheim@gmail.com',
        'kristina@dagensmat.no',
        'kristina.gardli@gmail.com',
        'hafdissunna@simnet.is',
        'sunna@dagensmat.no'
      ]
    : [];

const searchIndexPrefix = {
  production: 'prod_',
  prodStaging: 'prod_',
  test: 'test_',
  development: 'dev_'
}[environment];

export const productsSearchIndex = `${searchIndexPrefix}PRODUCTS`;

export const producersSearchIndex = `${searchIndexPrefix}PRODUCERS`;

export const defaultDistributionAreaId = {
  production: '22d04fb7-3be8-4eb7-ab91-d542e8cb340a',
  prodStaging: '22d04fb7-3be8-4eb7-ab91-d542e8cb340a',
  test: 'oRIVsNwDQfifK9rvO1mI0H',
  development: '1468a87e-8223-4abd-beaa-09ce7cec8a38'
}[environment];

export const AUTO_REQUEST_TIMEOUT_MS = 700;

export const PAGE_SIZE = 20;

export const PUBLIC_RELEASE_NOTES_URL =
  'https://dagensfarm.notion.site/Platform-updates-2120a951976b44a9be1f1fb771b364fb';
