import React from 'react';
import styled from 'styled-components';
import { P } from 'utils/fonts';
import theme from 'theme';

const Container = styled.div`
  margin: 15px 0;
`;

const Legend = styled(P)`
  margin-right: 10px;
  display: inline-block;
`;

const LegendIcon = styled.span`
  padding: 0 8px;
  margin-right: 4px;
  background: ${props => {
    return props.background;
  }};
  border-radius: ${theme.borderRadius.small}px;
`;

const Legends = ({ legends = [] }) => {
  return (
    <Container>
      {legends.map(({ name, color }) => {
        return (
          <Legend key={name}>
            <LegendIcon background={color} />
            {name}
          </Legend>
        );
      })}
    </Container>
  );
};

export default Legends;
