import React from 'react';
import { connect } from 'react-redux';
import { PRODUCT_CARD_CLICKED, track } from 'utils/mixpanel';
import { getImageUrl } from 'utils/imageUrls';
import { showProductModal } from '_common/reducers/modal';
import {
  CardContainer as Container,
  CardImageContainer as ImageContainer,
  Content
} from '_common/components/product/Product.style';
import ProductTag from '_common/components/product/ProductTag';
import ProductInfo from '_common/components/product/ProductInfo';
import LikeProduct from '_common/components/product/LikeProduct';
import { DeliveryDateContext } from '_common/context/DeliveryDateContext';
import { useAppDispatch } from '_common/hooks/reduxHooks';
import CardBuyerWrapper from './CardBuyerWrapper';

const ProductForSaleCard = ({ product, isGuest, selectedDeliveryDate }) => {
  const dispatch = useAppDispatch();
  const { _id: productId, image, name } = product;

  const imageUrl = getImageUrl(image, { width: 500 });
  const { pageContext } = React.useContext(DeliveryDateContext);
  return (
    <Container
      onClick={() => {
        dispatch(
          showProductModal({ productId, deliveryDateContext: pageContext })
        );
        track(PRODUCT_CARD_CLICKED);
      }}
    >
      <ImageContainer imageUrl={imageUrl} name={name} />
      <LikeProduct productId={productId} isGuest={isGuest} />
      <ProductTag product={product} deliveryDate={selectedDeliveryDate} />
      <Content>
        <ProductInfo product={product} />
        <CardBuyerWrapper product={product} />
      </Content>
    </Container>
  );
};

const mapStateToProps = ({ productsForSale: { selectedDeliveryDate } }) => {
  return {
    selectedDeliveryDate
  };
};

export default connect(mapStateToProps)(ProductForSaleCard);
