import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { forSaleStatuses } from '@dagensmat/core';
import Tag from '_common/components/utils/Tag';
import theme, { ColorValue } from 'theme';
import type { ProducerProduct } from 'types/Product';
import type { DeliveryDate } from '_common/components/search/DeliveryDateOptions';
import { isSoldOut, isNew } from '_consumer/reducers/productsForSale';

const TagContainer = styled.div`
  position: absolute;
  left: 15px;
  top: 15px;
`;

type ProductLabelProps = {
  product: ProducerProduct;
  deliveryDate?: DeliveryDate;
};

interface ProductLabelState {
  textKey: string;
  backgroundColor?: ColorValue;
}

const getLabelState = (product, deliveryDate): ProductLabelState | null => {
  switch (true) {
    case product.hasExclusivePricing:
      return {
        textKey: 'common:Exclusive',
        backgroundColor: theme.palette.noteworthy
      };
    case product.hasSpecialPricing:
      return {
        textKey: 'common:SpecialPriceForYou',
        backgroundColor: theme.palette.noteworthy
      };
    case product.forSaleStatus === forSaleStatuses.ON_REQUEST:
      return {
        textKey: 'common:ON_REQUEST',
        backgroundColor: theme.palette.messages
      };
    case isNew(product):
      return {
        textKey: 'common:new',
        backgroundColor: theme.palette.new
      };
    case isSoldOut(product, deliveryDate):
      return {
        textKey: 'common:soldOut'
      };
    default:
      return null;
  }
};

const ProductTag = ({ product, deliveryDate }: ProductLabelProps) => {
  const { t } = useTranslation();
  const state = getLabelState(product, deliveryDate);

  if (state === null) return null;
  const { backgroundColor, textKey }: ProductLabelState = state;
  return (
    <TagContainer>
      <Tag bgColor={backgroundColor} text={t(textKey)} />
    </TagContainer>
  );
};

export default ProductTag;
