import styled from 'styled-components';

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border-top: 1px dashed
    ${({ theme }) => {
      return theme.palette.black;
    }};
  border-bottom: 0;
  margin: 20px 0;
`;

export default Divider;
