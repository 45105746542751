import React from 'react';
import { useTranslation } from 'react-i18next';
import NewRadioButtons from '_common/components/radio-buttons/NewRadioButtons';
import { UnitOption } from '_common/components/radio-buttons/RadioButtons';

type ProcessedStateSelectorProps = {
  onChange: (buttonOption: UnitOption['key']) => void;
  value: UnitOption['key'];
};

const ProcessedStateSelector = ({
  onChange,
  value
}: ProcessedStateSelectorProps) => {
  const { t } = useTranslation();

  return (
    <NewRadioButtons
      labelText={t('producer:ProcessedStateLabel')}
      options={[
        {
          key: 'RAW',
          value: t('producer:Raw'),
          explanationText: t('producer:ExplanationTextRawProcessedState')
        },
        {
          key: 'PROCESSED',
          value: t('producer:Processed'),
          explanationText: t('producer:ExplanationTextProcessedProcessState')
        }
      ]}
      currentValue={value}
      radioGroupName="processedState"
      onChange={onChange}
    />
  );
};
export default ProcessedStateSelector;
