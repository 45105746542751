export type Color = keyof typeof dagensTheme['palette'];
export type ColorValue =
  | typeof dagensTheme['palette'][Color]
  | typeof dagensTheme['hover'][keyof typeof dagensTheme['hover']];
export type Spacing = keyof typeof dagensTheme['spacing'];
export type SpacingValue = typeof dagensTheme['spacing'][Spacing];

const dagensTheme = {
  palette: {
    white: '#ffffff',
    black: '#000000',
    secondary: '#767676',
    grey: '#cacaca',
    lightGrey: '#dedede',
    imagePlaceholder: '#f0f0f0',
    messages: '#ccbfa3',
    new: '#ddccff',
    noteworthy: '#ffe1ba',
    warning: '#ffac59',
    availability: '#dee5cf',
    available: '#9bc193',
    season: '#77b36b',
    error: '#ff4713',
    transparent: 'transparent'
  } as const,
  hover: { new: '#e7dbff' },
  borderRadius: { base: 5, small: 3 },
  spacing: {
    border: 2,
    xxs: 4,
    xs: 8,
    s: 16,
    m: 24,
    l: 32,
    xl: 48,
    jumbo: 96
  },
  typography: {
    fontSize: {
      displayTitle: 40,
      primaryHeading: 34,
      secondaryHeading: 28,
      leadText: 20,
      paragraph: 16,
      inputText: 16,
      buttonText: 14,
      paragraphSmall: 14,
      xs: 12
    },
    fontWeight: {
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700
    }
  }
} as const;

export default dagensTheme;
