import 'react-day-picker/lib/style.css';
import DayPicker from 'react-day-picker';
import styled from 'styled-components';
import theme from 'theme';

const StyledDayPicker = styled(DayPicker)`
  text-align: center;
  display: block;

  &.DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):not(.DayPicker-Day--isNotDeliveryDate):hover {
    background-color: ${theme.palette.lightGrey};
  }

  & .DayPicker-Day {
    border-radius: ${theme.borderRadius.base}px;
    color: ${theme.palette.secondary};

    &.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: ${theme.palette.black};
      color: ${theme.palette.white};
    }

    &--hasDagensLogistics:not(.DayPicker-Day--outside) {
      background-color: ${theme.palette.noteworthy};
      color: ${theme.palette.black};
    }

    &--disabled {
      color: ${theme.palette.lightGrey};
      cursor: not-allowed;
    }

    &--disabled.DayPicker-Day--selected:not(.DayPicker-Day--outside) {
      background-color: ${theme.palette.black};
      color: ${theme.palette.white};
    }

    &--disabled.DayPicker-Day--selected.DayPicker-Day--soldOut:not(.DayPicker-Day--outside) {
      background-color: ${theme.palette.black};
      color: ${theme.palette.white};
    }

    &--today {
      color: ${theme.palette.black};
      font-weight: ${theme.typography.fontWeight.bold};
    }

    &--soldOut:not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
      color: ${theme.palette.black};
      background-color: ${theme.palette.white};
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        display: block;
        border-radius: ${theme.borderRadius.base}px;
        border: 2px solid ${theme.palette.black};
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      &::after {
        content: '';
        display: block;
        border-top: 2px solid ${theme.palette.black};
        position: absolute;
        top: -4px;
        left: -4px;
        right: -50%;
        transform: rotate(45deg);
        transform-origin: 0 0;
      }
    }
  }
`;

export default StyledDayPicker;
