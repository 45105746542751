import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { definedNumber } from '@dagensmat/core';

const InputWithUnitContainer = styled.div<{
  disabled?: boolean;
  width?: number;
}>`
  --padding: ${({ theme }) => {
    return `${theme.spacing.xs + theme.spacing.border}px`;
  }};
  display: flex;
  flex-direction: row;
  border: 2px solid
    ${({ theme, disabled }) => {
      return disabled ? theme.palette.transparent : theme.palette.black;
    }};
  background-color: ${({ theme, disabled }) => {
    return disabled ? theme.palette.lightGrey : theme.palette.white;
  }};
  transition: border 0.4s ease-in-out;
  border-radius: 5px;
  width: ${({ width }) => {
    return `${width}px`;
  }};

  > input {
    flex-grow: 1;
    text-align: right;
    border: none;
    min-width: 0;
    color: ${({ theme }) => {
      return theme.palette.black;
    }};
    margin: 0;
    padding: 0; /* Fix iOS Safari */
    padding: var(--padding);
    padding-right: 0;
    line-height: 24px;
    box-shadow: none; /** Fix Firefox red border on required inputs */
    background-color: inherit;
    border-radius: ${({ theme }) => {
      return `${theme.borderRadius.base}px`;
    }};

    font-size: inherit;

    &:disabled {
      background-color: ${({ theme }) => {
        return theme.palette.lightGrey;
      }};
      opacity: 1; /** Fix iOS Safari when disabled */
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    -moz-appearance: textfield;
  }

  > span {
    line-height: 24px;
    padding: var(--padding);
    padding-left: 4px;
    word-break: keep-all;
  }
`;

type IntegerInputProps = Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> & {
  onChange: (value: number) => void;
};

const IntegerInput = React.forwardRef<HTMLInputElement, IntegerInputProps>(
  ({ onChange, ...rest }, ref) => {
    return (
      <input
        type="tel"
        pattern="d*"
        maxLength={4}
        onChange={({ target: { value: newValue } }) => {
          onChange(Number(newValue.replace(/\D/g, '')));
        }}
        ref={ref}
        onClick={e => {
          return e.stopPropagation();
        }}
        {...rest}
      />
    );
  }
);

type FloatInputProps = Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> & {
  onChange: (value: number | string) => void;
};

const FloatInput = React.forwardRef<HTMLInputElement, FloatInputProps>(
  ({ onChange, ...rest }, ref) => {
    return (
      <input
        type="number"
        onChange={({ target: { value: newValue } }) => {
          const newValueFloat = parseFloat(newValue);
          onChange(definedNumber(newValueFloat) ? newValueFloat : newValue);
        }}
        ref={ref}
        onClick={e => {
          return e.stopPropagation();
        }}
        {...rest}
      />
    );
  }
);

type InputWithUnitProps = {
  value: string | number;
  onChange: (value: number | '') => void;
  decimal?: boolean;
  disabled?: boolean;
  placeholder?: string;
  unit: string;
  textAlign: 'left' | 'right' | 'center';
  id: string;
  width?: number;
};

const InputWithUnit = ({
  value,
  onChange,
  decimal = false,
  disabled = false,
  placeholder = '#',
  unit,
  textAlign,
  id,
  width = 110
}: InputWithUnitProps) => {
  const inputEl = React.useRef(null);
  const themeContext = React.useContext(ThemeContext);

  const Input = decimal ? FloatInput : IntegerInput;

  return (
    <InputWithUnitContainer
      disabled={disabled}
      onClick={e => {
        e.stopPropagation();
        inputEl.current.focus();
      }}
      width={width}
    >
      <Input
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        ref={inputEl}
        placeholder={placeholder}
        style={{ textAlign }}
      />
      <span
        role="presentation"
        style={{
          fontWeight: themeContext.typography.fontWeight.medium,
          color: themeContext.palette.secondary
        }}
      >
        {unit}
      </span>
    </InputWithUnitContainer>
  );
};

export default InputWithUnit;
