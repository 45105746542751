import React from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '_common/components/utils/Flex.style';
import theme from 'theme';
import { ExplanationText } from 'utils/fonts';
import { formatNok2Decimals } from 'utils/texts';
import { EditablePricing } from 'types/Product';

type DisplayPricingStructureProps = {
  transactionCut: number;
  pricing: EditablePricing;
};

const serviceFeeAmount = (
  value: EditablePricing['nokPerPricedUnit'],
  transactionCut: number
): string => {
  if (value === '') return '';

  return formatNok2Decimals(value * transactionCut);
};

const getProductValueAfterCut = (
  value: EditablePricing['nokPerPricedUnit'],
  transactionCut: number
): string => {
  if (value === '') return '';
  return formatNok2Decimals(value - value * transactionCut);
};

const DisplayPricingStructure = ({
  transactionCut,
  pricing
}: DisplayPricingStructureProps) => {
  const { t } = useTranslation();
  const { nokPerPricedUnit } = pricing;

  const transactionCutPercent = transactionCut * 100;
  const serviceFee = serviceFeeAmount(nokPerPricedUnit, transactionCut);
  const valueAfterCut = getProductValueAfterCut(
    nokPerPricedUnit,
    transactionCut
  );

  return (
    <div style={{ width: 300, marginTop: theme.spacing.s, maxWidth: '100%' }}>
      <Flex mt={5}>
        <ExplanationText>
          {t('producer:ServiceFee', { transactionCutPercent })}
        </ExplanationText>
        <ExplanationText mb={5} color={theme.palette.black}>
          {t('producer:ServiceFeeAmount', { serviceFee })}
        </ExplanationText>
      </Flex>
      <Flex bt="2px solid black">
        <ExplanationText mt={5}>{t('producer:YouGetExclVat')}</ExplanationText>
        <ExplanationText color={theme.palette.black} fontWeight={600}>
          {valueAfterCut}
        </ExplanationText>
      </Flex>
    </div>
  );
};

export default DisplayPricingStructure;
