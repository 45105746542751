/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import {
  getCountAndPriceAndUnit,
  definedNumber,
  calculateOrderedUnitPrice
} from '@dagensmat/core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import REQ from 'utils/REQ';
import { getOrders, postOrderStatuses } from 'api';
import { formatTextWithUnits } from 'utils/texts';
import {
  byFunction,
  byKey,
  byDescending,
  byUniqueId,
  addKey,
  grouper
} from 'utils/array';
import { getPickupType } from 'utils/delivery';
import { isWeek } from 'utils/date/format';
import { Order, OrderLine, OrderStatuses } from 'utils/order';
import type { AppDispatch } from 'store';

/** Action creators */

interface OrdersState {
  req: symbol;
  items: Order[];
}

const initialState: OrdersState = {
  req: REQ.INIT,
  items: []
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    clearOrders(state) {
      state.req = REQ.INIT;
      state.items = [];
    },
    orderStatusUpdated(
      state,
      action: PayloadAction<{ orderId: string; statuses: OrderStatuses }>
    ) {
      const order = state.items.find(({ _id }) => {
        return _id === action.payload.orderId;
      });
      if (!order) {
        return;
      }
      order.statuses = { ...order.statuses, ...action.payload.statuses };
    },
    orderLineUpdated(state, action: PayloadAction<any>) {
      const { orderId } = action.payload;
      const orderIndex = state.items.findIndex(({ _id }) => {
        return _id === orderId;
      });

      if (orderIndex === -1) {
        return;
      }

      const order = state.items[orderIndex];

      const { lineKey } = action.payload;
      const orderLineIndex = order.orderLines.findIndex(({ _key }) => {
        return _key === lineKey;
      });

      if (orderLineIndex === -1) {
        return;
      }

      const {
        nrOfOrderedUnitsDelivered,
        nrOfPricedUnitsDelivered,
        nokPerPricedUnit
      } = action.payload;

      state.items[orderIndex].orderLines[
        orderLineIndex
      ].nrOfOrderedUnitsDelivered = nrOfOrderedUnitsDelivered;
      state.items[orderIndex].orderLines[
        orderLineIndex
      ].nrOfPricedUnitsDelivered = nrOfPricedUnitsDelivered;
      state.items[orderIndex].orderLines[
        orderLineIndex
      ].nrOfPricedUnitsDelivered = nrOfPricedUnitsDelivered;
      state.items[orderIndex].orderLines[
        orderLineIndex
      ].pricingAtTimeOfOrder.nokPerPricedUnit = nokPerPricedUnit;
    },
    fetchOrdersRequest(state) {
      state.req = REQ.PENDING;
      state.items = [];
    },
    fetchOrdersError(state) {
      state.req = REQ.ERROR;
      state.items = [];
    },
    fetchOrdersSuccess(state, action: PayloadAction<Order[]>) {
      state.req = REQ.SUCCESS;
      state.items = [...action.payload, ...state.items]
        .filter(byUniqueId)
        .sort(byKey('deliveryDate'));
    }
  }
});

export default ordersSlice.reducer;
export const {
  clearOrders,
  orderStatusUpdated,
  orderLineUpdated,
  fetchOrdersRequest,
  fetchOrdersSuccess,
  fetchOrdersError
} = ordersSlice.actions;

type ConsumerOrProducer =
  | { consumerId: string; producerId?: never }
  | { producerId: string; consumerId?: never };

export const fetchOrders = (
  params: ConsumerOrProducer,
  { clearStore = true } = {}
) => {
  return (dispatch: AppDispatch) => {
    if (clearStore) {
      dispatch(fetchOrdersRequest());
    }

    getOrders(params)
      .then(orders => {
        dispatch(fetchOrdersSuccess(orders));
      })
      .catch(() => {
        dispatch(fetchOrdersError());
      });
  };
};

const updateOrderStatus = (orderId: string, statuses: OrderStatuses) => {
  return (dispatch: AppDispatch) => {
    dispatch(
      orderStatusUpdated({
        orderId,
        statuses
      })
    );
  };
};

export const postAndUpdateOrderStatuses = (
  orderIds: string[],
  status: OrderStatuses
) => {
  return async (dispatch: AppDispatch) => {
    const result = await postOrderStatuses({ orderIds, status });
    return result.map(({ _id, statuses }) => {
      return dispatch(updateOrderStatus(_id, statuses));
    });
  };
};

export const updateOrderLine = ({
  orderId,
  lineKey,
  nrOfOrderedUnitsDelivered,
  nrOfPricedUnitsDelivered,
  nokPerPricedUnit
}) => {
  return (dispatch: AppDispatch) => {
    dispatch(
      orderLineUpdated({
        orderId,
        lineKey,
        nrOfOrderedUnitsDelivered,
        nrOfPricedUnitsDelivered,
        nokPerPricedUnit
      })
    );
  };
};

/** Selectors for order lines */

const hasOrderedUnitsDeliveredBeenUpdated = (line: OrderLine) => {
  return (
    line.nrOfOrderedUnitsDelivered !== line.nrOfUnits &&
    definedNumber(line.nrOfOrderedUnitsDelivered)
  );
};

export const hasPricedUnitsDeliveredBeenUpdated = (line: OrderLine) => {
  return line.pricingAtTimeOfOrder.orderedUnit ===
    line.pricingAtTimeOfOrder.pricedUnit
    ? definedNumber(line.nrOfPricedUnitsDelivered) &&
        line.nrOfPricedUnitsDelivered !== line.nrOfUnits
    : definedNumber(line.nrOfPricedUnitsDelivered);
};

export const hasOrderLineBeenUpdated = (line: OrderLine) => {
  return (
    hasOrderedUnitsDeliveredBeenUpdated(line) ||
    hasPricedUnitsDeliveredBeenUpdated(line)
  );
};

export const getDeliveredUnitsWithUnitText = (line: OrderLine) => {
  const { unit, count } = getCountAndPriceAndUnit(line);

  return formatTextWithUnits(unit, count);
};

export const getOrderedUnitsToBeDelivered = (line: OrderLine) => {
  return hasOrderedUnitsDeliveredBeenUpdated(line)
    ? line.nrOfOrderedUnitsDelivered
    : line.nrOfUnits;
};

export const getPricedUnitsToBeDelivered = (line: OrderLine) => {
  return hasPricedUnitsDeliveredBeenUpdated(line)
    ? line.nrOfPricedUnitsDelivered
    : line.pricingAtTimeOfOrder.pricedUnitsPerOrderedUnit *
        getOrderedUnitsToBeDelivered(line);
};

const getOriginalOrderLinePrice = ({
  nrOfUnits = 0,
  pricingAtTimeOfOrder
}: OrderLine) => {
  return nrOfUnits * calculateOrderedUnitPrice(pricingAtTimeOfOrder);
};

const getOrderLinePrice = (line: OrderLine) => {
  return hasPricedUnitsDeliveredBeenUpdated(line)
    ? getPricedUnitsToBeDelivered(line) *
        line.pricingAtTimeOfOrder.nokPerPricedUnit
    : getOrderedUnitsToBeDelivered(line) *
        calculateOrderedUnitPrice(line.pricingAtTimeOfOrder);
};

export const hasOrderLinesBeenUpdated = (lines: OrderLine[] = []) => {
  return lines.find(hasOrderLineBeenUpdated);
};

export const getOriginalTotalOrderPrice = (lines: OrderLine[] = []) => {
  return lines.map(getOriginalOrderLinePrice).reduce((acc, price) => {
    return acc + price;
  }, 0);
};

export const getTotalOrderPrice = (lines: OrderLine[]) => {
  return lines.map(getOrderLinePrice).reduce((acc, price) => {
    return acc + price;
  }, 0);
};

/** Selectors for orders */

export const getTotalOrdersPrice = (orders: Order[] = []) => {
  return orders.reduce((acc, { orderLines }) => {
    return acc + getTotalOrderPrice(orderLines);
  }, 0);
};

export const isOpened = ({
  statuses = {} as OrderStatuses
}: {
  statuses: OrderStatuses;
}) => {
  return statuses.openedByProducer;
};

const isCancelled = ({
  statuses = {} as OrderStatuses
}: {
  statuses: OrderStatuses;
}) => {
  return statuses.cancelled;
};

const isActive = ({
  statuses = {} as OrderStatuses
}: {
  statuses: OrderStatuses;
}) => {
  return !statuses.deliveredToConsumer && !statuses.cancelled;
};

const isUnopenedAndActive = (order: Order) => {
  return !isOpened(order) && isActive(order);
};

const isOrderInvoiceSentToDagens = (
  {
    statuses: { sentToInvoicing, invoiceSentToDagens } = {} as OrderStatuses
  }: { statuses: OrderStatuses } = { statuses: {} as OrderStatuses }
) => {
  return sentToInvoicing || invoiceSentToDagens;
};

export const isOrderReadOnly = (order: Order = {} as Order) => {
  return isOrderInvoiceSentToDagens(order) || isCancelled(order);
};

export const countActiveUnopenedOrders = (orders: Order[]) => {
  return orders.filter(isUnopenedAndActive).length;
};

export const calculateActiveUnopenedValue = (orders: Order[]) => {
  return orders.filter(isUnopenedAndActive).reduce((acc, { orderLines }) => {
    return acc + getOriginalTotalOrderPrice(orderLines);
  }, 0);
};

export const getOrderBySecret = (orders: Order[], secret: string) => {
  return orders.find(o => {
    return o.secret === secret;
  });
};

export const splitActiveAndPastOrders = (orders: Order[]) => {
  const activeOrders = orders.filter(isActive);
  const pastOrders = orders
    .filter(o => {
      return !isActive(o);
    })
    .sort(byDescending('deliveryDate'));

  return { activeOrders, pastOrders };
};

const isDeliveredAndNotInvoiced = (order: Order = {} as Order) => {
  const { statuses: { deliveredToConsumer, cancelled } = {} } = order;
  return (
    deliveredToConsumer && !cancelled && !isOrderInvoiceSentToDagens(order)
  );
};

export const splitInvoicedAndNotInvoicedOrders = (orders: Order[]) => {
  const notInvoicedOrders = orders.filter(isDeliveredAndNotInvoiced);

  const invoicedOrders = orders
    .filter(o => {
      return isOrderInvoiceSentToDagens(o) || isCancelled(o);
    })
    .sort(byDescending('deliveryDate'));

  return { notInvoicedOrders, invoicedOrders };
};

export const groupOrdersByDeliveryDate = (
  orders: Order[] = []
): {
  key: string;
  deliveryDate: Order['deliveryDate'];
  orders: Order[];
}[] => {
  const groups = orders
    .map(
      addKey(({ deliveryDate }) => {
        return deliveryDate;
      })
    )
    .reduce(...grouper(['deliveryDate'], 'orders'));

  return Object.values(groups);
};

export const groupOrdersByPickupType = (orders: Order[] = []) => {
  const groups = orders
    .map(order => {
      return { ...order, ...getPickupType(order) };
    })
    .reduce(
      ...grouper(
        [
          'deliveryDate',
          'type',
          'description',
          'producerDeliveryDate',
          'pickupPoint',
          'partnerName',
          'toName'
        ],
        'orders'
      )
    );

  return Object.values(groups);
};

export const getOrderGroup = (orders: Order[], pickupKey: string) => {
  return groupOrdersByPickupType(orders).find(({ key }) => {
    return key === pickupKey;
  });
};

export const getOrdersForNthWeek = (orders: Order[] = [], n = 0) => {
  return orders.filter(({ deliveryDate }) => {
    return isWeek(deliveryDate, n);
  });
};

export const ordersBySettlement = (orders: Order[] = []) => {
  const groups = orders
    .filter(order => {
      return !!order.settlement;
    })
    .map(
      addKey(({ settlement: { settlementNumber } }) => {
        return settlementNumber;
      })
    )
    .reduce(...grouper(['settlement'], 'orders'));

  return Object.values(groups)
    .sort(
      byFunction(({ settlement: { _createdAt = '' } = {} }) => {
        return _createdAt;
      })
    )
    .reverse();
};
