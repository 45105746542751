import React from 'react';
import styled from 'styled-components';
import { formatPhoneNumber } from '@dagensmat/core';
import { useTranslation } from 'react-i18next';
import ResponsiveImage from '_common/components/utils/ResponsiveImage.style';
import { getImageUrl } from 'utils/imageUrls';
import { P } from 'utils/fonts';
import { byKey } from 'utils/array';
import ProducerProducts from '_consumer/components/producer/ProducerProducts';
import BulletPointsCallout from '_common/components/callout/BulletPointsCallout';
import MultiProductSeasonCalendar from '_common/components/season/MultiProductSeasonCalendar';
import theme from 'theme';
import { PRODUCER_SEASON_CAL_CLICKED, track } from 'utils/mixpanel';
import Typography from '_common/components/utils/Typography';
import { Producer } from 'types/Producer';
import { ProductForSale } from 'types/Product';

const Container = styled.div`
  max-width: 65ch;
`;

const TextsWithHeaderContainer = styled.div`
  margin: 30px 0;
`;

const InstagramLink = ({ instagram }) => {
  return (
    <a
      href={`https://instagram.com/${instagram}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {`@${instagram.replace('@', '')}`}
    </a>
  );
};

type ProducerViewProfile = Pick<
  Producer,
  | 'name'
  | 'phone'
  | 'profileArea'
  | 'contactPerson'
  | 'instagram'
  | 'image'
  | 'profileBio'
  | 'keyFacts'
>;

interface ProducerViewProps {
  producer: ProducerViewProfile;
  products: ProductForSale[];
  roleType: 'consumers';
}

const ProducerView = ({
  producer: {
    name,
    phone,
    profileArea,
    contactPerson,
    instagram,
    image,
    profileBio,
    keyFacts
  } = {} as ProducerViewProfile,
  products = [],
  roleType
}: ProducerViewProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        {profileArea && <P>{profileArea}</P>}
        <Typography variant="primaryHeading" mt={0} mb={5}>
          {name}
        </Typography>
        <P>
          {contactPerson}{' '}
          {roleType === 'consumers' && (
            <a href={`tel:${phone}`}>{`(${formatPhoneNumber(phone)})`}</a>
          )}
        </P>
        {instagram && (
          <div style={{ marginBottom: 20 }}>
            <InstagramLink instagram={instagram} />
          </div>
        )}
        {image && (
          <ResponsiveImage
            src={getImageUrl(image, { width: 980, fit: 'max' })}
          />
        )}
        {profileBio && (
          <TextsWithHeaderContainer>
            <P color={theme.palette.secondary}>
              {t('common:AboutWord', { word: name })}
            </P>
            <P style={{ whiteSpace: 'pre-line' }}>{profileBio}</P>
          </TextsWithHeaderContainer>
        )}
        <BulletPointsCallout points={keyFacts} mb={30} />
      </Container>
      <MultiProductSeasonCalendar
        heading={t('consumer:ProduceSeason')}
        products={products.sort(byKey('name'))}
        onClick={() => {
          return track(PRODUCER_SEASON_CAL_CLICKED);
        }}
      />
      <ProducerProducts products={products} />
    </>
  );
};

export default ProducerView;
