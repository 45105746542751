import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { CountryCode } from '@dagensmat/core';
import ProductFormSaveButton from '_common/components/button/ProductFormSaveButton';
import EditPricingFlow from '_producer/components/edit-pricing-flow/EditPricingFlow';
import TextAreaWithLabel from '_common/components/text-area/TextAreaWithLabel';
import CategoryPicker from '_producer/components/category-picker/CategoryPicker';
import EditProductSaleStatus from '_producer/components/edit-product-sale-status/EditProductSaleStatus';
import { byKey } from 'utils/array';
import { makeEditable } from 'utils/pricing';
import usePromiseAll from '_common/hooks/usePromiseAll';
import { getCategories, getCertifications } from 'api';
import Typography from '_common/components/utils/Typography';
import FormField from '_common/components/input/FormField';
import ConfirmButton from '_common/components/confirm-button/ConfirmButton';
import theme from 'theme';
import MarginContainer2 from '_common/components/utils/MarginContainer2.style';
import { EditableProduct } from 'types/Product';
import TemperatureZoneSelector from './TemperatureZoneSelector';
import ProductCapacityInput from './ProductCapacityInput';
import ProductSeasonInput from './ProductSeasonInput';
import ProductImageInput from './ProductImageInput';
import ProductCertifications from './ProductCertifications';
import ProcessedStateSelector from './ProcessedStateSelector';
import PricingLinks from './PricingLinks';

export const FormSection = styled.div`
  margin-bottom: ${theme.spacing.jumbo}px;

  &:before {
    visibility: hidden;
    display: block;
    height: 70px;
    margin: -70px 0 0;
    content: '';
  }
`;

export const FormSectionIds = {
  AVAILABILITY: 'availability',
  DETAILS: 'details',
  PRICING: 'pricing'
};

export const FormWrapper = styled.div`
  max-width: 490px;
  width: 100%;
  position: relative;
`;

type ProductFormProps = {
  fieldChanged: (key: string) => (value: any) => void;
  onSave: () => Promise<void>;
  label: string;
  onDelete?: () => void;
  deliveryDates?: string[];
  isValid: boolean;
  product: Partial<EditableProduct>;
  countryCode: CountryCode;
};

const ProductForm = ({
  fieldChanged,
  onSave,
  label,
  onDelete,
  deliveryDates,
  isValid,
  product: {
    readyToEdit,
    _id,
    name = '',
    type = '',
    categoryId,
    description = '',
    image,
    certificationIds,
    prices,
    forSaleStatus,
    seasonCalendar,
    temperatureZone,
    capacities = [],
    processedState
  },
  countryCode
}: ProductFormProps) => {
  const { t } = useTranslation();

  const availabilityRef = React.useRef(null);
  const detailsRef = React.useRef(null);
  const pricingRef = React.useRef(null);

  const { hash, key } = useLocation();
  const sectionId = hash.replace('#', '');

  const [categories, certifications, isReady] = usePromiseAll([
    getCategories,
    async () => {
      return getCertifications({ countryCode });
    }
  ]);

  React.useLayoutEffect(() => {
    if (sectionId && isReady) {
      const el = {
        [FormSectionIds.AVAILABILITY]: availabilityRef,
        [FormSectionIds.DETAILS]: detailsRef,
        [FormSectionIds.PRICING]: pricingRef
      }[sectionId];

      if (el && el.current) {
        const top = el.current.offsetTop;
        window.scrollTo({ top, behavior: 'smooth' });
      }
    }
  }, [sectionId, isReady, key]);

  if (!(readyToEdit && isReady)) return null;

  return (
    <FormWrapper>
      <FormSection>
        <FormField
          name="productName"
          type="text"
          labelText={t('producer:productNameLabelText')}
          helpText={t('producer:productNameHelperText')}
          value={name}
          placeholder={t('producer:productNamePlaceholder')}
          onChange={({ target: { value } }) => {
            return fieldChanged('name')(value);
          }}
          margin={{ mb: theme.spacing.xl }}
        />
        <MarginContainer2 mb={theme.spacing.xl}>
          <ProductImageInput
            onChange={fieldChanged('image')}
            productImage={image}
          />
        </MarginContainer2>
        <MarginContainer2 mb={theme.spacing.xl}>
          <CategoryPicker
            categories={categories}
            categoryId={categoryId}
            onChange={fieldChanged('categoryId')}
          />
        </MarginContainer2>
      </FormSection>
      <FormSection ref={pricingRef}>
        <Typography variant="secondaryHeading" mt={0} mb={theme.spacing.xl}>
          {t('producer:productPriceFormSection')}
        </Typography>
        {onDelete ? (
          <PricingLinks productId={_id} prices={prices} />
        ) : (
          prices !== undefined && (
            <EditPricingFlow
              editablePricing={makeEditable(prices[0])}
              onPricingChange={pricing => {
                fieldChanged('prices')([pricing]);
              }}
            />
          )
        )}
      </FormSection>
      <FormSection ref={detailsRef}>
        <Typography variant="secondaryHeading" mt={0} mb={theme.spacing.xl}>
          {t('producer:ProductInformation')}
        </Typography>
        <FormField
          name="productType"
          type="text"
          labelText={t('producer:productTypeLabelText', {
            productName: name || t('common:product')
          })}
          helpText={t('producer:productTypeHelperText')}
          value={type}
          placeholder={t('producer:productTypePlaceholder')}
          onChange={({ target: { value } }) => {
            return fieldChanged('type')(value);
          }}
          maxLength={30}
          margin={{ mb: theme.spacing.xl }}
          optional
        />
        <MarginContainer2 mb={theme.spacing.xl}>
          <ProductCertifications
            onChange={fieldChanged('certificationIds')}
            certificationIds={certificationIds}
            certifications={certifications}
          />
        </MarginContainer2>
        <MarginContainer2 mb={theme.spacing.xl}>
          <ProcessedStateSelector
            onChange={fieldChanged('processedState')}
            value={processedState}
          />
        </MarginContainer2>
        <MarginContainer2 mb={theme.spacing.xl}>
          <TemperatureZoneSelector
            onChange={fieldChanged('temperatureZone')}
            value={temperatureZone}
          />
        </MarginContainer2>
        <TextAreaWithLabel
          mb={theme.spacing.xl}
          label={t('producer:productDescriptionLabelText')}
          value={description}
          placeholder={t('producer:productDescriptionPlaceholder')}
          optional
          onChange={({ target: { value } }) => {
            return fieldChanged('description')(value);
          }}
        />
      </FormSection>
      <FormSection ref={availabilityRef}>
        <Typography variant="secondaryHeading" mt={0} mb={theme.spacing.xl}>
          {t('producer:productFormSection')}
        </Typography>
        <MarginContainer2 mb={theme.spacing.xl}>
          <EditProductSaleStatus
            forSaleStatus={forSaleStatus}
            onChange={fieldChanged('forSaleStatus')}
          />
        </MarginContainer2>
        <MarginContainer2 mb={theme.spacing.xl}>
          <ProductSeasonInput
            onChange={fieldChanged('seasonCalendar')}
            seasonCalendar={seasonCalendar}
          />
        </MarginContainer2>
        {onDelete && (
          <MarginContainer2 mb={theme.spacing.xl}>
            <ProductCapacityInput
              deliveryDates={deliveryDates}
              capacities={[...capacities]
                .sort(byKey('deliveryDate'))
                .filter(({ units }) => {
                  return units === 0;
                })}
              onChange={fieldChanged('capacities')}
            />
          </MarginContainer2>
        )}
      </FormSection>
      {onDelete && (
        <FormSection>
          <ConfirmButton
            confirmText={t('producer:productDeleteParagraph')}
            confirmedText={t('producer:productDeleteButtonConfirmedText')}
            buttonText={t('producer:productDeleteButtonText')}
            rejectButtonText={t('common:NoWait')}
            confirmButtonText={t('producer:productConfirmDeleteButtonText')}
            confirmButtonVariant="error"
            onConfirm={onDelete}
          />
        </FormSection>
      )}
      {label && (
        <ProductFormSaveButton
          onSave={onSave}
          disabled={!isValid}
          label={label}
        />
      )}
    </FormWrapper>
  );
};
export default ProductForm;
