import React from 'react';
import ProductCard from '_common/components/product-card/ProductCard';
import { GridContainer } from '_common/pages/search/grid/Grid';

const ProducerProducts = ({ products = [] }) => {
  return (
    <GridContainer>
      {products.map(product => {
        return (
          <ProductCard
            key={product._id}
            product={product}
            showProducerName={false}
          />
        );
      })}
    </GridContainer>
  );
};

export default ProducerProducts;
