/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsForRegex": ["state"] }] */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppDispatch } from 'store';

export const VIEW_PRODUCT = 'VIEW_PRODUCT';
export const PRINT_PARCEL_LABELS = 'PRINT_PARCEL_LABELS';
export const DELIVERY_INFORMATION_CHECKOUT_FORM =
  'DELIVERY_INFORMATION_CHECKOUT_FORM';

const initialState: ModalState = {
  modalType: null,
  modalProps: {}
};

interface ModalState {
  modalType:
    | 'VIEW_PRODUCT'
    | 'PRINT_PARCEL_LABELS'
    | 'DELIVERY_INFORMATION_CHECKOUT_FORM';
  modalProps: { [key: string]: any };
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal(state, action: PayloadAction<ModalState>) {
      state.modalType = action.payload.modalType;
      state.modalProps = action.payload.modalProps;
    },
    hideModal() {
      return initialState;
    }
  }
});
export const { showModal, hideModal } = modalSlice.actions;

export const showProductModal = (modalProps: ModalState['modalProps']) => {
  return (dispatch: AppDispatch) => {
    dispatch(showModal({ modalType: 'VIEW_PRODUCT', modalProps }));
  };
};

export const showParcelLabelModal = (modalProps: ModalState['modalProps']) => {
  return (dispatch: AppDispatch) => {
    dispatch(showModal({ modalType: PRINT_PARCEL_LABELS, modalProps }));
  };
};

export const showDeliveryInformationFormModal = (
  modalProps: ModalState['modalProps']
) => {
  return (dispatch: AppDispatch) => {
    dispatch(
      showModal({
        modalType: DELIVERY_INFORMATION_CHECKOUT_FORM,
        modalProps: { isNotDismissable: true, ...modalProps }
      })
    );
  };
};

export default modalSlice.reducer;
