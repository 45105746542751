import React from 'react';
import { useTranslation } from 'react-i18next';
import REQ from 'utils/REQ';
import { getImageUrl } from 'utils/imageUrls';
import theme from 'theme';
import FormLabel from '_common/components/input/FormLabel';
import MarginContainer2 from '_common/components/utils/MarginContainer2.style';
import ImagePreviewSimple from './ImagePreviewSimple';
import ImageInput from './ImageInput';

const ImageUploaderSimple = props => {
  const [uploadReq, setUploadReq] = React.useState(REQ.INIT);
  const [previewUrl, setPreviewUrl] = React.useState('');

  const saveImage = file => {
    const { onSave } = props;

    setUploadReq(REQ.PENDING);

    onSave(file)
      .then(() => {
        setUploadReq(REQ.SUCCESS);
      })
      .catch(() => {
        setUploadReq(REQ.ERROR);
      });
  };

  const imageChanged = (file, imagePreviewUrl) => {
    setUploadReq(REQ.INIT);
    setPreviewUrl(imagePreviewUrl);
    saveImage(file);
  };

  const { image, label } = props;
  const hasImage = image && image.asset;
  const imageUrl = previewUrl || getImageUrl(image);

  const { t } = useTranslation();
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginBottom: theme.spacing.xxs
        }}
      >
        <FormLabel
          helpText={t('producer:ImageHelperText')}
          labelText={label}
          explanationText={t('producer:productImageText')}
        />
      </div>
      <MarginContainer2 mb={theme.spacing.xs}>
        <ImagePreviewSimple imageUrl={imageUrl} />
      </MarginContainer2>
      <ImageInput
        disabled={uploadReq === REQ.PENDING}
        onChange={imageChanged}
        fillButton={!hasImage}
        buttonText={
          hasImage
            ? t('common:changeProductImage')
            : t('common:uploadProductImage')
        }
        margin={0}
      />
    </>
  );
};

export default ImageUploaderSimple;
