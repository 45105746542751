import React from 'react';
import styled from 'styled-components';
import theme, { ColorValue } from 'theme';
import Typography from './Typography';

type TagStyleProps = {
  bgColor?: ColorValue;
};

const TagStyle = styled.span<TagStyleProps>`
  padding: ${theme.spacing.xxs}px;
  border-radius: ${theme.borderRadius.small}px;
  color: ${theme.palette.black};
  background-color: ${({ bgColor = theme.palette.noteworthy }) => {
    return bgColor;
  }};
  display: inline-block;
`;

type TagProps = TagStyleProps & {
  text: string;
};

const Tag = ({ bgColor, text }: TagProps) => {
  return (
    <TagStyle bgColor={bgColor}>
      <Typography variant="allCapsTitle" my={0} mb={0}>
        {text}
      </Typography>
    </TagStyle>
  );
};

export default Tag;
