import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { forSaleStatuses } from '@dagensmat/core';
import { P, FontSizes } from 'utils/fonts';
import { getYearProgressPercentage } from 'utils/date/format';
import Legends from '_common/components/legends/Legends';
import theme from 'theme';
import Flex from '_common/components/utils/Flex.style';
import Typography from '_common/components/utils/Typography';
import SaleStatusFilters from './SaleStatusFilters';
import SeasonRow from './SeasonRow';
import CalendarMonths from './CalendarMonths';

const CalendarContainer = styled.div`
  margin: 40px 0;
`;

const Calendar = styled.div`
  position: relative;
  width: 100%;
  margin: 20px 0;
`;

const RowsContainer = styled.div`
  padding-top: 20px;
`;

const DateIndicator = styled.div`
  position: absolute;
  top: 0;
  left: ${({ left }) => {
    return left;
  }}%;

  width: 2px;
  background: ${theme.palette.black};
  height: 100%;
`;

const DateIndicatorTag = styled.div`
  text-transform: uppercase;
  position: absolute;
  top: -15px;
  left: calc(
    ${({ left }) => {
        return left;
      }}% - 18px
  );
  padding: 0 6px;

  background: ${theme.palette.black};
  color: ${theme.palette.white};
  border-radius: ${theme.borderRadius.small}px;

  font-size: ${FontSizes.small}px;
  line-height: 18px;
`;

const ExpandButton = styled(P)`
  margin-left: 10px;
  color: ${theme.palette.secondary};
  cursor: pointer;
`;

const SHORT_LIST_CAP = 7;
const MultiProductSeasonCalendar = ({
  products = [],
  isProducer = false,
  heading,
  countProductsToShow = SHORT_LIST_CAP,
  onClick
}) => {
  const [showAll, toggleShowAll] = React.useState(false);
  const [saleStatusFilter, setSaleStatusFilter] = React.useState([
    forSaleStatuses.FOR_SALE,
    forSaleStatuses.ON_REQUEST
  ]);
  const left = getYearProgressPercentage();
  const filteredProducts = products.filter(({ forSaleStatus }) => {
    return saleStatusFilter.includes(forSaleStatus);
  });
  const productsToShow = showAll
    ? filteredProducts
    : filteredProducts.slice(0, countProductsToShow);
  const { t } = useTranslation();
  const saleStatusLegend = [
    {
      name: t('common:FOR_SALE'),
      color: theme.palette.available,
      show: products.some(({ forSaleStatus }) => {
        return forSaleStatus === forSaleStatuses.FOR_SALE;
      })
    },
    {
      name: t('common:ON_REQUEST'),
      color: theme.palette.availability,
      show: products.some(({ forSaleStatus }) => {
        return forSaleStatus === forSaleStatuses.ON_REQUEST;
      })
    },
    {
      name: t('common:NOT_FOR_SALE'),
      color: theme.palette.lightGrey,
      show: products.some(({ forSaleStatus }) => {
        return forSaleStatus === forSaleStatuses.NOT_FOR_SALE;
      })
    }
  ];
  return (
    <CalendarContainer>
      <Flex>
        <Typography variant="tertiaryHeading">
          {heading || t('common:overview')}
        </Typography>
        <Legends
          legends={saleStatusLegend.filter(({ show }) => {
            return show;
          })}
        />
      </Flex>
      {isProducer && (
        <SaleStatusFilters
          activeFilters={saleStatusFilter}
          setActiveFilters={setSaleStatusFilter}
        />
      )}
      <Calendar>
        <CalendarMonths multi />
        <RowsContainer>
          {productsToShow.map(product => {
            return (
              <SeasonRow
                key={product._id}
                product={product}
                isProducer={isProducer}
                onClick={onClick}
              />
            );
          })}
        </RowsContainer>
        <DateIndicator left={left} />
        <DateIndicatorTag left={left}>{t('common:today')}</DateIndicatorTag>
      </Calendar>
      {products.length > countProductsToShow && (
        <ExpandButton
          role="button"
          onClick={() => {
            return toggleShowAll(!showAll);
          }}
        >
          {showAll ? t('common:showLessDot') : t('common:showAllDot')}
        </ExpandButton>
      )}
    </CalendarContainer>
  );
};

export default MultiProductSeasonCalendar;
