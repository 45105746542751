import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ProductBuyer from '_common/components/product-buyer/ProductBuyer';
import Button from '_common/components/button/Button.style';
import {
  DeliveryDateContext,
  DELIVERY_DATE_CONTEXT_DEFAULT
} from '_common/context/DeliveryDateContext';
import { isProductBuyable } from '../modal/ModalBuyerWrapper';

const CardBuyerWrapper = ({
  product,
  selectedDeliveryDate,
  availableDeliveryDates
}) => {
  const { t } = useTranslation();
  const { pageContext } = React.useContext(DeliveryDateContext);

  const { deliveryDates } = product;

  if (!isProductBuyable(availableDeliveryDates, deliveryDates)) return null;

  if (!selectedDeliveryDate || pageContext !== DELIVERY_DATE_CONTEXT_DEFAULT) {
    return <Button my={10}>{t('consumer:ChooseDeliveryDay')}</Button>;
  }

  return (
    <ProductBuyer
      product={product}
      selectedDeliveryDate={selectedDeliveryDate}
    />
  );
};

const mapStateToProps = ({
  productsForSale: { selectedDeliveryDate, availableDeliveryDates }
}) => {
  return {
    selectedDeliveryDate,
    availableDeliveryDates
  };
};

export default connect(mapStateToProps)(CardBuyerWrapper);
