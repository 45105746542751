import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showProductModal } from '_common/reducers/modal';
import {
  isAlwaysInSeason,
  isValidThisYear,
  calculatePeriodPosition
} from 'utils/season';
import { Label } from 'utils/fonts';
import { formatProductNameAndType } from 'utils/texts';
import theme from 'theme';
import { FormSectionIds } from '_producer/components/product-form/ProductForm';
import { DeliveryDateContext } from '_common/context/DeliveryDateContext';

const RowContainer = styled.div`
  position: relative;
  height: 40px;
  width: 100%;
  margin: 20px 0;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
`;

const InSeasonBlock = styled.div`
  position: absolute;
  top: 0;
  left: ${({ left }) => {
    return left;
  }};
  width: ${({ width }) => {
    return width;
  }};
  height: inherit;
  background-color: ${({ color = theme.palette.season }) => {
    return color;
  }};
  border-radius: 5px;
`;

const ProductLabel = styled(Label)`
  position: absolute;
  left: 10px;
  line-height: 40px;
  cursor: inherit;
  color: ${({ color = theme.palette.black }) => {
    return color;
  }};
`;

const SeasonRowContent = ({
  product: { name, type, seasonCalendar = [], forSaleStatus }
}) => {
  const color =
    {
      FOR_SALE: theme.palette.available,
      ON_REQUEST: theme.palette.availability,
      NOT_FOR_SALE: theme.palette.lightGrey
    }[forSaleStatus] || null;
  return (
    <>
      {isAlwaysInSeason(seasonCalendar) ? (
        <InSeasonBlock left={0} width="100%" color={color} />
      ) : (
        seasonCalendar.filter(isValidThisYear).map(period => {
          const { left, width } = calculatePeriodPosition(period);
          return (
            <InSeasonBlock
              key={period._key}
              left={left}
              width={width}
              color={color}
            />
          );
        })
      )}
      <ProductLabel
        color={
          forSaleStatus === 'NOT_FOR_SALE' ? theme.palette.secondary : null
        }
      >
        {formatProductNameAndType({ name, type })}
      </ProductLabel>
    </>
  );
};

const SeasonRowInModal = connect()(({ product, dispatch, onClick }) => {
  const { pageContext } = React.useContext(DeliveryDateContext);
  return (
    <RowContainer
      onClick={() => {
        dispatch(
          showProductModal({
            productId: product._id,
            deliveryDateContext: pageContext
          })
        );
        if (onClick) {
          onClick();
        }
      }}
    >
      <SeasonRowContent product={product} />
    </RowContainer>
  );
});

const SeasonRowForProducer = ({ product, onClick }) => {
  return (
    <Link
      to={{
        pathname: `/my-products/${product._id}`,
        state: { returnPath: true },
        hash: FormSectionIds.AVAILABILITY
      }}
      onClick={() => {
        return onClick && onClick();
      }}
    >
      <RowContainer>
        <SeasonRowContent product={product} />
      </RowContainer>
    </Link>
  );
};

const SeasonRow = ({ product, isProducer = false, onClick }) => {
  return isProducer ? (
    <SeasonRowForProducer product={product} onClick={onClick} />
  ) : (
    <SeasonRowInModal product={product} onClick={onClick} />
  );
};

export default SeasonRow;
