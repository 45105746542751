import React from 'react';
import theme from 'theme';
import { P } from 'utils/fonts';
import { MarginProps } from 'utils/style';
import { Callout } from './Callout';

type BulletPointsCalloutProps = {
  points: string[];
} & Pick<MarginProps, 'mb' | 'mt'>;

const BulletPointsCallout = ({
  points = [],
  mb,
  mt
}: BulletPointsCalloutProps) => {
  if (points.length < 1) return null;
  return (
    <Callout color={theme.palette.noteworthy} mb={mb} mt={mt}>
      {points.map(point => {
        return <P key={point}>&#10003;&nbsp;{point}</P>;
      })}
    </Callout>
  );
};

export default BulletPointsCallout;
