/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CheckoutState {
  messages?: { [key: string]: string };
}

const checkoutInitialState: CheckoutState = {
  messages: {}
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: checkoutInitialState,
  reducers: {
    updateMessage(
      state,
      action: PayloadAction<{ messageKey: string; value: string }>
    ) {
      const { messageKey, value } = action.payload;
      state.messages[messageKey] = value;
    },
    clearCheckout() {
      return checkoutInitialState;
    }
  }
});

export const { updateMessage, clearCheckout } = checkoutSlice.actions;
export default checkoutSlice.reducer;
