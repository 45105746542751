import React from 'react';
import { useTranslation } from 'react-i18next';
import { P } from 'utils/fonts';
import theme from 'theme';
import { getUpcomingSeason } from 'utils/season';
import ProductTitle from './ProductTitle';
import PricingText from './PricingText';

const ProductInfo = ({ product }) => {
  const {
    name,
    type = '',
    producer,
    pricing,
    temperatureZone,
    certifications,
    seasonCalendar
  } = product;
  const { t } = useTranslation();

  const upcomingSeason = getUpcomingSeason(seasonCalendar);
  const { month, diff } = upcomingSeason;

  return (
    <>
      <P>{producer.name}</P>
      <ProductTitle name={name} type={type} certifications={certifications} />
      <PricingText pricing={pricing} temperatureZone={temperatureZone} />
      {!!upcomingSeason && (
        <P color={theme.palette.season}>
          {t('common:upcomingSeason_interval', {
            month,
            count: diff,
            postProcess: 'interval'
          })}
        </P>
      )}
    </>
  );
};

export default ProductInfo;
