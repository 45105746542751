import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import { MarginProps } from 'utils/style';
import useToggle from '_common/hooks/useToggle';
import { Callout } from '../callout/Callout';
import ToggleHelpCalloutButton from '../callout/ToggleHelpCalloutButton';
import Flex from '../utils/Flex.style';
import Typography from '../utils/Typography';

export const Optional = styled.span`
  font-size: ${theme.typography.fontSize.paragraphSmall}px;
  line-height: ${theme.spacing.m}px;
  font-weight: ${theme.typography.fontWeight.medium};
  letter-spacing: 0.1px;
  margin: 0;
  color: ${theme.palette.secondary};
`;

interface FormLabelProps extends MarginProps {
  labelText: string;
  helpText?: string;
  id?: string;
  maxLength?: number;
  optional?: boolean;
  explanationText?: React.ReactNode;
  value?: string | number;
  onToggleExplanation?: () => void;
}

const FormLabel = ({
  labelText,
  helpText,
  id = labelText,
  maxLength,
  optional,
  explanationText,
  value,
  onToggleExplanation
}: FormLabelProps) => {
  const { t } = useTranslation();
  const [showExplanation, toggleExplanation] = useToggle(
    false,
    onToggleExplanation && onToggleExplanation
  );

  return (
    <Flex justify="center" direction="column" mb={theme.spacing.xxs}>
      <Flex alignItems="center">
        <Typography variant="inputLabel" id={id}>
          {labelText}
          {optional && (
            <>
              {' '}
              <Optional>{t('common:optional')}</Optional>
            </>
          )}
        </Typography>
        {explanationText && (
          <ToggleHelpCalloutButton
            onToggle={toggleExplanation}
            isToggled={showExplanation}
          />
        )}
      </Flex>
      {showExplanation && (
        <Callout
          color={theme.palette.lightGrey}
          mb={theme.spacing.xxs}
          mt={theme.spacing.xxs}
        >
          {explanationText}
        </Callout>
      )}
      <Flex width="100%">
        {helpText && (
          <Typography variant="paragraphSmall" color={theme.palette.secondary}>
            {helpText}
          </Typography>
        )}
        {maxLength && (
          <div style={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}>
            <Typography
              variant="paragraphSmall"
              color={theme.palette.secondary}
            >
              {value.toString().length || 0} / {maxLength}
            </Typography>
          </div>
        )}
      </Flex>
    </Flex>
  );
};

export default FormLabel;
