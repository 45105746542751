import React from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { P } from 'utils/fonts';
import DagensPhoneLink from '_common/components/text-link/DagensPhoneLink';
import theme from 'theme';
import DagensEmailLink from '../text-link/DagensEmailLink';

const ErrorMessageContainer = styled.div`
  max-width: 260px;
  margin: 60px auto;
  padding: 0 10px;
  text-align: center;
`;

const ErrorMessage = () => {
  const { t } = useTranslation();
  return (
    <ErrorMessageContainer>
      <P my={20}>{t('common:errorOccured')}</P>
      <P color={theme.palette.secondary} my={20}>
        {t('common:errorFeedback')}
      </P>
      <P color={theme.palette.secondary} my={20}>
        <Trans
          t={t}
          i18nKey="common:errorFeedback2"
          components={{
            DagensPhoneLink: <DagensPhoneLink />,
            DagensEmailLink: <DagensEmailLink />
          }}
        />
      </P>
    </ErrorMessageContainer>
  );
};

export default ErrorMessage;
