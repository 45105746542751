import styled from 'styled-components';
import { definedNumber } from '@dagensmat/core';
import { TRANSITION_DURATION } from '_common/components/button/Button.style';
import margins from 'utils/style';

export const InputContainer = styled.div<{ margin?: number }>`
  margin: ${({ margin = 40 }) => {
    return `${margin}px 0`;
  }};
`;

const borderColor = ({ success, warning, error, theme }) => {
  if (error) return theme.palette.error;
  if (warning) return theme.palette.warning;
  if (success) return theme.palette.black;
  return theme.palette.grey;
};

const Input = styled.input<{
  maxWidth?: number;
  block?: boolean;
  textAlign?: string;
}>`
  padding: ${({ theme }) => {
    return theme.spacing.xxs + theme.spacing.xs;
  }}px;
  margin: 0;
  margin-bottom: ${({ theme }) => {
    return theme.spacing.xxs;
  }}px;
  outline: none;
  border: 2px solid ${borderColor};
  border-radius: 5px;
  background: ${({ theme }) => {
    return theme.palette.white;
  }};
  font-size: ${({ theme }) => {
    return `${theme.typography.fontSize.inputText}px`;
  }};
  font-weight: ${({ theme }) => {
    return theme.typography.fontWeight.medium;
  }};
  ${margins}

  width: ${({ width = '100%' }) => {
    return width;
  }};
  max-width: ${({ maxWidth }) => {
    return definedNumber(maxWidth) && `${maxWidth}px`;
  }};

  display: ${({ block }) => {
    return block && 'block';
  }};
  text-align: ${({ textAlign = 'left' }) => {
    return textAlign;
  }};

  ::placeholder {
    color: ${({ theme }) => {
      return theme.palette.secondary;
    }};
  }

  &:hover {
    border-color: ${({ theme }) => {
      return theme.palette.secondary;
    }};
  }

  &:focus:enabled {
    color: ${({ theme }) => {
      return theme.palette.black;
    }};
    border-color: ${({ theme }) => {
      return theme.palette.black;
    }};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  transition: border-color ${TRANSITION_DURATION}ms ease-in-out;
`;

export default Input;
