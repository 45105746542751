import React from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import {
  hideModal,
  VIEW_PRODUCT,
  PRINT_PARCEL_LABELS,
  DELIVERY_INFORMATION_CHECKOUT_FORM
} from '_common/reducers/modal';
import ParcelLabelModal from './ParcelLabelModal';
import ProductModal from './ProductModal';
import DeliveryInformationFormModal from './DeliveryInformationFormModal';

const toggleBodyStyles = (isOpen = false) => {
  const bodyStyle = document.body.style;
  const hasScrollbar = window.innerWidth > document.documentElement.clientWidth;

  if (isOpen) {
    bodyStyle.paddingRight = hasScrollbar ? '15px' : '';
    bodyStyle.overflow = 'hidden';
  } else {
    document.body.removeAttribute('style');
  }
};

const ModalBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 1010;
  @media only screen and (min-width: 981px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => {
    return theme.palette.white;
  }};
  margin: 0;
  max-width: 980px;
  width: 100%;
  margin-left: 50%;
  transform: translate(-50%, 0);
  min-height: 100vh;
  border-radius: 5px;

  @media only screen and (min-width: 981px) {
    min-height: calc(100vh - 80px);
  }
`;

const MODAL_COMPONENTS = {
  [VIEW_PRODUCT]: ProductModal,
  [PRINT_PARCEL_LABELS]: ParcelLabelModal,
  [DELIVERY_INFORMATION_CHECKOUT_FORM]: DeliveryInformationFormModal
};

const ModalContainer = ({ dispatch, modalProps, modalType }) => {
  const modalBackdropEl = React.useRef(null);
  const closeModal = React.useCallback(() => {
    dispatch(hideModal());
    toggleBodyStyles(false);
  }, [dispatch]);

  const escFunction = React.useCallback(
    event => {
      if (event.keyCode === 27 && !modalProps.isNotDismissable) {
        closeModal();
      }
    },
    [closeModal, modalProps.isNotDismissable]
  );

  React.useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      return document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  if (!modalType) return null;

  const SpecificModal = MODAL_COMPONENTS[modalType];

  toggleBodyStyles(true);

  /** Scroll to top on re-render. Should not be affected when stuff inside modal changes. */
  if (modalBackdropEl && modalBackdropEl.current) {
    modalBackdropEl.current.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return createPortal(
    <ModalBackdrop
      ref={modalBackdropEl}
      onClick={e => {
        if (modalProps.isNotDismissable) return;
        if (e.target === modalBackdropEl.current) {
          closeModal();
        }
      }}
    >
      <ModalContent>
        <SpecificModal {...modalProps} closeModal={closeModal} />
      </ModalContent>
    </ModalBackdrop>,
    document.getElementById('modal-root')
  );
};

const mapStateToProps = ({ modal: { modalType, modalProps } }) => {
  return {
    modalProps,
    modalType
  };
};

export default connect(mapStateToProps)(ModalContainer);
