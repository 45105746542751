import React from 'react';
import { useTranslation } from 'react-i18next';
import ImageUploaderSimple from '_common/components/image-uploader/ImageUploaderSimple';
import { postAsset } from 'api';

const ProductImageInput = ({ productImage, onChange }) => {
  const saveImage = async file => {
    if (file === null) {
      onChange();
      return;
    }
    const formData = new FormData();
    formData.append('image', file, file.name);

    const { image } = await postAsset(formData);
    onChange(image);
    formData.delete('image');
  };
  const { t } = useTranslation();
  return (
    <ImageUploaderSimple
      image={productImage}
      onSave={saveImage}
      label={t('producer:productImageLabel')}
    />
  );
};

export default ProductImageInput;
