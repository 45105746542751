import React from 'react';
import { find } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { formatPhoneNumber } from '@dagensmat/core';
import Typography from '_common/components/utils/Typography';
import { useAppSelector } from '_common/hooks/reduxHooks';
import theme from 'theme';

type ContactProducerTextProps = {
  producerId: string;
};

const PhoneLink = ({ phone }: { phone: string }) => {
  return (
    <Typography variant="link" href={`tel:${phone}`}>
      {formatPhoneNumber(phone)}
    </Typography>
  );
};

const ContactProducerText = ({ producerId }: ContactProducerTextProps) => {
  const { t } = useTranslation();

  const { producer } = useAppSelector(({ producers: { items } }) => {
    return {
      producer: find(items, { _id: producerId })
    };
  });

  if (!producer) return null;

  const { contactPerson, phone } = producer;

  return (
    <Typography variant="paragraphSmall" my={theme.spacing.m}>
      <Trans
        t={t}
        i18nKey="consumer:PleaseContactProducer"
        components={{
          contactPerson,
          PhoneLink: <PhoneLink phone={phone} />
        }}
      />
    </Typography>
  );
};

export default ContactProducerText;
