/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsForRegex": ["state"] }] */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import REQ from 'utils/REQ';
import { getMessages } from 'api';
import type { AppDispatch, RootState } from 'store';

interface MessagesState {
  req: symbol;
  items: Message[];
  subscription?: number;
}

interface Message {
  _id: string;
  _createdAt: string;
  threadId: string;
  fromId: string;
  toId: string;
  message: string;
  seen: string;
}

const initialState: MessagesState = {
  req: REQ.INIT,
  items: []
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    fetchMessagesRequest(state) {
      state.req = REQ.PENDING;
      state.items = [];
    },
    fetchMessagesError(state) {
      state.req = REQ.ERROR;
      state.items = [];
    },
    fetchMessagesSuccess(state, action: PayloadAction<Message[]>) {
      state.req = REQ.SUCCESS;
      state.items = action.payload;
    },
    markAsSeen(state, action: PayloadAction<Message['_id'][]>) {
      const messageIds = action.payload;
      state.items = state.items.map(item => {
        return messageIds.includes(item._id)
          ? { ...item, seen: new Date().toISOString() }
          : item;
      });
    },
    registerMessagesSubscription(state, action: PayloadAction<number>) {
      clearInterval(state.subscription);
      state.subscription = action.payload;
    },
    clearMessages(state) {
      clearInterval(state.subscription);
      return initialState;
    }
  }
});

export default messagesSlice.reducer;

/** Action creators */
export const {
  fetchMessagesRequest,
  fetchMessagesError,
  fetchMessagesSuccess,
  markAsSeen,
  registerMessagesSubscription,
  clearMessages
} = messagesSlice.actions;

const fetchMessages = (
  roleId: RootState['auth']['_id'],
  { clearStore = true }: { clearStore?: boolean } = {}
) => {
  return (dispatch: AppDispatch) => {
    if (clearStore) {
      dispatch(fetchMessagesRequest());
    }

    getMessages({ roleId })
      .then(messages => {
        dispatch(fetchMessagesSuccess(Array.isArray(messages) ? messages : []));
      })
      .catch(() => {
        dispatch(fetchMessagesError());
      });
  };
};

export const startInterval = (
  roleId: RootState['auth']['_id'],
  intervalTime = 15000
) => {
  return (dispatch: AppDispatch) => {
    const subscription = window.setInterval(() => {
      dispatch(fetchMessages(roleId, { clearStore: false }));
    }, intervalTime);

    dispatch(registerMessagesSubscription(subscription));
  };
};

export const setUpMessages = (roleId: RootState['auth']['_id']) => {
  return (dispatch: AppDispatch) => {
    dispatch(fetchMessages(roleId));
    dispatch(startInterval(roleId));
  };
};

/** Selectors */

export const getMessagesInThread = (messages: Message[] = [], id: string) => {
  return messages.filter(({ threadId }) => {
    return threadId === id;
  });
};

export const getUnseenMessageIds = (
  messages: Message[] = [],
  threadId: string,
  userId: string
) => {
  return messages
    .filter(({ threadId: messageThreadId }) => {
      return threadId === messageThreadId;
    })
    .filter(({ seen, fromId }) => {
      return !seen && fromId !== userId;
    })
    .map(({ _id }) => {
      return _id;
    });
};

export const hasUnseenMessagesInThread = (
  messages: Message[],
  threadId: string,
  userId: string
) => {
  return getUnseenMessageIds(messages, threadId, userId).length > 0;
};

export const countTotalUnseenMessages = (
  messages: Message[] = [],
  userId: string
) => {
  return messages.filter(({ seen, fromId }) => {
    return !seen && fromId !== userId;
  }).length;
};
