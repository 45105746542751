import { Mixpanel } from 'mixpanel-browser';
import { mixpanelOptOutLoginEmails, gitCommitRef } from 'config';
import type { User } from '_common/reducers/auth';

const { mixpanel }: { mixpanel: Mixpanel } = window as any;

const shouldOptOut = (loginEmail: string) => {
  return mixpanelOptOutLoginEmails.includes(loginEmail);
};

export const setupRole = (role: User) => {
  const { _id, _type, name, distributionAreaName, loginEmail } = role;

  if (shouldOptOut(loginEmail)) {
    mixpanel.opt_out_tracking();
    return;
  }

  mixpanel.identify(_id);

  mixpanel.people.set({
    roleType: _type,
    $name: name,
    distributionAreaName,
    release: gitCommitRef
  });

  mixpanel.register({
    loginEmail
  });
};

/** Page View Events */

export const BASKET_PAGE_VIEW = 'Basket - Page view';
export const PRODUCT_PAGE_VIEW = 'Product - Page view';
export const LOGIN_PAGE_VIEW = 'Login - Page view';
export const USER_SIGNUP_PAGE_VIEW = 'User signup - Page view';
export const PRODUCER_SIGNUP_PAGE_VIEW = 'Producer signup - Page view';
export const CONSUMER_SIGNUP_PAGE_VIEW = 'Consumer signup - Page view';
export const PRODUCT_SEARCH_PAGE_VIEW = 'Product search - Page view';
export const GROUPED_PRODUCT_SEARCH_PAGE_VIEW =
  'Grouped product search - Page view';
export const PRODUCER_OVERVIEW_PAGE_VIEW = 'Producer overview - Page view';
export const PRODUCER_PROFILE_PAGE_VIEW = 'Producer profile - Page view';
export const PRODUCER_HOME_PAGE_VIEW = 'Producer home - Page view';
export const MY_PRODUCTS_PAGE_VIEW = 'My products - Page view';
export const ADD_PRODUCT_PAGE_VIEW = 'Add product - Page view';
export const EDIT_PRODUCT_PAGE_VIEW = 'Edit product - Page view';
export const PRODUCER_ORDER_OVERVIEW_PAGE_VIEW =
  'Producer order overview - Page view';
export const CONSUMER_CREATE_PAGE_VIEW = 'Consumer Create - Page view';
export const PRODUCER_CREATE_PAGE_VIEW = 'Producer Create - Page view';
export const SIGNUP_NEWSLETTER_PAGE_VIEW = 'Signup Newsletter - Page view';
export const CONSUMER_VIEW_FAVORITE_PAGE = 'My favorites - Page view';
export const CUSTOMER_PLACED_FIRST_ORDER_SHOW_MODAL =
  'Customer placed first order - Show Edit Delivery Info Modal';
export const ORDER_CONFIRMATION_CLICK_EDIT_DELIVERY_INFO =
  'Order confirmation - Click edit delivery info';
export const EDIT_DELIVERY_INFO_MODAL_VIEW = 'Edit Delivery Info Modal - View';
export const INTRO_SCREEN_ONE_PAGE_VIEW = 'Intro Screen 1 - Page view';
export const INTRO_SCREEN_TWO_PAGE_VIEW = 'Intro Screen 2 - Page view';

/** User Action Events */

export const PLACED_ORDER = 'Placed Order';
export const SELECT_DELIVERY_DAY_PRODUCT_SEARCH =
  'Product Search - Select Delivery Day';
export const SELECT_DELIVERY_DAY_PRODUCT = 'Product - Select Delivery Day';
export const UNSELECT_DELIVERY_DAY =
  'Delivery Day Banner - Unselect Delivery Day';
export const DOWNLOAD_PARCEL_LABELS = 'Download Parcel Labels';
export const TOGGLE_ORDER_NOTE_TEXT_AREA = 'Toggle Order Note Text Area';
export const CONSUMER_SIGNUP_SUBMITTED = 'Consumer Signup Submitted';
export const CONSUMER_CREATE_FAILED = 'Consumer Create Failed';
export const SWITCH_ROLE = 'Switch Role';
export const ADD_TO_BASKET = 'Add to Basket';
export const TOGGLE_PACKING_LIST_AXES = 'Toggle Packing List Axes';
export const DOWNLOAD_DELIVERY_NOTES = 'Download Delivery Notes';
export const OPEN_PRODUCT_FROM_PRODUCER_LANDING_PAGE =
  'Open Product From Producer Landing Page';
export const DOWNLOAD_MY_DATA = 'Download My Data';
export const CONSUMER_SIGNUP_FAILED = 'Consumer Signup Failed';
export const PRODUCER_SIGNUP_SUBMITTED = 'Producer Signup Submitted';
export const PRODUCER_SIGNUP_FAILED = 'Producer Signup Failed';
export const CONSUMER_RESET_FILTERS_DELIVERY_DATE =
  'Product Search - Click Reset Filters And Delivery Day Button';
export const PRODUCER_CREATE_FAILED = 'Producer Create Failed';
export const SEARCH_SEASON_CAL_CLICKED = 'Search season cal - Clicked product';
export const FAVORITES_SEASON_CAL_CLICKED =
  'My favorites season cal - Clicked product';
export const PRODUCT_CARD_CLICKED =
  'Product search card - Clicked product card';
export const PRODUCT_ORDER_ITEM_CARD_CLICKED =
  'Product order item search card - Clicked product card';
export const PRODUCER_SEASON_CAL_CLICKED =
  'Producer season cal - Clicked product';
export const PRODUCT_CATEGORY_BUTTON_OPENED =
  'Product search - Show filter category';
export const PRODUCT_CATEGORY_BUTTON_CLOSED =
  'Product search - Hide filter category';
export const PRODUCT_SEARCH_ADD_FILTER = 'Product search - Add filter';
export const PRODUCT_SEARCH_REMOVE_FILTER = 'Product search - Remove filter';
export const PRODUCT_SEARCH = 'Product search - Search';
export const EDIT_DELIVERY_INFO_MODAL_EDIT_DELIVERY_ADDRESS =
  'Edit Delivery Info Modal - Edit delivery address';
export const EDIT_DELIVERY_INFO_MODAL_EDIT_DELIVERY_INSTRUCTIONS =
  'Edit Delivery Info Modal - Edit delivery instructions';
export const EDIT_DELIVERY_INFO_MODAL_ADD_DELIVERY_INSTRUCTIONS =
  'Edit Delivery Info Modal - Add delivery instructions';
export const EDIT_DELIVERY_INFO_MODAL_SAVE =
  'Edit Delivery Info Modal - Click save delivery information';
export const INTRO_SCREEN_ONE_CLICK_SKIP_INTRO =
  'Intro Screen 1 - Click skip intro';
export const INTRO_SCREEN_ONE_CLICK_NEXT = 'Intro Screen 1 - Click next';
export const INTRO_SCREEN_TWO_CLICK_CTA = 'Intro Screen 2 - Click CTA';
export const GROUPED_PRODUCT_SEARCH_CLICK_SHOW_ALL_PRODUCTS =
  'Grouped product search - Click show all products';

/** General events */
export const SUCCESSFUL_LOGIN = 'Successful Login';
export const AUTHENTICATED = 'Authenticated';

export const track: Mixpanel['track'] = (...args) => {
  return mixpanel.track(...args);
};
