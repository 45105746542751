import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n';
import REQ from 'utils/REQ';
import { P } from 'utils/fonts';
import Button, {
  ButtonContainer
} from '_common/components/button/Button.style';
import { postRequestProduct } from 'api';
import MarginContainer2 from '_common/components/utils/MarginContainer2.style';
import TextArea from '_common/components/text-area/TextArea.style';
import theme from 'theme';

class SendRequest extends Component {
  state = {
    req: REQ.INIT,
    edited: false,
    onRequestText: ''
  };

  sendRequest = () => {
    const { onRequestText } = this.state;
    const { consumerId, productId } = this.props;

    const payload = {
      consumerId,
      productId,
      message: onRequestText
    };

    this.setState({ req: REQ.PENDING });

    postRequestProduct(payload)
      .then(() => {
        this.setState({ req: REQ.SUCCESS, edited: false });
      })
      .catch(() => {
        this.setState({ req: REQ.ERROR });
      });
  };

  render() {
    const { req, edited, onRequestText } = this.state;

    return (
      <MarginContainer2 mt={theme.spacing.m}>
        <P mb={theme.spacing.xxs}>
          {i18n.t('common:ThisProductIsAnOrderItem')}
        </P>
        <TextArea
          placeholder={i18n.t('common:EnterDesiredQuanitityDateOrQuestion')}
          value={onRequestText}
          onChange={({ target: { value } }) => {
            return this.setState({
              onRequestText: value,
              edited: true,
              req: REQ.INIT
            });
          }}
        />
        <ButtonContainer>
          {[REQ.INIT, REQ.ERROR].includes(req) && (
            <Button
              disabled={!edited || onRequestText.length < 5}
              onClick={this.sendRequest}
            >
              {i18n.t('common:SendRequest')}
            </Button>
          )}
          {req === REQ.PENDING && (
            <P color={theme.palette.secondary}>
              {i18n.t('common:SendingRequest')}
            </P>
          )}
          {req === REQ.SUCCESS && (
            <P color={theme.palette.secondary}>
              {i18n.t('common:RequestSentConfirmation')}
            </P>
          )}
        </ButtonContainer>
      </MarginContainer2>
    );
  }
}

const mapStateToProps = ({ auth: { _id: consumerId } }) => {
  return {
    consumerId
  };
};

export default connect(mapStateToProps)(SendRequest);
