import { pick } from 'lodash';
import { forSaleStatuses } from '@dagensmat/core';
import { daysUntilAvailabilityChanges } from 'utils/season';
import { Period, ForSaleStatus } from 'types/Product';

export const byFunction = <T>(func: (val: T) => any) => {
  return (a: T, b: T) => {
    return func(a) < func(b) ? -1 : 1;
  };
};

export const byKey = (key: string) => {
  return (a: any, b: any) => {
    return a[key] < b[key] ? -1 : 1;
  };
};

export const byDescending = (key: string) => {
  return (a: any, b: any) => {
    return a[key] < b[key] ? 1 : -1;
  };
};

export const byUniqueId = (
  val: { _id: string },
  index: number,
  self: any[]
) => {
  return (
    self.findIndex(a => {
      return a._id === val._id;
    }) === index
  );
};

const arrayIncludes = <T>(arr: T[] = []) => {
  return (val: T) => {
    return arr.includes(val);
  };
};

export const arrayDoesNotInclude = <T>(arr: T[] = []) => {
  return (val: T) => {
    return !arrayIncludes(arr)(val);
  };
};

export const grouper = <T>(
  keep: (keyof T)[] = [],
  itemsKey = 'items'
): [(acc, curr) => {}, T] => {
  return [
    (
      acc: { [x: string]: { key: string } & { [index: string]: T[] } },
      val: Record<keyof T | 'key', any>
    ) => {
      const { key } = val;

      acc[key] = acc[key] || {
        ...pick(val, ['key', ...keep]),
        [itemsKey]: []
      };

      acc[key][itemsKey].push(val);

      return acc;
    },
    {} as T
  ];
};

export const addKey = <T>(
  func: (val: T) => string
): ((val: T) => T & { key: string }) => {
  return (val: T) => {
    return {
      ...val,
      key: func(val)
    };
  };
};

export const filterSearchHits = (hits: any[], data: any[]) => {
  return hits
    ? data.filter(({ _id }) => {
        return hits.some(hitId => {
          return hitId === _id;
        });
      })
    : data;
};

export const coalesceString = (...vals: any[]): string => {
  return vals.find(Boolean) || '';
};

type SeasonCalendar = {
  seasonCalendar: Period[];
};

export const byClosestDate = (today = new Date()) => {
  return (productA: SeasonCalendar, productB: SeasonCalendar) => {
    const daysFromChangeUntilToday = daysUntilAvailabilityChanges(today);
    return (
      daysFromChangeUntilToday(productA) - daysFromChangeUntilToday(productB)
    );
  };
};

const indexOfForSaleStatus = (status: ForSaleStatus) => {
  return Object.keys(forSaleStatuses).indexOf(status);
};

export const byForSaleStatus = (
  a: { forSaleStatus: ForSaleStatus },
  b: { forSaleStatus: ForSaleStatus }
) => {
  return (
    indexOfForSaleStatus(a.forSaleStatus) -
    indexOfForSaleStatus(b.forSaleStatus)
  );
};
