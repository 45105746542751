import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import theme from 'theme';
import { TRANSITION_DURATION } from './Button.style';

const variants = {
  new: {
    fgColor: theme.palette.black,
    bgColor: theme.palette.new,
    fgHover: theme.palette.black,
    bgHover: theme.hover.new,
    fgDisabled: theme.palette.white,
    bgDisabled: theme.palette.secondary
  },
  black: {
    fgColor: theme.palette.white,
    bgColor: theme.palette.black,
    fgHover: theme.palette.white,
    bgHover: theme.palette.secondary,
    fgDisabled: theme.palette.white,
    bgDisabled: theme.palette.secondary
  },
  white: {
    fgColor: theme.palette.black,
    bgColor: theme.palette.white
  },
  error: {
    fgColor: theme.palette.black,
    bgColor: theme.palette.error
  }
};

export const ctaButtonCss = css`
  width: 100%;
  margin: auto;
  padding: 20px 26px;

  font-size: 20px;
  text-align: right;
  transition: background-color ${TRANSITION_DURATION}ms ease-in-out;

  :disabled,
  &.disabled {
    cursor: not-allowed;
  }

  ${props => {
    const colors = variants[props.variant] || {};
    return css`
      color: ${colors.fgColor};
      background-color: ${colors.bgColor};

      :hover {
        color: ${colors.fgHover || colors.fgColor};
        background-color: ${colors.bgHover || colors.bgColor};
      }

      .disabled,
      &:disabled {
        color: ${colors.fgDisabled || colors.fgColor};
        background-color: ${colors.bgDisabled || colors.bgColor};
      }
    `;
  }};
`;

export const FixedFullWidthButton = styled.button`
  ${ctaButtonCss}

  position: fixed;

  right: 0;
  bottom: 0;
  left: 0;

  max-width: 980px;
  margin: 0 auto;
`;

FixedFullWidthButton.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants))
};
