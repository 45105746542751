import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputMeasureUnit, UnitType } from '@dagensmat/core';
import theme from 'theme';
import Select from '_common/components/select/Select';
import { MarginProps } from 'utils/style';
import i18n from 'i18n';
import InputWithUnit from './InputWithUnit';
import { InputContainer as Container } from './Input.style';
import FormLabel from './FormLabel';

type InputWithSelectProps = {
  labelText: string;
  inputValue: number | string;
  inputUnit?: string;
  onInputChange: (value: number | string) => void;
  inputPlaceholder?: string;
  selectValue: UnitType | InputMeasureUnit;
  selectUnits: (UnitType | InputMeasureUnit)[];
  onSelectChange: (value: InputWithSelectProps['selectValue']) => void;
  explanationText?: React.ReactNode;
  prefix?: string;
  inputTextAlign: 'left' | 'right' | 'center';
  helperText?: string;
  optional?: boolean;
} & Pick<MarginProps, 'my'>;

const InputWithSelect = ({
  labelText,
  inputValue,
  inputUnit,
  onInputChange,
  inputPlaceholder = '#',
  selectValue,
  selectUnits = [],
  onSelectChange,
  prefix = `${i18n.t('common:per')} `,
  inputTextAlign = 'left',
  my,
  explanationText,
  helperText,
  optional = false
}: InputWithSelectProps) => {
  const { t } = useTranslation();

  return (
    <Container margin={my}>
      <FormLabel
        labelText={labelText}
        helpText={helperText}
        optional={optional}
      />
      <div style={{ display: 'inline-block', marginRight: theme.spacing.xxs }}>
        <InputWithUnit
          id={labelText}
          value={inputValue}
          unit={inputUnit}
          decimal
          placeholder={inputPlaceholder}
          onChange={onInputChange}
          textAlign={inputTextAlign}
        />
      </div>
      <Select
        id={`${labelText}-select`}
        selectedValue={selectValue}
        options={selectUnits.map(unit => {
          return {
            value: unit,
            name: `${prefix}${t(`units:${unit}`)}`
          };
        })}
        onChange={onSelectChange}
      />
      {explanationText}
    </Container>
  );
};
export default InputWithSelect;
