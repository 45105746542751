import React from 'react';
import LikeButton from '_common/components/button/LikeButton';
import { LikeButtonContainer } from './Product.style';

const LikeProduct = ({ productId, isGuest }) => {
  if (isGuest) return null;

  return (
    <LikeButtonContainer>
      <LikeButton productId={productId} />
    </LikeButtonContainer>
  );
};

export default LikeProduct;
