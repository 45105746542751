import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  border-radius: 5px;
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  max-width: 490px;
  border-radius: 5px;
`;

const ImagePreviewSimple = ({ imageUrl }) => {
  return (
    imageUrl && (
      <Container>
        <Image src={imageUrl} />
      </Container>
    )
  );
};

export default ImagePreviewSimple;
