import { configureStore } from '@reduxjs/toolkit';
import authReducer from '_common/reducers/auth';
import checkoutReducer from '_common/reducers/checkout';
import ordersReducer from '_common/reducers/orders';
import productRequestsReducer from '_common/reducers/productRequests';
import messagesReducer from '_common/reducers/messages';
import producerProductsReducer from '_producer/reducers/products';
import newProductReducer from '_producer/reducers/newProduct';
import productsForSaleReducer from '_consumer/reducers/productsForSale';
import producersReducer from '_consumer/reducers/producers';
import basketReducer from '_consumer/reducers/basket';
import modalReducer from '_common/reducers/modal';
import statisticsReducer from '_consumer/reducers/statistics';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    orders: ordersReducer,
    productRequests: productRequestsReducer,
    messages: messagesReducer,
    producerProducts: producerProductsReducer,
    newProduct: newProductReducer,
    productsForSale: productsForSaleReducer,
    producers: producersReducer,
    basket: basketReducer,
    modal: modalReducer,
    checkout: checkoutReducer,
    statistics: statisticsReducer
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false
    });
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type ReduxStore = typeof store;
