import React, { Component } from 'react';
import ErrorMessage from '_common/components/error-message/ErrorMessage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    const { airbrake } = this.props;
    this.setState({ hasError: true });

    airbrake.notify({
      error,
      params: { info }
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorMessage />;
    }

    return children;
  }
}

export default ErrorBoundary;
