import React from 'react';
import Icon from '_common/components/utils/Icon';
import Typography from '_common/components/utils/Typography';
import theme from 'theme';
import Flex from '../utils/Flex.style';
import MarginContainer2 from '../utils/MarginContainer2.style';

interface FormValidationMessageProps {
  message: string;
}

const FormValidationMessage = ({ message }: FormValidationMessageProps) => {
  return (
    <Flex wrap="nowrap" alignItems="flex-start">
      <MarginContainer2 mr={theme.spacing.xxs}>
        <Icon icon="alert" $size="m" />
      </MarginContainer2>
      <Typography variant="paragraph" as="span">
        {message}
      </Typography>
    </Flex>
  );
};

export default FormValidationMessage;
