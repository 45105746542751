import { slugifyDate } from 'utils/date/format';
import i18n from 'i18n';

/** Pickup types */
export const PRODUCER_DELIVERS = 'PRODUCER_DELIVERS';
export const PRODUCER_TRANSPORTS = 'PRODUCER_TRANSPORTS';
export const PICKED_UP_BY_PARTNER = 'PICKED_UP_BY_PARTNER';

const refIsDefined = ref => {
  return ref && ref._id && ref.name;
};

const isLegInvalid = ({ from, to, partner, startDate, endDate }) => {
  return (
    [from, to, partner].some(ref => {
      return !refIsDefined(ref);
    }) ||
    !startDate ||
    !endDate
  );
};

const isRouteInvalid = (deliveryRoute = []) => {
  return deliveryRoute.some(isLegInvalid);
};

export const isDeliveredByProducer = ({
  deliveryRoute = [],
  producer: { _id: producerId }
}) => {
  return (
    deliveryRoute.length === 0 ||
    (deliveryRoute.length === 1 &&
      (deliveryRoute[0].partner._id === producerId ||
        deliveryRoute[0].partner._ref === producerId))
  );
};

const isTransportStartedByProducer = ({
  deliveryRoute = [],
  producer: { _id: producerId }
}) => {
  return (
    deliveryRoute.length > 1 && deliveryRoute[0].partner._id === producerId
  );
};

const getTransportStartedByProducerData = order => {
  const { deliveryRoute = [] } = order;
  const {
    to: { _id: toId, name: toName },
    endDate
  } = deliveryRoute[0];
  return {
    key: `${endDate}-${toId}-producer-start`,
    toName,
    producerDeliveryDate: endDate
  };
};

const getPickedUpByLogisticsPartnerData = order => {
  const {
    deliveryRoute = [],
    producer: { _id: producerId }
  } = order;

  const {
    from: { _id: fromId, name: fromName },
    partner: { _id: partnerId, name: partnerName },
    startDate
  } = deliveryRoute[0];

  const pickupPoint = fromId !== producerId ? fromName : i18n.t('common:you');

  return {
    key: `${startDate}${fromId}${partnerId}-third-party-pickup`,
    pickupPoint,
    partnerName,
    producerDeliveryDate: startDate
  };
};

export const getPickupType = order => {
  const { deliveryRoute = [], deliveryDate } = order;

  if (
    deliveryRoute.length < 1 ||
    isRouteInvalid(deliveryRoute) ||
    isDeliveredByProducer(order)
  ) {
    return {
      key: `${deliveryDate}-delivered-by-producer`,
      type: PRODUCER_DELIVERS,
      producerDeliveryDate: deliveryDate
    };
  }

  if (isTransportStartedByProducer(order)) {
    return {
      type: PRODUCER_TRANSPORTS,
      ...getTransportStartedByProducerData(order)
    };
  }

  return {
    type: PICKED_UP_BY_PARTNER,
    ...getPickedUpByLogisticsPartnerData(order)
  };
};

const hasLeftProducer = ({
  producer: { _ref: producerId },
  deliveryRoute = [],
  statuses: { leftProducer }
}) => {
  const producerLegIndex = deliveryRoute.findIndex(
    ({ partner: { _id: partnerId } }) => {
      return partnerId === producerId;
    }
  );

  const producerCheckPoint =
    producerLegIndex > -1 && deliveryRoute[producerLegIndex].deliveryTime;

  const deliveryRouteAfterProducer =
    producerLegIndex > -1
      ? deliveryRoute.slice(0, producerLegIndex)
      : deliveryRoute;

  const checkPointsAfterProducer = deliveryRouteAfterProducer.find(
    ({ pickupTime, deliveryTime }) => {
      return pickupTime || deliveryTime;
    }
  );

  return [leftProducer, producerCheckPoint, checkPointsAfterProducer].some(
    Boolean
  );
};

export const hasSomeOrderNotLeftProducer = (orders = []) => {
  return orders.some(order => {
    return !hasLeftProducer(order);
  });
};

export const hasAllOrdersLeftProducer = (orders = []) => {
  return orders.every(order => {
    return hasLeftProducer(order);
  });
};

export const getDagensDeliveryDates = (deliveryRoutes, producerId) => {
  return deliveryRoutes
    .filter(({ route }) => {
      return !isDeliveredByProducer({
        deliveryRoute: route,
        producer: { _id: producerId }
      });
    })
    .map(({ deliveryDate }) => {
      return slugifyDate(deliveryDate);
    });
};
