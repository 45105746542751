import React from 'react';
import { useTranslation } from 'react-i18next';
import { roundNumber } from '@dagensmat/core';
import { P, B } from 'utils/fonts';
import { isSimplePricing } from 'utils/pricing';
import theme from 'theme';

const PricingText = ({ pricing, temperatureZone }) => {
  const {
    pricedUnitsPerOrderedUnit,
    pricedUnit,
    nokPerPricedUnit,
    unitSizeDescription
  } = pricing;

  const ratio = roundNumber(pricedUnitsPerOrderedUnit);
  const price = roundNumber(nokPerPricedUnit);

  const { t } = useTranslation();
  return (
    <div>
      <P my={3}>
        {!isSimplePricing(pricing) && (
          <>
            <B>{`${ratio} ${t(`units:${pricedUnit}`)}`}</B>
            {` á `}
          </>
        )}
        <B>{price} kr</B>
        {` per ${t(`units:${pricedUnit}`)}`}
        {temperatureZone === 'FROZEN' && (
          <span style={{ color: theme.palette.secondary }}>
            &nbsp;{t('common:frozen')}
          </span>
        )}
      </P>
      {unitSizeDescription && (
        <P color={theme.palette.secondary}>{unitSizeDescription}</P>
      )}
    </div>
  );
};

export default PricingText;
