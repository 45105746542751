import styled from 'styled-components';
import BaseButton from '_common/components/button/Button.style';

export const Picker = styled.div`
  display: flex;
  flex-direction: row;

  > *:not(:first-child) {
    margin-left: 6px;
  }

  margin-top: 10px;
`;

export const Button = styled(BaseButton)`
  font-size: ${({ theme }) => {
    return `${theme.typography.fontSize.inputText}px`;
  }};
  width: 44px;

  touch-action: manipulation;
`;
