import React from 'react';
import { ReactComponent as QuestionCircleIcon } from 'assets/question-circle-0x000000.svg';
import { ReactComponent as CloseCircleIcon } from 'assets/close-circle-0x000000.svg';
import { UnstyledButton } from '_common/components/button/Button.style';
import theme from 'theme';

type ToggleHelpCalloutButtonProps = {
  onToggle: () => void;
  isToggled: boolean;
};

const ToggleHelpCalloutButton = ({
  onToggle,
  isToggled
}: ToggleHelpCalloutButtonProps) => {
  return (
    <UnstyledButton
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing.xxs,
        width: theme.spacing.m,
        height: theme.spacing.m
      }}
      onClick={onToggle}
    >
      {isToggled ? <CloseCircleIcon /> : <QuestionCircleIcon />}
    </UnstyledButton>
  );
};

export default ToggleHelpCalloutButton;
