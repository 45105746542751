import React from 'react';
import { capitalize } from '@dagensmat/core';
import { ExplanationText } from 'utils/fonts';
import Typography from '_common/components/utils/Typography';
import MarginContainer from '_common/components/utils/MarginContainer.style';
import Button from '_common/components/button/Button.style';
import { MarginProps } from 'utils/style';

export type UnitOption = {
  key: string;
  value: string;
  explanationText?: string;
  disabled?: boolean;
};

export const mapOptions = (options: UnitOption[]) => {
  return options.map(option => {
    return typeof option === 'string' ? { key: option, value: option } : option;
  });
};

export type RadioButtonsProps = {
  headerText: string;
  unitOptions: UnitOption[];
  unit: string;
  unitChanged: (key: UnitOption['key']) => void;
  explanationText: string;
  useRawString?: boolean;
} & Pick<MarginProps, 'my'>;

const RadioButtons = ({
  headerText,
  unitOptions,
  unit,
  unitChanged,
  explanationText,
  my,
  useRawString = false
}: RadioButtonsProps) => {
  return (
    <MarginContainer my={my}>
      <Typography variant="inputLabel">{headerText}</Typography>
      {mapOptions(unitOptions).map(({ key, value }) => {
        return (
          <Button
            mr={10}
            mt={10}
            onClick={() => {
              unitChanged(key);
            }}
            key={key}
            variant={unit === key ? 'secondary' : 'ghost'}
          >
            {useRawString ? value : capitalize(value)}
          </Button>
        );
      })}
      {explanationText && (
        <ExplanationText my={10}>{explanationText}</ExplanationText>
      )}
    </MarginContainer>
  );
};

export default RadioButtons;
