import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Callout } from '_common/components/callout/Callout';
import { Label } from 'utils/fonts';
import {
  isAlwaysInSeason,
  isValidThisYear,
  calculatePeriodPosition
} from 'utils/season';
import theme from 'theme';
import CalendarMonths from './CalendarMonths';

const CalendarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 25px;
`;

const InSeasonBlock = styled.div`
  position: absolute;
  left: ${({ left }) => {
    return left;
  }};
  width: ${({ width }) => {
    return width;
  }};
  height: inherit;
  background-color: ${theme.palette.season};
  border-radius: 5px;
`;

const SingleProductSeason = ({ seasonCalendar = [] }) => {
  const { t } = useTranslation();
  return (
    <Callout color={theme.palette.availability}>
      <Label> {t('producer:productSeasonCalendarYear')} </Label>
      <CalendarContainer>
        {isAlwaysInSeason(seasonCalendar) ? (
          <InSeasonBlock left="0%" width="100%" />
        ) : (
          seasonCalendar.filter(isValidThisYear).map(period => {
            const { left, width } = calculatePeriodPosition(period);
            return (
              <InSeasonBlock key={period._key} left={left} width={width} />
            );
          })
        )}
        <CalendarMonths />
      </CalendarContainer>
    </Callout>
  );
};

export default SingleProductSeason;
