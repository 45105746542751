import { keys } from 'lodash';
import imageUrlBuilder from '@sanity/image-url';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { dataset } from 'config';

const builder = imageUrlBuilder({
  projectId: 'nn1enxmz',
  dataset
});

type Options = {
  width?: number;
  fit?: string;
};

export const getImageUrl = (
  source: SanityImageSource,
  options: Options = {}
): string | null => {
  try {
    const optionsFunctions = keys(options);
    return optionsFunctions
      .reduce((acc, key) => {
        return acc[key](options[key]);
      }, builder.image(source))
      .url();
  } catch (e) {
    return null;
  }
};
