import React, { Component, Suspense, lazy } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import lazyHandler from 'utils/network/lazyImportHandler';
import { checkAuth } from '_common/reducers/auth';
import { showProductModal } from '_common/reducers/modal';
import Modal from '_common/components/modal/ModalContainer';
import './i18n';

const ConsumerRouter = lazy(() => {
  return lazyHandler(() => {
    return import('_consumer/ConsumerRouter');
  });
});

const ProducerRouter = lazy(() => {
  return lazyHandler(() => {
    return import('_producer/ProducerRouter');
  });
});

const UnauthRouter = lazy(() => {
  return lazyHandler(() => {
    return import('_unauthenticated/UnauthRouter');
  });
});

class App extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(checkAuth());

    const { location } = window;
    const { showProduct } = queryString.parse(location.search);

    if (showProduct) {
      dispatch(showProductModal({ productId: showProduct }));
    }
  }

  render() {
    const { authenticated, role } = this.props;

    if (authenticated === undefined) return null;

    const RouterComponent =
      {
        producers: ProducerRouter,
        consumers: ConsumerRouter
      }[role] || UnauthRouter;

    return (
      <Suspense fallback={null}>
        <RouterComponent />
        <Modal />
      </Suspense>
    );
  }
}

const mapStateToProps = ({ auth: { authenticated, _type } }) => {
  return {
    authenticated,
    role: _type
  };
};

export default connect(mapStateToProps)(App);
