import algoliasearch from 'algoliasearch';
import { capitalize, AvailableLanguages } from '@dagensmat/core';
import { omit } from 'lodash';
import { productsSearchIndex, producersSearchIndex } from 'config';

const getTagsKey = (roleLang = AvailableLanguages.ENGLISH) => {
  return `tags_${roleLang}`;
};

const getProductAttributesToRetrieve = ({
  distributionAreaId,
  onlyIds,
  roleLang,
  roleId
}) => {
  return onlyIds
    ? ['_id']
    : [
        '_id',
        '_updatedAt',
        '_createdAt',
        'name',
        'type',
        'image',
        'producer',
        'certifications',
        'description',
        'tags_en',
        getTagsKey(roleLang),
        'forSaleStatus',
        'pricing',
        `pricing_${roleId}`,
        'temperatureZone',
        'keyFacts',
        'seasonCalendar',
        `deliveryDates_${distributionAreaId}`,
        `deadlines_${distributionAreaId}`,
        'soldOutDates',
        'processedState',
        'newnessRank'
      ];
};

const setupAlgolia = (index, roleId) => {
  return algoliasearch('QK33H0JTG8', 'f246495c7ac3bfa30fd94400584f33f4', {
    headers: {
      'X-Algolia-UserToken': roleId
    }
  }).initIndex(
    { products: productsSearchIndex, producers: producersSearchIndex }[index] ||
      productsSearchIndex
  );
};

export const getSearchableTags = (products = []) => {
  return [
    ...new Set(
      products
        .map(({ tags_en = [], tags = [] }) => {
          return tags_en[0] && tags[0] && `${tags_en[0]}|${tags[0]}`;
        })
        .filter(Boolean)
    )
  ].map(tag => {
    const [key, value] = tag.split('|');
    return { key, value: capitalize(value) };
  });
};

export const getAvailableCertifications = (products = []) => {
  return [
    ...new Set(
      products
        .map(({ certifications = [] }) => {
          return certifications[0];
        })
        .filter(Boolean)
        .map(({ displayNameKey }) => {
          return displayNameKey;
        })
    )
  ];
};

export const getProductsProcessedStates = (products = []) => {
  return [
    ...new Set(
      products
        .map(({ processedState = '' }) => {
          return processedState;
        })
        .filter(Boolean)
    )
  ];
};

export const getCountProducersInSearch = (productsInSearch = []) => {
  return [
    ...new Set(
      productsInSearch
        .map(({ producer: { _id } }) => {
          return _id;
        })
        .filter(Boolean)
    )
  ].length;
};

const buildFilterQuery = ({ filters = {}, distributionAreaId, roleId }) => {
  const algoliaFilters = [
    `availableTo:${roleId} OR availableTo:CONSUMERS`,
    `availableIn:${distributionAreaId}`
  ];
  if (Object.keys(filters).length > 0) {
    algoliaFilters.push(
      ...Object.keys(filters).map(key => {
        const arr = filters[key];
        return arr
          .map(filterValue => {
            return `${key}:"${filterValue}"`;
          })
          .join(' OR ');
      })
    );
  }
  return algoliaFilters
    .filter(Boolean)
    .map(filter => {
      return `(${filter})`;
    })
    .join(' AND ');
};

const getSearchOptions = options => {
  const { searchIndex, roleLang } = options;

  const restrictSearchableAttributes = [
    'name',
    'type',
    'producer.name',
    getTagsKey(roleLang)
  ];
  switch (searchIndex) {
    case 'products':
      return {
        filters: buildFilterQuery(options),
        attributesToRetrieve: getProductAttributesToRetrieve(options),
        restrictSearchableAttributes
      };
    default:
      return undefined;
  }
};

const handleConsumerSpecialPricing = (product, roleId) => {
  const consumersSpecialPricing = product[`pricing_${roleId}`];
  if (!consumersSpecialPricing)
    return { ...product, hasExclusivePricing: false, hasSpecialPricing: false };

  return omit(
    {
      ...product,
      pricing: consumersSpecialPricing,
      hasExclusivePricing: !product.pricing,
      hasSpecialPricing: true
    },
    `pricing_${roleId}`
  );
};

export const getAlgoliaClient = options => {
  const { roleId, searchIndex, onlyIds, roleLang } = options;
  const client = setupAlgolia(searchIndex, roleId);

  return async (searchString, filters) => {
    const { hits } = await client.search(
      searchString,
      getSearchOptions({ ...options, filters, roleId })
    );

    if (onlyIds) return hits;

    const tagsKey = getTagsKey(roleLang);
    return searchIndex === 'products'
      ? hits.map(hit => {
          const updatedHit = handleConsumerSpecialPricing(hit, roleId);
          return { tags: updatedHit[tagsKey], ...updatedHit };
        })
      : hits;
  };
};
