import React from 'react';
import MarginContainer from '_common/components/utils/MarginContainer.style';
import FormLabel from '_common/components/input/FormLabel';
import TextArea from './TextArea.style';

const TextAreaWithLabel = ({
  label,
  placeholder,
  value,
  onChange,
  mb,
  optional = false,
  explanationText = '',
  helpText = '',
  ...rest
}) => {
  return (
    <MarginContainer mt={0} mb={mb}>
      <FormLabel
        id={label}
        labelText={label}
        value={value}
        explanationText={explanationText}
        helpText={helpText}
        optional={optional}
      />
      <TextArea
        id={label}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        {...rest}
      />
    </MarginContainer>
  );
};

export default TextAreaWithLabel;
