import styled from 'styled-components';
import margins, { MarginProps } from 'utils/style';

const Flex = styled.div<
  {
    justify?: string;
    grow?: number;
    direction?: string;
    wrap?: string;
    alignItems?: string;
    bt?: string;
    minHeight?: string | number;
    width?: string;
  } & MarginProps
>`
  display: flex;
  justify-content: ${({ justify = 'space-between' }) => {
    return justify;
  }};
  flex-grow: ${({ grow }) => {
    return grow;
  }};
  flex-direction: ${({ direction }) => {
    return direction;
  }};
  flex-wrap: ${({ wrap = 'wrap' }) => {
    return wrap;
  }};
  align-items: ${({ alignItems = 'baseline' }) => {
    return alignItems;
  }};
  border-top: ${({ bt }) => {
    return bt;
  }};
  min-height: ${({ minHeight = 0 }) => {
    return minHeight;
  }};
  ${({ width }) => {
    return width && `width: ${width};`;
  }};
  ${margins};
`;

export default Flex;
