import styled from 'styled-components';
import { MarginProps } from 'utils/style';

const InnerScrollContainer = styled.div<Pick<MarginProps, 'ml' | 'mr'>>`
  @media only screen and (max-width: 599px) {
    width: auto;
    white-space: nowrap;

    > * {
      white-space: initial;
    }

    > *:first-child {
      margin-left: ${({ ml = 20 }) => {
        return `${ml}px`;
      }};
    }

    > *:last-child {
      margin-right: ${({ mr = 20 }) => {
        return `${mr}px`;
      }};
    }
  }
`;

export default InnerScrollContainer;
