import styled from 'styled-components';
import { MarginProps } from 'utils/style';

const ScrollContainer = styled.div<Pick<MarginProps, 'ml' | 'mr'>>`
  @media only screen and (max-width: 599px) {
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    margin-left: ${({ ml = -20 }) => {
      return `${ml}px`;
    }};
    margin-right: ${({ mr = -20 }) => {
      return `${mr}px`;
    }};
  }
`;

export default ScrollContainer;
