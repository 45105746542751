import {
  getRequest,
  postRequest,
  deleteRequest,
  postFormDataRequest
} from 'utils/network/fetch';

/** API Endpoints */

/** GET Requests */

export const getOrganization = getRequest('/utils/organization');

export const getNews = getRequest('/news');

export const getMessages = getRequest('/message');

export const getProductRequests = getRequest('/products/request');

export const getConsumers = getRequest('/consumer');

export const getOrders = getRequest('/order');

export const getOrderHistory = getRequest('/order/history');

export const getHasFirstOrder = getRequest('/order/hasFirstOrder');

export const getLogout = getRequest('/auth/logout');

export const getIdentity = getRequest('/auth');

export const getProducerDeliveryDates = getRequest('/logistics/deliveryDates');

export const getProducerDeliveryRoutes = getRequest(
  '/logistics/deliveryRoutes'
);

export const getProducerProducts = getRequest('/products');

export const getProducers = getRequest('/producer');

export const getCategories = getRequest('/categories');

export const getCertifications = getRequest('/certifications');

export const getStatistics = getRequest('/order/history/statistics');

/** POST Requests */

export const postLeftProducer = postRequest('/order/leftProducer');

export const postSeenNews = postRequest('/news/update');

export const postLogin = postRequest('/auth/login');

export const postResetPassword = postRequest('/auth/resetPassword');

export const postOpenProductRequest = postRequest('/products/request/open');

export const postRequestProduct = postRequest('/products/request');

export const postFavorite = postRequest('/favorite');

export const postMessage = postRequest('/message');

export const postSeenMessages = postRequest('/message/seen');

export const postOrder = postRequest('/order');

export const postProduct = postRequest('/products');

export const postProductCapacity = postRequest('/products/capacity');

export const postProductFieldsUpdate = postRequest('/products/update');

export const postOrderStatuses = postRequest('/order/statuses');

export const postOrderLine = postRequest('/order/orderLine');

export const postAddOrderLines = postRequest('/order/addOrderLines');

export const postOrderUpdate = postRequest('/order/update');

export const createUser = postRequest('/user');

export const verifyUser = postRequest('/user/verify');

export const createConsumer = postRequest('/consumer');

export const createProducer = postRequest('/producer');

export const postProducerUpdate = postRequest('/producer/update');

export const postConsumerUpdate = postRequest('/consumer/update');

export const postAsset = postFormDataRequest('/assets');

/** DELETE Requests */

export const deleteFavorite = deleteRequest('/favorite');

export const deleteProduct = deleteRequest('/products');
