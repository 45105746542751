import { connect } from 'react-redux';
import Picker from '_common/components/picker/Picker';
import {
  updateBasket,
  countUnitsOfProductInBasketForDeliveryDate
} from '_consumer/reducers/basket';

const EzPicker = connect(
  ({ basket }, ownProps) => {
    return {
      nofUnits: countUnitsOfProductInBasketForDeliveryDate(
        basket,
        ownProps.selectedDate,
        ownProps.product._id
      ),
      unit: ownProps.product.pricing.orderedUnit
    };
  },
  (dispatch, ownProps) => {
    return {
      setNofUnits: nofUnits => {
        return dispatch(
          updateBasket({
            deliveryDate: ownProps.selectedDate,
            productId: ownProps.product._id,
            units: nofUnits
          })
        );
      }
    };
  }
)(Picker);

export default EzPicker;
