import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditableProduct } from 'types/Product';

interface InitialProductState {
  readyToEdit: boolean;
  processedState: EditableProduct['processedState'];
}

const initialState: InitialProductState = {
  readyToEdit: false,
  processedState: 'RAW'
};

const newProductSlice = createSlice({
  name: 'newProduct',
  initialState,
  reducers: {
    updateProduct(state, action: PayloadAction<Partial<EditableProduct>>) {
      return {
        ...state,
        ...action.payload
      };
    },
    clearProduct() {
      return initialState;
    }
  }
});

export default newProductSlice.reducer;

export const { updateProduct, clearProduct } = newProductSlice.actions;
