import styled from 'styled-components';
import firstIllustration from 'assets/Illu_1.svg';
import secondIllustration from 'assets/Illu_2.svg';
import thirdIllustration from 'assets/Illu_3.svg';
import fourthIllustration from 'assets/Illu_4.svg';

const getIllustrationFromInitial = (productName: string): string => {
  if (!productName) return 'none';

  let illustration: string;
  if (/^[a-g]/i.test(productName)) {
    illustration = firstIllustration;
  } else if (/^[h-n]/i.test(productName)) {
    illustration = secondIllustration;
  } else if (/^[o-t]/i.test(productName)) {
    illustration = thirdIllustration;
  } else {
    illustration = fourthIllustration;
  }

  return `url(${illustration})`;
};

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 2px solid
    ${({ theme }) => {
      return theme.palette.lightGrey;
    }};
  border-radius: 5px;

  height: 500px;
  max-height: 65vh;

  cursor: pointer;

  top: 0px;
  transition: border 0.4s ease-in-out;

  :hover {
    border-color: ${({ theme }) => {
      return theme.palette.grey;
    }};
  }

  :active {
    border-color: ${({ theme }) => {
      return theme.palette.black;
    }};
  }
`;

const ImageContainer = styled.div<{
  name?: string;
  imageUrl?: string;
  roundedCorners?: string;
}>`
  background: ${({ theme }) => {
    return theme.palette.lightGrey;
  }};
  background-image: ${({ imageUrl, name }) => {
    return imageUrl ? `url(${imageUrl});` : getIllustrationFromInitial(name);
  }};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${({ roundedCorners = 'top', theme }) => {
    const orientation = ['top', 'bottom'].includes(roundedCorners)
      ? roundedCorners
      : 'top';
    return `border-${orientation}-right-radius: ${theme.borderRadius.small}px;
  border-${orientation}-left-radius: ${theme.borderRadius.small}px;`;
  }}
`;

export const ModalImageContainer = styled(ImageContainer)`
  display: block;
  width: 100%;
  height: 250px;
  max-height: 60vh;
  cursor: pointer;

  @media only screen and (min-width: 400px) {
    height: 400px;
  }
`;

export const CardImageContainer = styled(ImageContainer)`
  flex-grow: 2;
  width: 100%;
  height: auto;
`;

export const Content = styled.div`
  padding: 10px 15px 15px;
`;

export const LikeButtonContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
`;

export const SimpleBuyerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 3px;
`;
