import {
  ConsumerOnboarding,
  ConsumerOnboardingStepValue
} from '@dagensmat/core';

export const isGuestConsumer = (userId: string): boolean => {
  return userId === 'guest-consumer';
};

export type Onboarding = {
  [key in ConsumerOnboardingStepValue]?: string;
};

export const hasConsumerOnboarded = (onboarding: Onboarding) => {
  return onboarding[ConsumerOnboarding.HAS_SEEN_INTRO_SCREEN];
};
