import React from 'react';
import { xor } from 'lodash';
import { capitalize } from '@dagensmat/core';
import theme from 'theme';
import MarginContainer from '_common/components/utils/MarginContainer.style';
import Button from '_common/components/button/Button.style';
import { MarginProps } from 'utils/style';
import FormLabel from '_common/components/input/FormLabel';
import { mapOptions, UnitOption } from './RadioButtons';

export type CheckboxButtonsProps = {
  headerText: string;
  options: UnitOption[];
  selectedOptions: UnitOption['key'][];
  onChange: (buttonOption: UnitOption['key'][]) => void;
  margin?: number;
  disabled?: boolean;
  optional?: boolean;
} & MarginProps;

const CheckboxButtons = ({
  headerText,
  options,
  selectedOptions,
  onChange,
  margin,
  disabled = false,
  optional = false
}: CheckboxButtonsProps) => {
  return (
    <MarginContainer my={margin}>
      <FormLabel labelText={headerText} optional={optional} />
      {mapOptions(options).map(({ key, value }) => {
        return (
          <Button
            mr={theme.spacing.xxs}
            mb={theme.spacing.xs}
            onClick={() => {
              return onChange(xor(selectedOptions, [key]));
            }}
            key={key}
            variant={selectedOptions.includes(key) ? 'secondary' : 'ghost'}
            type="button"
            disabled={disabled}
          >
            {capitalize(value)}
          </Button>
        );
      })}
    </MarginContainer>
  );
};

export default CheckboxButtons;
