import { uuid } from '@sanity/uuid';
import {
  definedNumber,
  Unit,
  ScaledMeasureUnit,
  MeasureUnit
} from '@dagensmat/core';
import { Pricing, EditablePricing } from 'types/Product';

export const getInitialPricing = (): EditablePricing => {
  return {
    _type: 'productPricing',
    _key: uuid(),
    availableTo: ['CONSUMERS'],
    pricedUnit: Unit.kg,
    nokPerPricedUnit: '',
    orderedUnit: Unit.kg,
    pricedUnitsPerOrderedUnit: 1,
    unitSizeDescription: '',
    isSimplePricing: '',
    inputMeasureUnit: Unit.kg,
    inputMeasureUnitValue: 1
  };
};

const VALID_INPUT_MEASURE_UNITS = [
  ...Object.values(MeasureUnit),
  ...Object.values(ScaledMeasureUnit)
];

export const isMeasureUnitValid = ({
  inputMeasureUnit,
  inputMeasureUnitValue
}: EditablePricing) => {
  const numberValue = Number(inputMeasureUnitValue);
  return (
    VALID_INPUT_MEASURE_UNITS.includes(inputMeasureUnit) && numberValue > 0
  );
};

const isPricing = (price: Pricing | EditablePricing): price is Pricing => {
  return (
    typeof (price as Pricing).nokPerPricedUnit !== 'string' &&
    typeof (price as Pricing).pricedUnitsPerOrderedUnit !== 'string'
  );
};

const isEditablePricing = (
  price: Pricing | EditablePricing
): price is EditablePricing => {
  return typeof (price as EditablePricing).isSimplePricing !== 'undefined';
};

export const pricingComplete = (price: Pricing | EditablePricing) => {
  if (!isPricing(price)) return false;

  const {
    pricedUnit,
    orderedUnit,
    nokPerPricedUnit,
    pricedUnitsPerOrderedUnit
  } = price;
  return (
    !!pricedUnit &&
    !!orderedUnit &&
    definedNumber(nokPerPricedUnit) &&
    nokPerPricedUnit >= 0 &&
    definedNumber(pricedUnitsPerOrderedUnit) &&
    pricedUnitsPerOrderedUnit > 0
  );
};

export const pricesComplete = (prices: (Pricing | EditablePricing)[]) => {
  return prices.length > 0 && prices.every(pricingComplete);
};

const convertPricing = (editablePrice: EditablePricing): Pricing => {
  if (!isPricing(editablePrice)) {
    throw new Error('You can not convert an incomplete price');
  }

  const {
    _type,
    _key,
    availableTo,
    pricedUnit,
    nokPerPricedUnit,
    orderedUnit,
    pricedUnitsPerOrderedUnit,
    inputMeasureUnit,
    inputMeasureUnitValue,
    unitSizeDescription
  } = editablePrice;

  return {
    _type,
    _key,
    availableTo,
    pricedUnit,
    nokPerPricedUnit,
    orderedUnit,
    pricedUnitsPerOrderedUnit,
    unitSizeDescription,
    ...(isMeasureUnitValid(editablePrice) && {
      inputMeasureUnitValue,
      inputMeasureUnit
    })
  };
};

export const convertPrices = (prices: (Pricing | EditablePricing)[]) => {
  return prices.map(convertPricing);
};

export const isSimplePricing = (pricing: Pricing) => {
  return pricing.pricedUnit === pricing.orderedUnit;
};

export const makeEditable = (
  pricing: Pricing | EditablePricing
): EditablePricing => {
  if (isEditablePricing(pricing)) {
    return pricing;
  }

  const {
    _key,
    _type,
    availableTo,
    pricedUnit,
    nokPerPricedUnit,
    orderedUnit,
    pricedUnitsPerOrderedUnit,
    unitSizeDescription,
    inputMeasureUnit = Unit.kg,
    inputMeasureUnitValue
  } = pricing;

  return {
    _key,
    _type,
    availableTo,
    pricedUnit,
    nokPerPricedUnit,
    orderedUnit,
    pricedUnitsPerOrderedUnit,
    unitSizeDescription,
    inputMeasureUnit,
    inputMeasureUnitValue,
    isSimplePricing: isSimplePricing(pricing)
  };
};

const findDefaultConsumerPrice = (prices: Pricing[]): Pricing => {
  return prices.find(({ specialConsumers }) => {
    return !specialConsumers || specialConsumers.length < 1;
  });
};

export const findRightConsumerPrice = (
  prices: Pricing[],
  consumerId?: string
): Pricing => {
  if (!consumerId) return findDefaultConsumerPrice(prices);
  const consumersSpecialPrice = prices.find(productPricing => {
    const { specialConsumers } = productPricing;
    return specialConsumers &&
      specialConsumers.find(consumer => {
        return consumer._ref === consumerId;
      })
      ? productPricing
      : false;
  });

  return consumersSpecialPrice || findDefaultConsumerPrice(prices);
};
