import React from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { definedNumber } from '@dagensmat/core';
import { P } from 'utils/fonts';
import margins, { MarginProps } from 'utils/style';
import theme from 'theme';
import DagensPhoneLink from '_common/components/text-link/DagensPhoneLink';
import happyCustomerIllustration from 'assets/happy-customer-0xddccff@2x.png';
import Icon from '_common/components/utils/Icon';

export const Callout = styled.div<
  {
    padding?: number;
    minHeight?: number;
  } & MarginProps
>`
  position: relative;
  border-radius: 5px;
  background-color: ${({ color = theme.palette.messages }) => {
    return color;
  }};
  padding: ${({ padding = 15 }) => {
    return `${padding}px`;
  }};
  font-size: ${theme.typography.fontSize.paragraphSmall}px;
  ${margins}
  min-height: ${({ minHeight }) => {
    return definedNumber(minHeight) && `${minHeight}px`;
  }};

  @media print {
    border: 1pt solid ${theme.palette.black};
    background-color: transparent;
  }
`;

const ChevronContainer = styled.div`
  background: transparent;
  appearance: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .expanded {
    transform: rotate(180deg);
  }
`;

export const ExpandableCallout = ({
  expanded = false,
  expandedContent,
  children,
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = React.useState(expanded);
  return (
    <Callout {...rest} style={{ maxHeight: isExpanded ? '100vh' : 'none' }}>
      <ChevronContainer
        onClick={() => {
          return setIsExpanded(!isExpanded);
        }}
      >
        {children}
        <Icon icon="chevron" rotate={isExpanded ? 0 : 180} />
      </ChevronContainer>
      <div style={{ display: isExpanded ? 'block' : 'none' }}>
        {expandedContent}
      </div>
    </Callout>
  );
};

const FlexContainer = styled.div`
  display: flex;
  > img {
    align-self: flex-end;
    max-width: 100px;
    height: auto;
    @media only screen and (min-width: 360px) {
      max-width: 120px;
    }
    @media only screen and (min-width: 450px) {
      max-width: 170px;
    }
  }
`;

export type LoginCalloutProps = {
  type?: 'verifyEmail' | 'connectAccount';
};

export const LoginCallout = ({ type }: LoginCalloutProps) => {
  const { t } = useTranslation();

  switch (type) {
    case 'verifyEmail':
      return (
        <Callout color={theme.palette.noteworthy} mb={20}>
          <P my={10}>{t('common:VerifyEmailCallout')}</P>
        </Callout>
      );
    case 'connectAccount':
      return (
        <Callout color={theme.palette.noteworthy} mb={20}>
          <P my={10}>{t('common:ConnectAccountCallout')}</P>
        </Callout>
      );
    default:
      return (
        <Callout color={theme.palette.new} mb={20}>
          <FlexContainer>
            <div style={{ flexGrow: 2 }}>
              <Trans
                t={t}
                i18nKey="common:LoginCallout"
                components={{
                  DagensPhoneLink: <DagensPhoneLink />,
                  P: <P my={10} />
                }}
              />
            </div>
            <img
              src={happyCustomerIllustration}
              alt={t('common:Illustration')}
            />
          </FlexContainer>
        </Callout>
      );
  }
};
