import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getBasketFromLocalStorage,
  updateLocalStorage
} from 'utils/clientcache/index';
import { ADD_TO_BASKET, track } from 'utils/mixpanel';
import { DeliveryDate } from '_common/components/search/DeliveryDateOptions';
import { fetchProductsForSale } from './productsForSale';

const isBasketItem = (
  item: BasketItem,
  deliveryDate: BasketItem['deliveryDate'],
  productId: BasketItem['productId']
) => {
  return item.deliveryDate === deliveryDate && item.productId === productId;
};

export interface BasketItem {
  deliveryDate: DeliveryDate;
  productId: string;
  units: number;
}

const initialState: BasketItem[] = [];

const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    updateBasket(
      state,
      action: PayloadAction<{
        deliveryDate: DeliveryDate;
        productId: string;
        units: number;
      }>
    ) {
      const { deliveryDate, productId, units } = action.payload;

      const newState = state.filter(item => {
        return !isBasketItem(item, deliveryDate, productId);
      });

      if (units === 0) {
        updateLocalStorage('basket', newState);
        return newState;
      }

      newState.push({
        deliveryDate,
        productId,
        units
      });
      updateLocalStorage('basket', newState);
      track(ADD_TO_BASKET, { units, productId });
      return newState;
    },

    completeOrder() {
      updateLocalStorage('basket', []);
      return initialState;
    },

    clearBasket() {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchProductsForSale.fulfilled, (_state, action) => {
      const { items } = action.payload;
      return getBasketFromLocalStorage(items);
    });
  }
});
export default basketSlice.reducer;

export const { updateBasket, completeOrder, clearBasket } = basketSlice.actions;

/** Selectors */

export const countProductsInBasket = (basket: BasketItem[]) => {
  return basket.reduce((acc, { units }) => {
    return acc + units;
  }, 0);
};

export const countUnitsOfProductInBasketForDeliveryDate = (
  basket: BasketItem[],
  deliveryDate: DeliveryDate,
  productId: string
) => {
  const basketItem = basket.find(item => {
    return isBasketItem(item, deliveryDate, productId);
  });
  return basketItem ? basketItem.units : 0;
};

export const getBasketItemsForDeliveryDate = (
  basket: BasketItem[],
  deliveryDate: DeliveryDate
) => {
  return basket.filter(item => {
    return item.deliveryDate === deliveryDate;
  });
};
