import {
  AvailableLanguages,
  ConsumerOnboardingStepValue
} from '@dagensmat/core';
import { ProductForSale } from 'types/Product';
import { postRequest } from 'utils/network/fetch';
import { getAlgoliaClient } from 'utils/search';

export type User = {
  userId: string;
};

export const postConsumerOnboardingUpdate: (payload: {
  userId: User['userId'];
  onboardingStep: ConsumerOnboardingStepValue;
}) => Promise<void> = postRequest('/consumer/onboarding/update');

export const getProductsForSale = async ({
  userId,
  distributionAreaId,
  roleLang
}: {
  userId: string;
  distributionAreaId: string;
  roleLang: AvailableLanguages;
}): Promise<ProductForSale[]> => {
  const searchFunction = getAlgoliaClient({
    searchIndex: 'products',
    roleId: userId,
    distributionAreaId,
    onlyIds: false,
    roleLang
  });

  const hits = await searchFunction('');
  const deliveryDatesKey = `deliveryDates_${distributionAreaId}`;
  const deadlinesKey = `deadlines_${distributionAreaId}`;
  const forSaleHits = hits.map(product => {
    return {
      ...product,
      deliveryDates: product[deliveryDatesKey],
      deadlines: product[deadlinesKey]
    };
  });

  return forSaleHits;
};
