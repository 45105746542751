import React from 'react';
import styled, { css } from 'styled-components';
import theme, { ColorValue } from 'theme';
import margins, { MarginProps } from 'utils/style';

const colorProp = css<{ color: string }>`
  color: ${({ color }) => {
    return color;
  }};
`;

const PrimaryHeading = styled.h1`
  font-size: ${theme.typography.fontSize.primaryHeading}px;
  line-height: ${theme.spacing.l + theme.spacing.xs}px;
  letter-spacing: -0.4px;
  font-weight: ${theme.typography.fontWeight.regular};
  margin-top: 0;
  margin-bottom: ${theme.spacing.l}px;
  ${colorProp}
  ${margins}
`;

const SecondaryHeading = styled.h2`
  font-size: ${theme.typography.fontSize.secondaryHeading}px;
  line-height: ${theme.spacing.l}px;
  letter-spacing: -0.4px;
  font-weight: ${theme.typography.fontWeight.regular};
  ${colorProp}
  ${margins}
`;

const TertiaryHeading = styled.h3`
  font-size: ${theme.typography.fontSize.leadText}px;
  line-height: ${theme.spacing.l}px;
  letter-spacing: -0.4px;
  font-weight: ${theme.typography.fontWeight.regular};
  ${colorProp}
  ${margins}
`;

const AllCapsTitle = styled.strong`
  font-size: ${theme.typography.fontSize.xs}px;
  line-height: ${theme.spacing.s}px;
  letter-spacing: 1.25px;
  font-weight: ${theme.typography.fontWeight.bold};
  text-transform: uppercase;
  ${colorProp}
  ${margins}
`;

const paragraphStyles = css<{ weight?: 'regular' | 'semiBold' }>`
  font-size: ${theme.typography.fontSize.paragraph}px;
  line-height: ${theme.spacing.m}px;
  font-weight: ${({ weight = 'regular' }) => {
    return theme.typography.fontWeight[weight];
  }};
  letter-spacing: 0.1px;
  margin: 0;
  ${colorProp}
  ${margins}
`;

const Paragraph = styled.p`
  ${paragraphStyles}
`;

const ParagraphSmall = styled.p`
  font-size: ${theme.typography.fontSize.paragraphSmall}px;
  line-height: ${theme.spacing.m}px;
  font-weight: ${theme.typography.fontWeight.medium};
  letter-spacing: 0.1px;
  margin: 0;
  ${colorProp}
  ${margins}
`;

const InputLabel = styled.label`
  ${paragraphStyles}
  font-weight: ${theme.typography.fontWeight.semiBold};
  display: block;
`;

const LeadText = styled.p`
  font-size: ${theme.typography.fontSize.leadText}px;
  line-height: ${theme.spacing.l}px;
  font-weight: ${theme.typography.fontWeight.regular};
  letter-spacing: normal;
  margin: 0;
  ${colorProp}
  ${margins}
`;

const Link = styled.a`
  font-size: inherit;
  line-height: inherit;
  font-weight: ${theme.typography.fontWeight.medium};
  letter-spacing: inherit;
  text-decoration: underline;
  margin: 0;
  ${colorProp}
  ${margins}
`;

interface TypographyProps<C extends React.ElementType> extends MarginProps {
  variant:
    | 'primaryHeading'
    | 'secondaryHeading'
    | 'tertiaryHeading'
    | 'allCapsTitle'
    | 'paragraph'
    | 'paragraphSmall'
    | 'link'
    | 'inputLabel'
    | 'leadText';
  children?: React.ReactNode;
  color?: ColorValue;
  id?: string;
  href?: string;
  as?: C;
  weight?: 'regular' | 'semiBold';
}

type TypographyCompProps<C extends React.ElementType> = TypographyProps<C> &
  Omit<React.ComponentPropsWithoutRef<C>, keyof TypographyProps<C>>;

const components = {
  primaryHeading: PrimaryHeading,
  secondaryHeading: SecondaryHeading,
  tertiaryHeading: TertiaryHeading,
  allCapsTitle: AllCapsTitle,
  paragraph: Paragraph,
  paragraphSmall: ParagraphSmall,
  inputLabel: InputLabel,
  leadText: LeadText
};

const Typography = <C extends React.ElementType = 'span'>({
  variant,
  children,
  color = theme.palette.black,
  id,
  ...rest
}: TypographyCompProps<C>) => {
  if (variant === 'link') {
    return (
      <Link color={color} {...rest}>
        {children}
      </Link>
    );
  }

  if (variant === 'inputLabel') {
    return (
      <InputLabel color={color} htmlFor={id} {...rest}>
        {children}
      </InputLabel>
    );
  }

  const Comp = components[variant];

  return (
    <Comp color={color} {...rest}>
      {children}
    </Comp>
  );
};

export default Typography;
