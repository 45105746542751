import React from 'react';
import styled from 'styled-components';
import { P } from 'utils/fonts';
import theme from 'theme';
import Typography from '_common/components/utils/Typography';

const Container = styled.div<Pick<PageHeaderProps, 'mtMobile' | 'mtDesktop'>>`
  margin: ${({ mtMobile }) => {
    return `${mtMobile}px 0 30px`;
  }};

  @media only screen and (min-width: 600px) {
    margin: ${({ mtDesktop }) => {
      return `${mtDesktop}px 0 50px`;
    }};
  }
`;

type PageHeaderProps = {
  headerText: string;
  variant?: 'primaryHeading' | 'secondaryHeading';
  subTitle?: React.ReactNode | string;
  mtMobile?: number;
  mtDesktop?: number;
};

const SubTitle = ({ subTitle }: { subTitle: PageHeaderProps['subTitle'] }) => {
  return React.isValidElement(subTitle) ? (
    subTitle
  ) : (
    <P color={theme.palette.secondary}>{subTitle}</P>
  );
};

const PageHeader = ({
  headerText = '',
  variant = 'primaryHeading',
  subTitle = '',
  mtDesktop = 30,
  mtMobile = 10
}: PageHeaderProps) => {
  return (
    <Container mtDesktop={mtDesktop} mtMobile={mtMobile}>
      <Typography variant={variant} my={0}>
        {headerText}
      </Typography>
      <SubTitle subTitle={subTitle} />
    </Container>
  );
};

export default PageHeader;
