import React from 'react';
import { find } from 'lodash';
import styled from 'styled-components';
import {
  likedProduct,
  unlikedProduct
} from '_common/reducers/auth';
import { postFavorite, deleteFavorite } from 'api';
import heart from 'assets/heart.svg';
import heartFilled from 'assets/heart-filled.svg';
import { useAppSelector, useAppDispatch } from '_common/hooks/reduxHooks';

const Container = styled.div`
  border: none;
  background: transparent;
  height: 40px;
  width: 40px;
  padding: 0;
`;

const HeartIcon = styled.img<{
  visible: boolean;
}>`
  position: absolute;
  height: 20px;
  width: auto;
  top: 10px;
  right: 9px;
  visibility: ${props => {
    return props.visible ? 'visible' : 'hidden';
  }};
`;

type LikeButtonProps = {
  productId: string;
};

const LikeButton = ({ productId }: LikeButtonProps) => {
  const { consumerId, liked } = useAppSelector(({ auth }) => {
    return {
      consumerId: auth._id,
      liked: Boolean(find(auth.favorites || [], { _ref: productId }))
    };
  });
  const dispatch = useAppDispatch();

  const toggleLike = e => {
    e.stopPropagation();
    const payload = { consumerId, favoriteId: productId };

    if (liked) {
      dispatch(unlikedProduct(productId));
      deleteFavorite(payload);
    } else {
      dispatch(likedProduct(productId));
      postFavorite(payload);
    }
  };

  return (
    <Container onClick={toggleLike} role="button">
      <HeartIcon src={heartFilled} visible={liked} alt="Heart" />
      <HeartIcon src={heart} visible={!liked} alt="Heart" />
    </Container>
  );
};

export default LikeButton;
