/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { find, filter } from 'lodash';
import REQ from 'utils/REQ';
import { getProducers } from 'api';
import type { ProducerProduct } from 'types/Product';
import type { Producer } from 'types/Producer';
import { getAlgoliaClient } from 'utils/search';

const getProducersFromAlgolia = async ({ distributionAreaId }) => {
  const producerIndexSearch = getAlgoliaClient({
    searchIndex: 'producers',
    distributionAreaId
  });

  return producerIndexSearch('');
};

export const fetchProducers = createAsyncThunk(
  'consumers/fetchProducers',
  async (options: { distributionAreaId: string }) => {
    const sanityProducers = await getProducers(options);
    const algoliaProducers = await getProducersFromAlgolia(options);
    return sanityProducers.map((producer: { _id: Producer['_id'] }) => {
      return {
        ...producer,
        rawRank: find(algoliaProducers, { _id: producer._id }).rawRank
      };
    });
  }
);

interface ProducersState {
  req: symbol;
  items: Producer[];
}

const initialState: ProducersState = { req: REQ.INIT, items: [] };

const producersSlice = createSlice({
  name: 'producers',
  initialState,
  reducers: {
    clearProducers() {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(
        fetchProducers.fulfilled,
        (state, action: PayloadAction<Producer[]>) => {
          state.req = REQ.SUCCESS;
          state.items = action.payload;
        }
      )
      .addCase(fetchProducers.pending, state => {
        state.req = REQ.PENDING;
        state.items = [];
      })
      .addCase(fetchProducers.rejected, state => {
        state.req = REQ.ERROR;
        state.items = [];
      });
  }
});

export default producersSlice.reducer;

export const { clearProducers } = producersSlice.actions;

/** Selectors */

export const countProducerProducts = (
  producers: ProducersState['items'],
  products: ProducerProduct[]
) => {
  return producers.map(producer => {
    return {
      ...producer,
      numProductsForSale: filter(products, ['producer._id', producer._id])
        .length
    };
  });
};
