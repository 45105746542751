import { oneOf } from 'prop-types';

const REQ = {
  INIT: Symbol('INIT'),
  PENDING: Symbol('PENDING'),
  ERROR: Symbol('ERROR'),
  NOT_FOUND: Symbol('NOT_FOUND'),
  SUCCESS: Symbol('SUCCESS')
} as const;

export const combineReqs = (reqs: symbol[]) => {
  if (
    reqs.some(req => {
      return req === REQ.ERROR;
    })
  )
    return REQ.ERROR;
  if (
    reqs.some(req => {
      return req === REQ.PENDING;
    })
  )
    return REQ.PENDING;
  if (
    reqs.some(req => {
      return req === REQ.NOT_FOUND;
    })
  )
    return REQ.NOT_FOUND;
  if (
    reqs.every(req => {
      return req === REQ.SUCCESS;
    })
  )
    return REQ.SUCCESS;
  return REQ.INIT;
};

export default REQ;

export const ReqType = oneOf([
  REQ.INIT,
  REQ.PENDING,
  REQ.ERROR,
  REQ.NOT_FOUND,
  REQ.SUCCESS
]);
