import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  MeasureUnit,
  CombinedUnit,
  ScaledMeasureUnit,
  PackedUnit,
  UnitType
} from '@dagensmat/core';
import { connect } from 'react-redux';
import { booleanToYesNoUndecided } from 'utils/texts';
import { isMeasureUnitValid, pricingComplete } from 'utils/pricing';
import { EditablePricing } from 'types/Product';
import FormField from '_common/components/input/FormField';
import NewRadioButtons from '_common/components/radio-buttons/NewRadioButtons';
import InputWithSelect from '_common/components/input/InputWithSelect';
import { ExplanationText } from 'utils/fonts';
import { Callout } from '_common/components/callout/Callout';
import theme from 'theme';
import MarginContainer2 from '_common/components/utils/MarginContainer2.style';
import DisplayPricingStructure from './DisplayPricingStructure';

const MEASURE_UNIT_VALUES = Object.values(MeasureUnit);
const PACKED_UNIT_VALUES = Object.values(PackedUnit);

const isMeasureUnit = value => {
  return MEASURE_UNIT_VALUES.includes(value);
};

const inputMeasureUnitOptions = [
  MeasureUnit.kg,
  ScaledMeasureUnit.g,
  MeasureUnit.liter,
  ScaledMeasureUnit.ml
];

type MeasureUnitValidationCalloutProps = {
  pricing: EditablePricing;
};

const MeasureUnitValidationCallout = ({
  pricing
}: MeasureUnitValidationCalloutProps) => {
  const { t } = useTranslation();
  return (
    !isMeasureUnitValid(pricing) && (
      <Callout color={theme.palette.noteworthy}>
        {t('producer:productInputMeasureUnitCalloutText')}
      </Callout>
    )
  );
};

type EditPricingFlowProps = {
  editablePricing: EditablePricing;
  onPricingChange: (pricing: EditablePricing) => void;
  transactionCut: number;
};

const EditPricingFlow = ({
  editablePricing,
  onPricingChange,
  transactionCut
}: EditPricingFlowProps) => {
  const { t } = useTranslation();
  const {
    pricedUnit,
    nokPerPricedUnit,
    orderedUnit,
    pricedUnitsPerOrderedUnit,
    unitSizeDescription,
    isSimplePricing,
    inputMeasureUnit,
    inputMeasureUnitValue
  } = editablePricing;

  const isComplete = pricingComplete(editablePricing);

  const handlePricingChange = partialPricing => {
    onPricingChange({ ...editablePricing, ...partialPricing });
  };

  const pricedUnitOptions = Array.from(
    new Set([
      ...MEASURE_UNIT_VALUES,
      CombinedUnit.pcs,
      ...PACKED_UNIT_VALUES,
      pricedUnit
    ])
  ).filter(Boolean);

  const orderedUnitOptions = Array.from(
    new Set([CombinedUnit.pcs, ...PACKED_UNIT_VALUES, orderedUnit])
  ).filter(unit => {
    return !!unit && unit !== pricedUnit;
  });

  const showUnitSizeDescription =
    isComplete &&
    [pricedUnit, orderedUnit].some(unit => {
      return !(MEASURE_UNIT_VALUES as UnitType[]).includes(unit);
    });

  return (
    <div>
      <MarginContainer2 mb={theme.spacing.xl}>
        <InputWithSelect
          my={0}
          labelText={t('producer:productProductPriceLabelText')}
          inputValue={nokPerPricedUnit}
          inputUnit="kr"
          onInputChange={value => {
            handlePricingChange({
              nokPerPricedUnit: value
            });
          }}
          selectValue={pricedUnit}
          selectUnits={pricedUnitOptions}
          onSelectChange={(option: PackedUnit) => {
            handlePricingChange({
              pricedUnit: option,
              orderedUnit: PACKED_UNIT_VALUES.includes(option) ? option : '',
              isSimplePricing: PACKED_UNIT_VALUES.includes(option) ? true : '',
              pricedUnitsPerOrderedUnit: 1,
              unitSizeDescription: '',
              inputMeasureUnit: isMeasureUnit(option) ? option : MeasureUnit.kg,
              inputMeasureUnitValue: isMeasureUnit(option) ? 1 : ''
            });
          }}
          explanationText={
            nokPerPricedUnit ? (
              <DisplayPricingStructure
                transactionCut={transactionCut}
                pricing={editablePricing}
              />
            ) : (
              <ExplanationText mt={5}>
                {t('producer:productPriceFee')}
              </ExplanationText>
            )
          }
          inputTextAlign="right"
        />
      </MarginContainer2>
      <MeasureUnitValidationCallout pricing={editablePricing} />
      {!isMeasureUnit(pricedUnit) && (
        <MarginContainer2 mt={theme.spacing.xs} mb={theme.spacing.xl}>
          <InputWithSelect
            prefix=""
            labelText={t('producer:WeightPerPricedUnit', {
              pricedUnit
            })}
            inputValue={inputMeasureUnitValue}
            onInputChange={value => {
              handlePricingChange({
                inputMeasureUnitValue: value,
                inputMeasureUnit
              });
            }}
            selectValue={inputMeasureUnit}
            selectUnits={inputMeasureUnitOptions}
            onSelectChange={option => {
              handlePricingChange({
                inputMeasureUnit: option
              });
            }}
            my={0}
            inputTextAlign="right"
            helperText={t('producer:productInputMeasureUnitHelperText')}
            optional
          />
        </MarginContainer2>
      )}
      {!(PACKED_UNIT_VALUES as UnitType[]).includes(pricedUnit) && (
        <MarginContainer2 mb={theme.spacing.xl}>
          <NewRadioButtons
            labelText={t('producer:productOrderingUnitsHeader', { pricedUnit })}
            options={[
              {
                key: t('common:yes'),
                value: t('common:Yes')
              },
              {
                key: t('common:no'),
                value: t('common:No')
              }
            ]}
            currentValue={booleanToYesNoUndecided(isSimplePricing)}
            radioGroupName="orderingUnitsSame"
            onChange={option => {
              if (option === t('common:no')) {
                handlePricingChange({
                  isSimplePricing: false,
                  orderedUnit:
                    pricedUnit === CombinedUnit.pcs
                      ? PackedUnit.package
                      : CombinedUnit.pcs,
                  pricedUnitsPerOrderedUnit: ''
                });
              } else {
                handlePricingChange({
                  isSimplePricing: true,
                  orderedUnit: pricedUnit,
                  pricedUnitsPerOrderedUnit: 1
                });
              }
            }}
          />
        </MarginContainer2>
      )}
      {isSimplePricing === false && (
        <MarginContainer2 mb={theme.spacing.xl}>
          <InputWithSelect
            labelText={t('producer:productListOrderingUnitsLabelText')}
            inputValue={pricedUnitsPerOrderedUnit}
            inputUnit={t(`units:${pricedUnit}`)}
            onInputChange={value => {
              handlePricingChange({
                pricedUnitsPerOrderedUnit: value
              });
            }}
            selectValue={orderedUnit}
            selectUnits={orderedUnitOptions}
            onSelectChange={option => {
              handlePricingChange({
                orderedUnit: option
              });
            }}
            inputTextAlign="right"
            my={0}
          />
        </MarginContainer2>
      )}
      {showUnitSizeDescription && (
        <FormField
          type="text"
          labelText={t('producer:productExtraInfo')}
          name="unitSizeDescription"
          value={unitSizeDescription}
          margin={{ mb: theme.spacing.xl }}
          placeholder={t('producer:unitSizeDescriptionPlaceholder')}
          onChange={({ target: { value } }) => {
            return handlePricingChange({
              unitSizeDescription: value.slice(0, 50)
            });
          }}
          optional
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ auth: { transactionCut } }) => {
  return {
    transactionCut
  };
};

export default connect(mapStateToProps)(EditPricingFlow);
