import React from 'react';
import { find } from 'lodash';
import { getProductsFromProducer } from '_consumer/reducers/productsForSale';
import { useAppSelector } from '_common/hooks/reduxHooks';
import ProducerView from './ProducerView';

type ProducerViewContainerProps = {
  producerId: string;
  roleType?: 'consumers';
};

const ProducerViewContainer = ({
  producerId,
  roleType
}: ProducerViewContainerProps) => {
  const products = useAppSelector(({ productsForSale: { items } }) => {
    return getProductsFromProducer(items, producerId);
  });
  const producer = useAppSelector(({ producers: { items } }) => {
    return find(items, { _id: producerId })
  });

  return (
    producer && (
      <ProducerView
        producer={producer}
        products={products}
        roleType={roleType}
      />
    )
  );
};

export default ProducerViewContainer;
