import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';
import { TRANSITION_DURATION } from '_common/components/button/Button.style';
import margins from 'utils/style';

const stateStyles = ({ success, warning, error, theme }) => {
  const initialBorderColor = theme.palette.grey;
  const style = css`
    border: 2px solid ${initialBorderColor};
    &:disabled:hover {
      border-color: ${initialBorderColor};
    }
  `;

  if (error) {
    return css`
      ${style}
      border-color:${theme.palette.error};

      &:hover {
        border-color: ${lighten(0.2, theme.palette.error)};
      }
    `;
  }

  if (warning) {
    return css`
      ${style}
      border-color:${theme.palette.warning};

      &:hover {
        border-color: ${darken(0.2, theme.palette.warning)};
      }
    `;
  }

  if (success) {
    return css`
      ${style}
      border-color: ${theme.palette.black};
    `;
  }

  return style;
};

const Input2 = styled.input<{
  success: boolean;
  error: boolean;
  autoSave?: boolean;
}>`
  padding: ${({ theme }) => {
    return theme.spacing.xxs + theme.spacing.xs;
  }}px;
  margin: 0;
  margin-bottom: ${({ theme, autoSave, success, error }) => {
    return [success, error, autoSave].some(Boolean) && `${theme.spacing.xxs}px`;
  }};
  outline: none;
  border-radius: 5px;
  background: ${({ theme }) => {
    return theme.palette.white;
  }};
  font-size: ${({ theme }) => {
    return `${theme.typography.fontSize.inputText}px`;
  }};
  font-weight: ${({ theme }) => {
    return theme.typography.fontWeight.medium;
  }};
  width: 100%;

  ::placeholder {
    color: ${({ theme }) => {
      return theme.palette.secondary;
    }};
  }

  &:hover {
    border-color: ${({ theme }) => {
      return theme.palette.secondary;
    }};
  }

  &:focus:enabled {
    color: ${({ theme }) => {
      return theme.palette.black;
    }};
    border-color: ${({ theme }) => {
      return theme.palette.black;
    }};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  transition: border-color ${TRANSITION_DURATION}ms ease-in-out;

  ${stateStyles};
  ${margins};
`;

export default Input2;
