import React from 'react';

const useToggle = (
  initialValue: boolean = false,
  onToggle?: () => void
): [boolean, () => void] => {
  const [isVisible, setIsVisible] = React.useState(initialValue);

  return [
    isVisible,
    () => {
      if (onToggle) onToggle();
      setIsVisible(prev => {
        return !prev;
      });
    }
  ];
};

export default useToggle;
