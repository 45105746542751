import styled from 'styled-components';
import { TRANSITION_DURATION } from '_common/components/button/Button.style';

export default styled.textarea<{
  height?: string;
}>`
  position: relative;
  box-sizing: border-box;
  border: 2px solid
    ${({ theme }) => {
      return theme.palette.grey;
    }};
  border-radius: 5px;
  padding: ${({ theme }) => {
    return `${theme.spacing.xxs + theme.spacing.xs}px`;
  }};
  color: ${({ theme }) => {
    return theme.palette.black;
  }};
  font-size: ${({ theme }) => {
    return `${theme.typography.fontSize.inputText}px`;
  }};
  font-weight: ${({ theme }) => {
    return theme.typography.fontWeight.medium;
  }};

  width: 100%;
  resize: vertical;
  overflow-y: scroll;
  min-height: 6em;
  height: ${({ height = '6em' }) => {
    return height;
  }};
  line-height: 1.5;

  ::placeholder {
    color: ${({ theme }) => {
      return theme.palette.secondary;
    }};
  }

  &:hover {
    border-color: ${({ theme }) => {
      return theme.palette.secondary;
    }};
  }

  &:focus:enabled {
    color: ${({ theme }) => {
      return theme.palette.black;
    }};
    border-color: ${({ theme }) => {
      return theme.palette.black;
    }};
  }

  transition: border-color ${TRANSITION_DURATION}ms ease-in-out;
`;
