import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import Button from './Button.style';

const SaveProductButton = styled(Button)`
  padding: ${theme.spacing.s}px 0;
  text-align: center;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  height: 80px;
  padding: ${theme.spacing.s}px 0;
  background-color: white;
`;

type ProductFormSaveButtonProps = {
  onSave: () => Promise<void>;
  label: string;
  disabled?: boolean;
};

const ProductFormSaveButton = ({
  onSave,
  disabled,
  label
}: ProductFormSaveButtonProps) => {
  return (
    <ButtonWrapper>
      <SaveProductButton onClick={onSave} disabled={disabled} variant="primary">
        {label}
      </SaveProductButton>
    </ButtonWrapper>
  );
};

export default ProductFormSaveButton;
