import React from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { P, Label } from 'utils/fonts';
import InputWithUnit from '_common/components/input/InputWithUnit';
import theme from 'theme';
import PageHeader from '_common/components/page-header/PageHeader';
import { baseUrl } from 'config';
import { track, DOWNLOAD_PARCEL_LABELS } from 'utils/mixpanel';
import Button, {
  ButtonContainer,
  ButtonAnchor
} from '_common/components/button/Button.style';
import CloseButton from '_common/components/button/CloseButton';
import Container from '_common/components/container/Container.style';
import { getItemFromLocalStorage, updateLocalStorage } from 'utils/clientcache';

const CountInput = ({ count, onChange, id }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'inline-block',
        marginBottom: 0,
        marginRight: 10
      }}
    >
      <InputWithUnit
        value={count}
        unit={t('units:stk')}
        onChange={onChange}
        id={id}
      />
    </div>
  );
};
const sum = counts => {
  return Object.values(counts).reduce((acc, curr) => {
    const add = acc + curr;
    return add;
  }, 0);
};

const toDTO = counts => {
  return queryString.stringify(
    {
      counts: Object.values(counts),
      orderIds: Object.keys(counts)
    },
    {
      arrayFormat: 'bracket',
      sort: false
    }
  );
};

const ParcelLabelModal = ({ orders, closeModal }) => {
  const [counts, setCounts] = React.useState(() => {
    const preferredLabelQuantity = getItemFromLocalStorage(
      'preferredLabelQuantity'
    );
    const data = orders.reduce((acc, curr) => {
      acc[curr._id] = preferredLabelQuantity || 3;
      return acc;
    }, {});
    data.blanks = 0;
    return data;
  });
  const { t } = useTranslation();
  return (
    <Container>
      <CloseButton
        onClick={closeModal}
        style={{
          position: 'absolute',
          right: 15,
          top: 0,
          padding: 15
        }}
      />
      <PageHeader
        headerText={t('common:parcelLabelModal.labelDownload')}
        subTitle={t('common:parcelLabelModal.text')}
      />
      {[...orders, { _id: 'blanks' }].map(order => {
        return (
          <div
            style={{
              padding: '20px 0',
              display: 'flex',
              alignItems: 'center',
              borderBottom: `1px solid ${theme.palette.lightGrey}`
            }}
            key={order._id}
          >
            <CountInput
              id={order._id}
              count={counts[order._id]}
              onChange={value => {
                updateLocalStorage('preferredLabelQuantity', value);
                return setCounts(prevState => {
                  return {
                    ...prevState,
                    [order._id]: value
                  };
                });
              }}
            />
            <Label htmlFor={order._id} mb={0}>
              <P color={theme.palette.black}>
                {(order.consumer && order.consumer.name) ||
                  t('common:parcelLabelModal.blank')}
              </P>
              {order.orderNumberString && (
                <P color={theme.palette.secondary}>{order.orderNumberString}</P>
              )}
            </Label>
          </div>
        );
      })}
      <ButtonContainer>
        <Button onClick={closeModal}>{t('common:close')}</Button>
        <ButtonAnchor
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            return track(DOWNLOAD_PARCEL_LABELS);
          }}
          href={`${baseUrl}/export/parcelLabel?${toDTO(counts)}`}
          variant="secondary"
        >
          {t('common:parcelLabelModal.labelDownloadCount', {
            count: sum(counts)
          })}
        </ButtonAnchor>
      </ButtonContainer>
    </Container>
  );
};

export default ParcelLabelModal;
