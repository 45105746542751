import React from 'react';
import { useTranslation } from 'react-i18next';
import { daysUntil, getDay } from 'utils/date/format';
import { P } from 'utils/fonts';
import theme from 'theme';

const ProductDeliveryDeadlineText = ({ product, selectedDeliveryDate, mt }) => {
  const { deadlines } = product;

  const { t } = useTranslation();

  const deliveryDateDeadline = deadlines[selectedDeliveryDate];
  const daysToDeadline = daysUntil(deliveryDateDeadline);

  if (daysToDeadline > 7 || !deliveryDateDeadline) return null;

  const TIME_BEFORE_MIDNIGHT = '23:59';

  const deadlineTextColor =
    daysToDeadline === 0 ? theme.palette.warning : theme.palette.secondary;

  const deadlineText = t('consumer:OrderBefore', {
    date:
      daysToDeadline === 0
        ? TIME_BEFORE_MIDNIGHT
        : `${getDay(deliveryDateDeadline)} ${TIME_BEFORE_MIDNIGHT}`
  });

  return (
    <P mt={mt} fontSize="small" color={deadlineTextColor}>
      {deadlineText}
    </P>
  );
};

export default ProductDeliveryDeadlineText;
