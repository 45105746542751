import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EditablePricing, Pricing } from 'types/Product';
import SpacedContainer from '_common/components/container/FormContainer.style';
import Typography from '_common/components/utils/Typography';
import theme from 'theme';
import PricingText from '_common/components/product/PricingText';
import MarginContainer2 from '_common/components/utils/MarginContainer2.style';
import { ButtonLink } from '_common/components/button/Button.style';

type PricingLinksProps = {
  productId: string;
  prices?: (EditablePricing | Pricing)[];
};

type PricingLinkProps = {
  productId: string;
  pricing: EditablePricing | Pricing;
};

const PricingLinkStyle = styled(Link)`
  display: block;
  padding: ${theme.spacing.s}px;
  border 2px solid ${theme.palette.black};
  border-radius: ${theme.borderRadius.base}px;

  :hover {
    color: ${theme.palette.secondary};
    border-color: ${theme.palette.secondary};

    > * {
      color: ${theme.palette.secondary};
      border-color: ${theme.palette.secondary};
    }
  }
`;

const PricingLink = ({ productId, pricing }: PricingLinkProps) => {
  const { t } = useTranslation();
  const countSpecialConsumers = (pricing.specialConsumers || []).length;
  return (
    <PricingLinkStyle
      to={{
        pathname: `/my-products/${productId}/edit-pricing/${pricing._key}`,
        state: { returnPath: true }
      }}
    >
      <Typography variant="paragraph" weight="semiBold" mb={theme.spacing.xxs}>
        {countSpecialConsumers > 0 ? `TODO ${countSpecialConsumers} special consumers` : t('common:AllBuyers')}
      </Typography>
      <MarginContainer2 mb={theme.spacing.xxs}>
        <PricingText pricing={pricing} temperatureZone="" />
      </MarginContainer2>
      <Typography variant="paragraphSmall">
        {t('common:ClickToEditPrice')}
      </Typography>
    </PricingLinkStyle>
  );
};

const TODO_SPECIAL_PRICES_READY = false;

const PricingLinks = ({ productId, prices = [] }: PricingLinksProps) => {
  return (
    <SpacedContainer space="m">
      {prices.map(pricing => {
        return (
          <PricingLink
            key={pricing._key}
            productId={productId}
            pricing={pricing}
          />
        );
      })}
      {TODO_SPECIAL_PRICES_READY && (
        <ButtonLink
          to={{
            pathname: `/my-products/${productId}/add-pricing`,
            state: { returnPath: true }
          }}
        >
          TODO: + Add new price
        </ButtonLink>
      )}
    </SpacedContainer>
  );
};

export default PricingLinks;
