import React from 'react';
import { connect } from 'react-redux';
import { AvailableLanguages } from '@dagensmat/core';
import { useTranslation } from 'react-i18next';
import Tags from '_common/components/tags/Tags';
import FormLabel from '_common/components/input/FormLabel';

const toKeyValue = (category, roleLang = AvailableLanguages.ENGLISH) => {
  return {
    key: category._id,
    value: category[`name_${roleLang}`] || category.name
  };
};

const CategoryPicker = ({
  categoryId,
  onChange,
  roleLang,
  categories = []
}) => {
  const topLevelTags = () => {
    return categories.filter(({ parent }) => {
      return !parent;
    });
  };

  const findCategory = id => {
    return categories.find(({ _id }) => {
      return _id === id;
    });
  };

  const getCurrent = () => {
    return findCategory(categoryId);
  };

  const getChildren = () => {
    const category = getCurrent();
    return category ? category.children : topLevelTags();
  };

  const getCurrentPath = () => {
    const category = getCurrent();
    return category ? category.path : [];
  };

  const deselectTag = id => {
    const selectedTag = findCategory(id);
    const parentId = selectedTag.parent ? selectedTag.parent._ref : undefined;
    onChange(parentId);
  };
  const { t } = useTranslation();
  return (
    <>
      <FormLabel labelText={t('producer:productPickCategoryHeader')} />
      <Tags
        selectedTags={getCurrentPath().map(cat => {
          return toKeyValue(cat, roleLang);
        })}
        tags={getChildren().map(cat => {
          return toKeyValue(cat, roleLang);
        })}
        selectTag={onChange}
        deselectTag={deselectTag}
      />
    </>
  );
};

const mapStateToProps = ({ auth: { roleLang } }) => {
  return { roleLang };
};

export default connect(mapStateToProps)(CategoryPicker);
