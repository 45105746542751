import React from 'react';
import { capitalize } from '@dagensmat/core';
import { formatDate } from 'utils/date/format';
import ScrollContainer from '_common/components/utils/ScrollContainer.style';
import InnerScrollContainer from '_common/components/utils/InnerScrollContainer.style';
import { TagButton } from '_common/components/button/Button.style';

const SCROLL_CONTAINER_OFFSET = 15;

export type DeliveryDate = string;

type DeliveryDateOptionsProps = {
  availableDeliveryDates: DeliveryDate[];
  setDeliveryDate: (deliveryDate: DeliveryDate) => void;
  unavailableDeliveryDates?: DeliveryDate[];
};

const DeliveryDateOptions = ({
  availableDeliveryDates = [],
  setDeliveryDate,
  unavailableDeliveryDates = []
}: DeliveryDateOptionsProps) => {
  return (
    <ScrollContainer
      mr={-SCROLL_CONTAINER_OFFSET}
      ml={-SCROLL_CONTAINER_OFFSET}
    >
      <InnerScrollContainer
        mr={SCROLL_CONTAINER_OFFSET}
        ml={SCROLL_CONTAINER_OFFSET}
      >
        {availableDeliveryDates.map(deliveryDate => {
          return (
            <TagButton
              key={deliveryDate}
              onClick={() => {
                return setDeliveryDate(deliveryDate);
              }}
              disabled={!!unavailableDeliveryDates.includes(deliveryDate)}
            >
              {capitalize(formatDate(deliveryDate, 'iii d. MMM'))}
            </TagButton>
          );
        })}
      </InnerScrollContainer>
    </ScrollContainer>
  );
};

export default DeliveryDateOptions;
