import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

export const GridContainer = styled.div`
  --span: 1;
  display: grid;
  grid-template-columns: repeat(var(--span), 1fr);
  grid-template-rows: auto;
  gap: ${({ theme }) => {
    return `${theme.spacing.s}px`;
  }};

  @media only screen and (min-width: 600px) {
    --span: 2;
  }

  @media only screen and (min-width: 900px) {
    --span: 3;
  }
`;

const Grid = ({ hasMore, loadMore, children }) => {
  return (
    <InfiniteScroll hasMore={hasMore} loadMore={loadMore}>
      <GridContainer>{children}</GridContainer>
    </InfiniteScroll>
  );
};

export default Grid;

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired
};
