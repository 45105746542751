import React from 'react';
import { useTranslation } from 'react-i18next';
import NewRadioButtons from '_common/components/radio-buttons/NewRadioButtons';

const TemperatureZoneSelector = ({ onChange, value }) => {
  const { t } = useTranslation();
  return (
    <NewRadioButtons
      radioGroupName="temperatureZone"
      labelText={t('producer:productTemperatureZoneSelectorHeader')}
      options={[
        {
          key: 'FROZEN',
          value: t('producer:productTemperatureZoneSelectorOption1')
        },
        {
          key: 'REFRIGERATED',
          value: t('producer:productTemperatureZoneSelectorOption2')
        },
        {
          key: 'DRY',
          value: t('producer:productTemperatureZoneSelectorOption3')
        }
      ]}
      currentValue={value}
      onChange={onChange}
    />
  );
};
export default TemperatureZoneSelector;
