import React from 'react';
import { MarginProps } from 'utils/style';
import Icon from '_common/components/utils/Icon';
import theme from 'theme';
import Button from './Button.style';

type SelectedButtonProps = {
  onClick: () => void;
  children?: React.ReactNode;
} & MarginProps;

const SelectedButton = ({ children, ...props }: SelectedButtonProps) => {
  return (
    <Button variant="secondary" {...props}>
      {children}
      <Icon icon="clear" ml={theme.spacing.xxs} />
    </Button>
  );
};

export default SelectedButton;
