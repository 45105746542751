import styled, { css } from 'styled-components';
import margins, { MarginProps } from 'utils/style';

const fontStyle = css`
  font-family: Inter;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-synthesis: none;
`;

const BASE_FONT_SIZE = 16;
export const FontSizes = {
  xs: BASE_FONT_SIZE * 0.625,
  small: BASE_FONT_SIZE * 0.6875,
  medium: BASE_FONT_SIZE * 0.875,
  large: BASE_FONT_SIZE,
  xl: BASE_FONT_SIZE * 1.125
};

const truncateStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const P = styled.p<
  {
    fontSize?: keyof typeof FontSizes;
    align?: string;
    bold?: boolean;
    transform?: string;
    decoration?: string;
    number?: boolean;
    truncate?: boolean;
  } & MarginProps
>`
  ${fontStyle}
  font-size: ${({ fontSize = 'medium' }) => {
    return `${FontSizes[fontSize]}px`;
  }};
  line-height: 20px;
  text-align: ${({ align = 'inherit' }) => {
    return align;
  }};
  color: ${({ color = 'inherit' }) => {
    return color;
  }};
  font-weight: ${({ theme, bold }) => {
    return bold ? theme.typography.fontWeight.semiBold : 500;
  }};
  margin: 0;
  ${margins}
  text-transform: ${({ transform = 'initial' }) => {
    return transform;
  }};
  text-decoration: ${({ decoration = 'initial' }) => {
    return decoration;
  }};
  ${({ number }) => {
    return number ? `white-space: nowrap` : undefined;
  }}
  ${({ truncate }) => {
    return truncate ? truncateStyle : undefined;
  }}
`;

export const Label = styled.label`
  display: block;
  line-height: 1;
  margin-bottom: 9px;
  color: ${({ color = 'inherit' }) => {
    return color;
  }};
  font-weight: 800;
  font-size: ${`${FontSizes.small}px`};
  text-transform: uppercase;
  letter-spacing: 0.09em;
  ${margins}
`;

export const ExplanationText = styled(P)<{
  fontWeight?: number;
  color?: string;
}>`
  font-size: 12px;
  color: ${({ theme, color = theme.palette.secondary }) => {
    return color;
  }};
  font-weight: ${({ fontWeight = 500 }) => {
    return fontWeight;
  }};
`;

export const B = styled.strong<{ decoration?: string }>`
  font-weight: ${({ theme }) => {
    return theme.typography.fontWeight.semiBold;
  }};
  text-decoration: ${({ decoration }) => {
    return decoration || undefined;
  }};
`;
