import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import { MarginProps } from 'utils/style';
import MarginContainer2 from '_common/components/utils/MarginContainer2.style';
import theme from 'theme';
import Typography from '_common/components/utils/Typography';
import useResetAnimation from '_common/hooks/useResetAnimation';
import Input2 from './Input2.style';
import FormLabel from './FormLabel';

const fadeOut = keyframes`
0%, 90%{
  opacity:1;
}

100% {
  opacity: 0;
}
`;
const FadeOut = styled.span<{ saved: boolean }>`
  display: inline-block;
  visibility: ${({ saved }) => {
    return saved ? 'visible' : 'hidden';
  }};
  opacity: 0;
  animation: ${fadeOut} ease-in ${3000 / 0.9}ms;
`;

type FormFieldProps = {
  labelText: string;
  helpText?: string;
  placeholder: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  value: string | number;
  savedText?: string;
  autoSave?: boolean;
  saved?: boolean;
  id?: string;
  success?: boolean;
  error?: boolean;
  margin?: MarginProps;
  maxLength?: number;
  optional?: boolean;
  explanationText?: string;
  autoComplete?: string;
  tabIndex?: number;
  required?: boolean;
};

const FormField = ({
  labelText,
  savedText,
  helpText,
  placeholder,
  name,
  onChange,
  type,
  value,
  saved = false,
  id = name,
  success = false,
  error = false,
  margin = { mb: theme.spacing.xs },
  maxLength,
  autoSave = false,
  optional = false,
  explanationText,
  ...rest
}: FormFieldProps) => {
  const { t } = useTranslation();

  const ref = React.useRef<HTMLElement>(null);
  useResetAnimation(saved, ref);

  return (
    <MarginContainer2 {...margin}>
      <FormLabel
        labelText={labelText}
        helpText={helpText}
        id={id}
        maxLength={maxLength}
        optional={optional}
        explanationText={explanationText}
        value={value}
      />
      <Input2
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        success={success}
        error={error}
        type={type}
        value={value}
        maxLength={maxLength}
        {...rest}
      />
      {autoSave && (
        <FadeOut ref={ref} saved={saved}>
          <Typography variant="paragraphSmall" color={theme.palette.secondary}>
            {savedText || t('common:Saved')}
          </Typography>
        </FadeOut>
      )}
    </MarginContainer2>
  );
};

export default FormField;
