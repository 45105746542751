/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { find } from 'lodash';
import {
  AvailableLanguages,
  chooseLanguage,
  ConsumerOnboardingStepValue,
  onboardingSteps,
  Organization
} from '@dagensmat/core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18n';
import { defaultDistributionAreaId } from 'config';
import { getIdentity } from 'api';
import {
  updateLocalStorage,
  getCurrentRoleIdFromLocalStorage
} from 'utils/clientcache';
import {
  setupRole as setupMixpanelRole,
  track,
  AUTHENTICATED,
  SWITCH_ROLE
} from 'utils/mixpanel';
import type { AppDispatch } from 'store';
import type { ProducerFields } from 'types/Producer';
import type { SanityRef } from 'types/Sanity';
import type { Favorite } from 'types/Product';
import type { Onboarding as ConsumerOnboarding } from 'utils/role';

type ProducerOnboardingStepKey = keyof typeof onboardingSteps;
type ProducerOnboardingStepValue =
  typeof onboardingSteps[ProducerOnboardingStepKey];

export interface User
  extends Role,
    Partial<ConsumerFields>,
    Partial<ProducerFields> {
  _id: string;
  _type: 'user' | 'consumers' | 'producers';
  _updatedAt?: string;
  isVerified?: boolean;
  loginEmail?: string;
  uid?: string;
  options?: User[];
}

interface Role {
  emails?: string[];
  loginEmails?: string[];
  name: string;
  phone?: string;
  organization?: Organization;
  roleLang: AvailableLanguages;
  contactPerson: string;
  onboarding?: ConsumerOnboarding | ProducerOnboardingStepValue[];
}

interface ConsumerFields {
  favorites: Favorite[];
  betaTester?: boolean;
  isApprovedToOrder: boolean;
  deliveryAddress?: string;
  billingAddress?: string;
  deliveryInfo?: string;
  distributionArea?: SanityRef;
  distributionAreaId: string;
  distributionAreaName?: string;
  eanNumber?: string;
}

interface AuthState extends User {
  authenticated: boolean;
}

export type GuestConsumer = typeof guestConsumer;

const guestConsumer = {
  _id: 'guest-consumer',
  _type: 'consumers' as const,
  name: 'Gjest',
  contactPerson: 'Gjest',
  isApprovedToOrder: false,
  distributionAreaId: defaultDistributionAreaId,
  roleLang: chooseLanguage([
    ...(window.navigator.languages || [window.navigator.language])
  ])
};

const switchRole = (options: User['options'], roleId: User['uid']) => {
  const role = find(options, { _id: roleId });

  updateLocalStorage('currentRoleId', roleId);
  if (role.roleLang) i18n.changeLanguage(role.roleLang);
  setupMixpanelRole(role);
  track(SWITCH_ROLE);

  return {
    ...role,
    options
  };
};

const getCurrentRole = (roles = []) => {
  const roleId = getCurrentRoleIdFromLocalStorage();
  const current = roles.find(({ _id }) => {
    return _id === roleId;
  });

  if (current) {
    return current;
  }

  const [first] = roles;

  return first;
};

const initialState = { authenticated: undefined, favorites: [] } as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userAuthenticated(_state, action: PayloadAction<User>) {
      return { ...action.payload, authenticated: true };
    },
    guest() {
      return {
        authenticated: false,
        distributionAreaId: defaultDistributionAreaId,
        _id: 'unauth-user',
        favorites: [],
        contactPerson: ''
      } as AuthState;
    },
    changeUserRole(state, action: PayloadAction<User['uid']>) {
      return {
        ...switchRole(state.options, action.payload),
        authenticated: true
      };
    },
    userUpdated(state, action: PayloadAction<Partial<User>>) {
      return { ...state, ...action.payload };
    },
    likedProduct(state, action: PayloadAction<Favorite['_key']>) {
      const productId = action.payload;
      const favorite = {
        _key: productId,
        _ref: productId,
        _type: 'products' as const
      };
      if (state.favorites) {
        state.favorites.push(favorite);
      } else {
        state.favorites = [favorite];
      }
    },
    unlikedProduct(state, action: PayloadAction<Favorite['_key']>) {
      state.favorites = state.favorites.filter(({ _ref }) => {
        return _ref !== action.payload;
      });
    },
    consumerOnboardingUpdated(
      state,
      action: PayloadAction<ConsumerOnboardingStepValue>
    ) {
      state.onboarding = { [action.payload]: new Date().toISOString() };
    }
  }
});

export const {
  userAuthenticated,
  guest,
  changeUserRole,
  userUpdated,
  likedProduct,
  unlikedProduct,
  consumerOnboardingUpdated
} = authSlice.actions;

export default authSlice.reducer;

export interface ChangeRoleDTO {
  uid: User['uid'];
  email: User['loginEmail'];
  roles: User['options'];
}

const setupRoles = (dispatch: AppDispatch, data: ChangeRoleDTO) => {
  const { uid, email: loginEmail, roles } = data;
  const rolesWithUid = roles.map(role => {
    return { uid, loginEmail, ...role };
  });
  const current = getCurrentRole(rolesWithUid);

  const currentGuestConsumer = { ...guestConsumer, uid, loginEmail };

  if (current) {
    updateLocalStorage('currentRoleId', current._id);
    i18n.changeLanguage(current.roleLang);
    updateLocalStorage('fromHeader', loginEmail);
    setupMixpanelRole(current);

    const userData = !rolesWithUid.find(({ _type }) => {
      return _type === 'consumers';
    })
      ? { ...current, options: [...rolesWithUid, currentGuestConsumer] }
      : { ...current, options: rolesWithUid };

    dispatch(userAuthenticated(userData));
  } else {
    dispatch(
      userAuthenticated({
        ...currentGuestConsumer,
        options: [currentGuestConsumer]
      })
    );
  }
};

const setupLoggedInUser = (dispatch: AppDispatch, data: ChangeRoleDTO) => {
  if (data && data.uid) {
    setupRoles(dispatch, data);
    track(AUTHENTICATED);
  } else {
    dispatch(guest());
  }
};

export const loggedIn = (data: ChangeRoleDTO) => {
  return (dispatch: AppDispatch) => {
    setupLoggedInUser(dispatch, data);
  };
};

export const loggedOut = () => {
  return (dispatch: AppDispatch) => {
    dispatch(guest());
  };
};

export const checkAuth = () => {
  return (dispatch: AppDispatch) => {
    getIdentity().then(data => {
      setupLoggedInUser(dispatch, data);
    });
  };
};

/** Selectors */

export const getUserFirstName = (user: User) => {
  return user && user.contactPerson ? user.contactPerson.split(' ')[0] : '';
};

export const hasSomeProfile = ({
  profileArea,
  profileBio,
  image
}: AuthState) => {
  return [profileArea, profileBio, image].some(val => {
    return val;
  });
};

export const missingSomeProfile = ({
  profileArea,
  profileBio,
  image
}: AuthState) => {
  return [profileArea, profileBio, image && image.asset].some(val => {
    return !val;
  });
};
