import React from 'react';

const lazyHandler = (
  fn: () => Promise<{
    default: React.ComponentType<any>;
  }>
) => {
  return new Promise(resolve => {
    fn()
      .then(resolve)
      .catch(() => {
        window.location.reload();
      });
  });
};

export default lazyHandler;
