import React, { Component } from 'react';
import heic2any from 'heic2any';
import styled from 'styled-components';
import i18n from 'i18n';

const Input = styled.input`
  border: 0;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  z-index: -1;
`;

const LabelButton = styled.label`
  display: inline-block;
  padding: 8px 10px;
  margin: ${({ margin = 20 }) => {
    return `${margin}px 0`;
  }};

  border: 2px solid
    ${({ theme }) => {
      return theme.palette.black;
    }};
  border-radius: 5px;

  background-color: ${({ theme, fillButton }) => {
    return fillButton ? theme.palette.black : theme.palette.white;
  }};
  color: ${({ theme, fillButton }) => {
    return fillButton ? theme.palette.white : theme.palette.black;
  }};

  &.disabled {
    color: ${({ theme }) => {
      return theme.palette.secondary;
    }};
    border-color: ${({ theme }) => {
      return theme.palette.lightGrey;
    }};
    background-color: ${({ theme }) => {
      return theme.palette.lightGrey;
    }};
    cursor: initial;
  }

  line-height: 1;
  white-space: nowrap;

  position: relative;
  cursor: pointer;

  text-align: center;
  width: 100%;

  @media only screen and (min-width: 600px) {
    width: auto;
    text-align: left;
  }
`;

const convertPossibleHeicFile = async file => {
  if (file.type === 'image/heic') {
    const resultBlob = await heic2any({
      blob: file,
      toType: 'image/jpeg',
      quality: 0.3
    });
    return new File([resultBlob], 'image.jpeg', {
      type: 'image/jpeg'
    });
  }
  return file;
};

class ImageInput extends Component {
  inputRef = React.createRef();

  async handleImageChange(e) {
    e.preventDefault();

    const { onChange } = this.props;

    const reader = new FileReader();
    const file = await convertPossibleHeicFile(e.target.files[0]);

    reader.onloadend = () => {
      onChange(file, reader.result);
      if (this.inputRef.current) {
        this.inputRef.current.value = null;
      }
    };

    reader.readAsDataURL(file);
  }

  render() {
    const {
      buttonText = i18n.t('common:uploadImage'),
      disabled,
      margin,
      fillButton
    } = this.props;

    return (
      <div>
        <Input
          ref={this.inputRef}
          id="image-file-upload"
          type="file"
          onChange={e => {
            return this.handleImageChange(e);
          }}
          disabled={disabled}
          accept="image/*"
        />
        <LabelButton
          htmlFor="image-file-upload"
          margin={margin}
          fillButton={fillButton}
          className={disabled && 'disabled'}
        >
          {buttonText}
        </LabelButton>
      </div>
    );
  }
}

export default ImageInput;
