import styled from 'styled-components';
import { Spacing } from 'theme';

const SpacedContainer = styled.div<{ space: Spacing }>`
  > * {
    margin-bottom: ${({ theme, space = 'xl' }) => {
      return `${theme.spacing[space]}px`;
    }};
  }
`;

export default SpacedContainer;
