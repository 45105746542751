import React from 'react';
import { useTranslation } from 'react-i18next';
import { getImageUrl } from 'utils/imageUrls';
import {
  CardContainer as Container,
  CardImageContainer as ImageContainer,
  Content
} from '_common/components/product/Product.style';
import ProductTag from '_common/components/product/ProductTag';
import ProductInfo from '_common/components/product/ProductInfo';
import LikeProduct from '_common/components/product/LikeProduct';
import Button from '_common/components/button/Button.style';
import { showProductModal } from '_common/reducers/modal';
import { PRODUCT_ORDER_ITEM_CARD_CLICKED, track } from 'utils/mixpanel';
import { useAppDispatch } from '_common/hooks/reduxHooks';

const ProductOnRequestCard = ({ product, isGuest }) => {
  const dispatch = useAppDispatch();
  const { _id: productId, image, name } = product;

  const imageUrl = getImageUrl(image, { width: 500 });

  const { t } = useTranslation();
  return (
    <Container
      onClick={() => {
        dispatch(showProductModal({ productId }));
        track(PRODUCT_ORDER_ITEM_CARD_CLICKED);
      }}
    >
      <ImageContainer imageUrl={imageUrl} name={name} />
      <LikeProduct productId={productId} isGuest={isGuest} />
      <ProductTag product={product} />
      <Content>
        <ProductInfo product={product} />
        <Button my={10}>{t('common:writeRequest')}</Button>
      </Content>
    </Container>
  );
};

export default ProductOnRequestCard;
