import React from 'react';
import { CountryCode } from '@dagensmat/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { getImageUrl } from 'utils/imageUrls';
import Typography from '_common/components/utils/Typography';

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: inline-block;

  > img {
    max-width: 20px;
    max-height: 20px;
  }
`;

type Certification = {
  _createdAt: string;
  _id: string;
  _rev: string;
  _type: 'certification';
  _updatedAt: string;
  availableIn: CountryCode;
  displayNameKey: string;
  logo: SanityImageSource;
  name: string;
  parcelLabelAsset: SanityImageSource;
};

type ProductTitleProps = {
  name: string;
  type: string;
  certifications: Certification[];
};

const ProductTitle = ({
  name,
  type = '',
  certifications = []
}: ProductTitleProps) => {
  const { t } = useTranslation();

  return (
    <Typography variant="secondaryHeading" my={0}>
      {certifications.map(certification => {
        return (
          <IconWrapper key={`logo-${certification._id}`}>
            <img
              src={getImageUrl(certification.logo)}
              alt={t(`common:${certification.displayNameKey}`)}
            />
          </IconWrapper>
        );
      })}
      {name}
      {type && <Typography variant="paragraph">{` ${type}`}</Typography>}
    </Typography>
  );
};

export default ProductTitle;
