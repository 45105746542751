import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { ExplanationText } from 'utils/fonts';
import FormLabel from '_common/components/input/FormLabel';
import Button from '_common/components/button/Button.style';
import DatePicker from '_common/components/delivery-date-picker/DayPicker.style';
import { formatDate, slugifyDate } from 'utils/date/format';
import { arrayDoesNotInclude } from 'utils/array';
import theme from 'theme';
import { Callout } from '_common/components/callout/Callout';
import { toSoldOutCapacityDTO } from 'utils/season';
import { numberOfMonths } from 'utils/dayPicker';
import useWindowWidth from '_common/hooks/useWindowWidth';

const buttonMargins = { mr: theme.spacing.xxs, mb: theme.spacing.xs };

const saveButtonLabel = (index, slug) => {
  return index === -1
    ? `${i18n.t('producer:productSoldOutSet', { date: `${slug}` })}`
    : `${i18n.t('producer:productSoldOutUpdate', {
        date: `${slug}`
      })}`;
};

const dateOrEmpty = date => {
  return date && new Date(date);
};

const ProductCapacityInput = ({
  onChange,
  capacities = [],
  deliveryDates = []
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [selectedDate, setSelectedDate] = React.useState();
  const windowWidth = useWindowWidth();

  const isNotDeliveryDate = arrayDoesNotInclude(deliveryDates);
  const isSaveDisabled =
    !selectedDate ||
    !!capacities.find(({ deliveryDate }) => {
      return selectedDate === deliveryDate;
    });

  const selectedDateSlug = selectedDate
    ? formatDate(selectedDate, 'dd.MM.yy')
    : '';

  const onSave = () => {
    if (selectedIndex > -1 && selectedDate) {
      onChange(
        capacities.map((capacity, i) => {
          return selectedIndex === i
            ? toSoldOutCapacityDTO(selectedDate)
            : capacity;
        })
      );
    }

    if (
      selectedIndex > -1 &&
      selectedDate === capacities[selectedIndex].deliveryDate
    ) {
      onChange(
        capacities.filter((_, i) => {
          return selectedIndex !== i;
        })
      );
    }

    if (selectedIndex === -1 && selectedDate) {
      onChange([...capacities, toSoldOutCapacityDTO(selectedDate)]);
    }

    setSelectedIndex();
    setSelectedDate();
  };
  const { t } = useTranslation();
  return (
    <>
      <FormLabel
        labelText={t('producer:productSoldOutLabel')}
        helpText={t('producer:productSoldOutExplanation')}
      />
      {capacities.map(({ deliveryDate }, i) => {
        return (
          <Button
            {...buttonMargins}
            key={deliveryDate}
            variant={selectedIndex === i ? 'secondary' : 'ghost'}
            onClick={() => {
              if (selectedIndex === i) {
                setSelectedIndex(undefined);
                setSelectedDate();
              } else {
                setSelectedIndex(i);
                setSelectedDate(deliveryDate);
              }
            }}
          >
            {formatDate(deliveryDate, 'dd.MM.yy')}
          </Button>
        );
      })}
      <Button
        {...buttonMargins}
        variant={selectedIndex === -1 ? 'secondary' : 'ghost'}
        onClick={() => {
          return selectedIndex === -1
            ? setSelectedIndex(undefined)
            : setSelectedIndex(-1);
        }}
      >
        {t('producer:productSoldOutButton')}
      </Button>
      {selectedIndex >= -1 && (
        <>
          <DatePicker
            onDayClick={day => {
              return setSelectedDate(slugifyDate(day));
            }}
            disabledDays={day => {
              const slugified = slugifyDate(day);
              return (
                !!capacities.find(({ deliveryDate }) => {
                  return deliveryDate === slugified;
                }) || isNotDeliveryDate(slugified)
              );
            }}
            modifiers={{
              soldOut: day => {
                return !!capacities.find(({ deliveryDate }) => {
                  return deliveryDate === slugifyDate(day);
                });
              }
            }}
            selectedDays={new Date(selectedDate)}
            locale={i18n.language}
            months={t('calendar:months', { returnObjects: true })}
            weekdaysLong={t('calendar:weekdaysLong', {
              returnObjects: true
            })}
            weekdaysShort={t('calendar:weekdaysShort', {
              returnObjects: true
            })}
            firstDayOfWeek={1}
            numberOfMonths={numberOfMonths({ width: windowWidth })}
            initialMonth={dateOrEmpty(selectedDate)}
          />
          {selectedDate && isNotDeliveryDate(selectedDate) && (
            <Callout color={theme.palette.warning} mb={20}>
              {t('producer:productSoldOutCallOut')}
            </Callout>
          )}
          <ExplanationText mb={20}>
            {t('producer:productSoldOutExplanation1')}
          </ExplanationText>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {selectedIndex > -1 && (
              <Button
                onClick={onSave}
                mr={10}
                disabled={
                  selectedIndex === undefined ||
                  selectedDate !== capacities[selectedIndex].deliveryDate
                }
              >
                {t('common:reset')}
              </Button>
            )}
            <Button
              variant="secondary"
              onClick={onSave}
              disabled={isSaveDisabled}
            >
              {saveButtonLabel(selectedIndex, selectedDateSlug)}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default ProductCapacityInput;
