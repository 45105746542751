import React from 'react';
import styled from 'styled-components';
import DayPicker, {
  DateUtils,
  NavbarElementProps,
  RangeModifier
} from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { AvailableLanguages } from '@dagensmat/core';
import { Label } from 'utils/fonts';
import theme from 'theme';
import Button from '../button/Button.style';
import Icon from '../utils/Icon';
import Flex from '../utils/Flex.style';

const RangePicker = styled(DayPicker)<{
  id: string;
  firstDayOfWeek: number;
  selectedDays: (Date | RangeModifier)[];
  onDayClick: (day: Date) => void;
  locale: AvailableLanguages[keyof AvailableLanguages];
  modifiers: RangeModifier;
  months: string;
  weekdaysLong: string;
  weekdaysShort: string;
  numberOfMonths: number;
}>`
  margin: 10px 0;
  display: block;

  &:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: ${theme.palette.grey};
    border-radius: ${theme.borderRadius.base}px;
  }

  &:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: ${theme.palette.grey};
  }

  .DayPickerInput-Overlay {
    border-radius: ${theme.borderRadius.base}px;
  }

  .DayPicker-Day {
    border-radius: 0;

    &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: ${theme.palette.black};
    }

    &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to) {
      color: ${theme.palette.black};
      background-color: ${theme.palette.lightGrey};
    }

    &--today {
      color: ${theme.palette.secondary};
    }

    &--from.DayPicker-Day--selected {
      background-color: ${theme.palette.black};
      border-top-left-radius: ${theme.borderRadius.base}px;
      border-bottom-left-radius: ${theme.borderRadius.base}px;
    }

    &--to.DayPicker-Day--selected {
      background-color: ${theme.palette.black};
      border-top-right-radius: ${theme.borderRadius.base}px;
      border-bottom-right-radius: ${theme.borderRadius.base}px;
    }
  }
`;

export const initialRange = { from: undefined, to: undefined };

const NavBar = ({
  onPreviousClick,
  onNextClick,
  className
}: NavbarElementProps) => {
  return (
    <Flex className={className}>
      <Button
        variant="borderless"
        onClick={() => {
          return onPreviousClick();
        }}
      >
        <Icon icon="chevron" rotate={270} />
      </Button>
      <Button
        variant="borderless"
        onClick={() => {
          return onNextClick();
        }}
      >
        <Icon icon="chevron" rotate={90} />
      </Button>
    </Flex>
  );
};

type DateRangePickerProps = {
  dates: RangeModifier;
  onChange: (newRange: RangeModifier) => void;
  label?: string;
  monthsToShow?: number;
};

const DateRangePicker = ({
  dates,
  onChange,
  label,
  monthsToShow = 3
}: DateRangePickerProps) => {
  const handleDayClick = (day: Date) => {
    const newRange = DateUtils.addDayToRange(day, dates);
    onChange(newRange);
  };

  const { t, i18n } = useTranslation();
  return (
    <>
      {label && <Label htmlFor={label}>{label}</Label>}
      <RangePicker
        className="RangePicker"
        id={label}
        firstDayOfWeek={1}
        selectedDays={[dates.from, dates]}
        onDayClick={handleDayClick}
        locale={i18n.language}
        modifiers={{ from: dates.from, to: dates.to }}
        months={t('calendar:months', { returnObjects: true })}
        weekdaysLong={t('calendar:weekdaysLong', { returnObjects: true })}
        weekdaysShort={t('calendar:weekdaysShort', {
          returnObjects: true
        })}
        numberOfMonths={monthsToShow}
        navbarElement={NavBar}
      />
    </>
  );
};

export default DateRangePicker;
