import styled from 'styled-components';

const Container = styled.div<{
  padding?: number;
}>`
  padding: ${({ padding = 20 }) => {
    return `${padding}px`;
  }};
`;

export default Container;
