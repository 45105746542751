/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  roundNumber,
  calculateOrderedUnitPrice,
  AvailableLanguages
} from '@dagensmat/core';
import { find } from 'lodash';
import REQ from 'utils/REQ';
import { byProductNameAndId } from 'utils/texts';
import { byKey, byFunction, addKey, grouper } from 'utils/array';
import { daysUntil } from 'utils/date/format';
import { DeliveryDate } from '_common/components/search/DeliveryDateOptions';
import type { Pricing, ProductForSale } from 'types/Product';
import { getProductsForSale } from 'api/consumer';
import type { BasketItem } from './basket';

/** Action creators */
const tagsToLowerCase = (products: ProductForSale[]) => {
  return products.map(product => {
    return {
      ...product,
      tags: product.tags
        ? product.tags.map(t => {
            return t.toLowerCase();
          })
        : []
    };
  });
};

export const fetchProductsForSale = createAsyncThunk(
  'productsForSale/fetchProducts',
  async (options: {
    userId: string;
    distributionAreaId: string;
    roleLang: AvailableLanguages;
  }) => {
    const response = await getProductsForSale(options);
    return {
      items: tagsToLowerCase(response),
      availableDeliveryDates: Array.from(
        new Set(
          [].concat(
            ...response.map(({ deliveryDates = [] }) => {
              return deliveryDates;
            })
          )
        )
      )
        .sort()
        .slice(0, 6)
    };
  }
);

interface ProductsForSaleState {
  req: symbol;
  items: ProductForSale[];
  selectedDeliveryDate?: DeliveryDate;
  availableDeliveryDates?: DeliveryDate[];
}

const initialState: ProductsForSaleState = { req: REQ.INIT, items: [] };

const productsForSaleSlice = createSlice({
  name: 'productsForSale',
  initialState,
  reducers: {
    clearProductsForSale() {
      return initialState;
    },
    updateSelectedDeliveryDate(state, action: PayloadAction<string>) {
      state.selectedDeliveryDate = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProductsForSale.fulfilled, (state, action) => {
        const { items, availableDeliveryDates } = action.payload;
        state.req = REQ.SUCCESS;
        state.items = items;
        state.availableDeliveryDates = availableDeliveryDates;
      })
      .addCase(fetchProductsForSale.pending, state => {
        state.req = REQ.PENDING;
        state.items = [];
      })
      .addCase(fetchProductsForSale.rejected, state => {
        state.req = REQ.ERROR;
        state.items = [];
      });
  }
});
export default productsForSaleSlice.reducer;
export const { clearProductsForSale, updateSelectedDeliveryDate } =
  productsForSaleSlice.actions;

/** Selectors */

export const getProductsFromProducer = (
  products: ProductForSale[],
  producerId: string
) => {
  return products.filter(({ producer: { _id } }) => {
    return producerId === _id;
  });
};

export const isSoldOut = (
  { soldOutDates = [] }: { soldOutDates: string[] },
  deliveryDate: DeliveryDate
) => {
  return deliveryDate && soldOutDates.includes(deliveryDate);
};

export const isNew = ({ _createdAt }: { _createdAt: string }) => {
  return daysUntil(new Date(_createdAt)) > -8;
};

/** Selectors combining basket & products */

type ProductWithBasket = ProductForSale & {
  deliveryDate: BasketItem['deliveryDate'];
  unitsInBasket: BasketItem['units'];
};

export const getProductsInBasket = (
  products: ProductForSale[] = [],
  basketItems: BasketItem[] = []
): ProductWithBasket[] => {
  return basketItems
    .map(({ productId, units, deliveryDate }) => {
      return {
        ...find(products, { _id: productId }),
        deliveryDate,
        unitsInBasket: units
      };
    })
    .sort(byFunction(byProductNameAndId));
};

export const countProducersInBasket = (
  products: ProductForSale[],
  basketItems: BasketItem[]
) => {
  const basketProducts = getProductsInBasket(products, basketItems);
  return Array.from(
    new Set(
      basketProducts.map(({ producer: { _id } }) => {
        return _id;
      })
    )
  ).length;
};

export const getBasketByDeliveryDate = (basketProducts = []) => {
  const basketByDeliveryDate = basketProducts
    .map(
      addKey(({ deliveryDate }) => {
        return deliveryDate;
      })
    )
    .reduce(...grouper(['deliveryDate'], 'products'));

  return Object.values(basketByDeliveryDate).sort(byKey('deliveryDate'));
};

export const getBasketByProducer = (basketProducts = []) => {
  const basketByProducer = basketProducts
    .map(
      addKey(({ producer: { _id } }) => {
        return _id;
      })
    )
    .reduce(...grouper(['producer'], 'products'));

  return Object.values(basketByProducer).sort(
    byFunction(({ producer: { name: producerName } }) => {
      return producerName;
    })
  );
};

export const getDeliveryDateProducerKey = (
  deliveryDate: DeliveryDate,
  producerId: string
) => {
  return `${deliveryDate}${producerId}`;
};

const getBasketByDeliveryDateAndProducer = (basketProducts = []) => {
  const basketByDeliveryDateAndProducer = basketProducts
    .map(
      addKey(({ deliveryDate, producer: { _id } }) => {
        return getDeliveryDateProducerKey(deliveryDate, _id);
      })
    )
    .reduce(...grouper(['producer', 'deliveryDate'], 'products'));

  return Object.values(basketByDeliveryDateAndProducer);
};

/** Helpers for products in baskets */

type OrderPayloadCommonFields = {
  consumerId?: string;
  createdAs?: string;
  createdBy?: string;
  deliveryDate?: string;
  messageFromProducerToConsumer?: string;
  messageFromConsumerToProducer?: string;
  awaitLogistics?: boolean;
  withLogistics?: boolean;
};

export const getOrderPayload = (
  productsInBasket: ProductWithBasket[],
  commonFields: OrderPayloadCommonFields
) => {
  return {
    basket: productsInBasket.map(({ _id: productId, unitsInBasket }) => {
      return {
        productId,
        unitsInBasket
      };
    }),
    ...commonFields
  };
};

export const getOrderPayloads = (
  productsInBasket: ProductWithBasket[],
  commonFields: OrderPayloadCommonFields,
  messages: Record<string, string>
) => {
  const baskets = getBasketByDeliveryDateAndProducer(productsInBasket);
  return baskets.map(({ deliveryDate, producer: { _id }, products }) => {
    const messageKey = getDeliveryDateProducerKey(deliveryDate, _id);
    return getOrderPayload(products, {
      ...commonFields,
      deliveryDate,
      messageFromConsumerToProducer: messages[messageKey]
    });
  });
};

export const calculateBasketProductTotal = ({
  pricing,
  unitsInBasket
}: {
  pricing: Pricing;
  unitsInBasket: number;
}) => {
  return roundNumber(calculateOrderedUnitPrice(pricing) * unitsInBasket);
};

export const sumPriceForProductsInBasket = (
  productsInBasket: ProductWithBasket[]
): number => {
  return productsInBasket.reduce((acc, basketProduct) => {
    return acc + calculateBasketProductTotal(basketProduct);
  }, 0);
};
