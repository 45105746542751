import { uuid } from '@sanity/uuid';
import { forSaleStatuses } from '@dagensmat/core';
import { map, filter } from 'lodash';
import {
  isWithinInterval,
  closestTo,
  isBefore,
  differenceInDays,
  getMonth
} from 'date-fns';
import {
  daysUntil,
  minCurrentYear,
  maxCurrentYear,
  dayOfYear,
  slugifyDate,
  isBeforeThisYear,
  isAfterThisYear,
  parseDate,
  formatDate
} from 'utils/date/format';

const toPeriodWithKey = ({ from, to }) => {
  return {
    _key: uuid(),
    from: slugifyDate(from),
    to: slugifyDate(to)
  };
};

export const getAlwaysInSeason = () => {
  return [{ _key: uuid(), from: slugifyDate(new Date()) }];
};

export const addToSeasonCalendar = (seasonCalendar, period) => {
  return [...seasonCalendar, toPeriodWithKey(period)];
};

export const updateCalendar = (seasonCalendar, period, index) => {
  const updatedCalendar = [...seasonCalendar];
  updatedCalendar[index] = toPeriodWithKey(period);
  return updatedCalendar;
};

export const isAlwaysInSeason = (seasonCalendar = []) => {
  return (
    seasonCalendar.length === 1 &&
    seasonCalendar[0].from &&
    !seasonCalendar[0].to &&
    daysUntil(seasonCalendar[0].from) <= 0
  );
};

export const validatePeriod = ({ from, to }) => {
  return from && to;
};

export const isValidThisYear = period => {
  return (
    validatePeriod(period) &&
    !isBeforeThisYear(period.to) &&
    !isAfterThisYear(period.from)
  );
};

export const calculatePeriodPosition = ({ from, to }) => {
  const fromDayOfYear = dayOfYear(minCurrentYear(from));
  const toDayOfYear = dayOfYear(maxCurrentYear(to));
  const numDaysInPeriod = toDayOfYear - fromDayOfYear;

  return {
    left: `${(fromDayOfYear / 365) * 100}%`,
    width: `${(numDaysInPeriod / 365) * 100}%`
  };
};

export const toSoldOutCapacityDTO = date => {
  return {
    _type: 'capacity',
    _key: uuid(),
    deliveryDate: date,
    units: 0
  };
};

export const isInSeason = (seasonCalendar = []) => {
  const today = new Date();
  return seasonCalendar.some(season => {
    if (!validatePeriod(season)) return false;
    return isWithinInterval(today, {
      start: parseDate(season.from),
      end: parseDate(season.to)
    });
  });
};

export const getFirstSeasonStart = (seasonCalendar = [], today) => {
  const futureSeasonStarts = filter(
    map(seasonCalendar, ({ from }) => {
      return parseDate(from);
    }),
    fromDate => {
      return !isBefore(fromDate, today);
    }
  );

  return closestTo(today, futureSeasonStarts);
};

export const getUpcomingSeason = (seasonCalendar = []) => {
  if (
    seasonCalendar.length === 0 ||
    isInSeason(seasonCalendar) ||
    isAlwaysInSeason(seasonCalendar)
  )
    return false;

  const today = new Date();

  const firstSeasonStart = getFirstSeasonStart(seasonCalendar, today);

  if (!firstSeasonStart || differenceInDays(firstSeasonStart, today) < 14)
    return false;

  return {
    diff: Math.abs(getMonth(today) - getMonth(firstSeasonStart)),
    month: formatDate(firstSeasonStart, 'MMMM')
  };
};

export const daysUntilAvailabilityChanges = (today = new Date()) => {
  return ({ seasonCalendar = [] }) => {
    if (isAlwaysInSeason(seasonCalendar)) {
      return Infinity;
    }
    const dates = [].concat(
      ...seasonCalendar.map(({ from, to }) => {
        return [parseDate(to), parseDate(from)];
      })
    );
    return Math.abs(differenceInDays(today, closestTo(today, dates)));
  };
};
const byClosestSeasonStart = (a, b) => {
  return differenceInDays(
    getFirstSeasonStart(a.seasonCalendar, new Date()),
    getFirstSeasonStart(b.seasonCalendar, new Date())
  );
};

const filterByComingInSeason = lastAvailableDelivery => {
  return product => {
    const isNotAlwaysAvailable = !isAlwaysInSeason(product);
    const isNotInSeason = !isInSeason(product.seasonCalendar);
    const firstSeasonStart = getFirstSeasonStart(
      product.seasonCalendar,
      new Date()
    );
    const isInSeasonBeforeLastDeliveryDate =
      differenceInDays(lastAvailableDelivery, firstSeasonStart) >= 0;
    const isBuyable =
      product.forSaleStatus === forSaleStatuses.ON_REQUEST ||
      (product.deliveryDates || []).some(deliveryDate => {
        return (
          differenceInDays(lastAvailableDelivery, parseDate(deliveryDate)) >= 0
        );
      });
    return [
      isNotAlwaysAvailable,
      isNotInSeason,
      isInSeasonBeforeLastDeliveryDate,
      isBuyable
    ].every(Boolean);
  };
};

export const getSoonComingInSeason = (availableDeliveryDates, products) => {
  const lastAvailableDelivery = parseDate(availableDeliveryDates.slice(-1)[0]);
  const filterFunc = filterByComingInSeason(lastAvailableDelivery);
  const result = products.filter(filterFunc).sort(byClosestSeasonStart);
  return result;
};
