import React from 'react';

type State = {
  req: 'init' | 'pending' | 'success';
  data: any;
};

function reducer(
  state: State,
  action: { type: 'fetch' | 'done'; payload?: any }
) {
  switch (action.type) {
    case 'fetch':
      return { ...state, req: 'pending' };
    case 'done':
      return {
        req: 'success',
        data: action.payload
      };
    default:
      return state;
  }
}

const usePromiseAll = (
  requests: ((params?: Record<string, any>) => Promise<any>)[]
) => {
  const [state, dispatch] = React.useReducer(reducer, {
    req: 'init',
    data: []
  });

  React.useEffect(() => {
    if (state.req === 'init') {
      const fetchAll = async () => {
        const responses = await Promise.all(
          requests.map(fn => {
            return fn();
          })
        );
        dispatch({ type: 'done', payload: responses });
      };
      dispatch({ type: 'fetch' });
      fetchAll();
    }
  }, [dispatch, requests, state.req]);
  return [...state.data, state.req === 'success'];
};

export default usePromiseAll;
